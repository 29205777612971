/* Collection of project-specific routes */
export enum ERoutes {
  // Defaults
  HOME = '/',
  MARKETING_QUIZ = '/quiz',

  ROYAL_RUMBLE = '/:style',
  STRAIGHT_TO_OFFER = '/sto/:style',

  TRIAL_PAGE_7_DAY = '/7-day-trial',

  BLACK_FRIDAY = '/black-friday',

  PDS = 'https://university.personaldevelopmentschool.com/collections',

  // Corporate
  CORPORATE_LANDING_PAGE = '/corporate-quiz',
  CORPORATE_QUIZ_QUESTIONS = '/corporate-quiz/questions',
  CORPORATE_QUIZ_RESULTS = '/corporate-quiz/questions/results/:result/:ap/:da/:fa/:sa',

  // ========== External ==========
  THINKIFIC_CHECKOUT_REGULAR_SUBSCRIPTION = 'https://university.personaldevelopmentschool.com/enroll/559833?price_id=1285773',
  THINKIFIC_CHECKOUT_7_DAY_TRIAL = 'https://university.personaldevelopmentschool.com/enroll/559833?price_id=590989',
  THINKIFIC_CHECKOUT_14_DAY_TRIAL = 'https://university.personaldevelopmentschool.com/enroll/559833?price_id=2357208',
  CONTACT_US = 'mailto:info@personaldevelopmentschool.com',
  SUPPORT = 'info@personaldevelopmentschool.com',
  CORPORATE = 'https://www.corporate.personaldevelopmentschool.com/',
  DISCORD = 'https://discord.com/invite/EasJ2CvUkv',
  FACEBOOK = 'https://www.facebook.com/groups/461389461257253/?ref=bookmarks',
  INSTAGRAM = 'https://www.instagram.com/personaldevelopment_school/',
  PODCASTS = 'https://podcasts.apple.com/us/podcast/personal-development-school/id1478580185',
  PRIVACY = 'https://university.personaldevelopmentschool.com/pages/privacy',
  TERMS = 'https://university.personaldevelopmentschool.com/pages/terms',
  TWITTER = 'https://twitter.com/PDS_ThaisGibson',
  YOUTUBE = 'https://www.youtube.com/channel/UCHQ4lSaKRap5HyrpitrTOhQ',
  VSL = 'https://attachment.personaldevelopmentschool.com/quiz/c/',

  /* For Form submission to Node Server */
  // PRODUCTION
  FB_CAPI_LEAD = 'https://pds-marketing-api.herokuapp.com/fb-capi/lead',
  ACTIVECAMPAIGN_LEAD = 'https://pds-marketing-api.herokuapp.com/api/post/contact',

  // STAGING
  // FB_CAPI_LEAD = 'https://immense-caverns-59768.herokuapp.com/fb-capi/lead',
  // ACTIVECAMPAIGN_LEAD = 'https://immense-caverns-59768.herokuapp.com/api/post/contact',
}

/* Custom react-router component wrapper */
export * from './Router'

/* Custom react-router `useNavigation()` hook-wrapper */
export * from './useRouter'
