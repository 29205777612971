// core
import React from 'react'
// components
import { Icon } from 'components/Icon'
import { Tooltip } from 'components/Tooltip'
import { IInputDefaultProps } from './InputDefault'
// libraries
import cx from 'classnames'
import { Field, FieldProps, useFormikContext } from 'formik'
import { get } from 'lodash'
// utils
// import {} from 'utils'

interface IInputTextAreaCoreProps extends IInputDefaultProps<string> {
  rows?: number
}

interface IInputTextAreaFormikProps extends IInputTextAreaCoreProps {
  name: string
}

type IInputTextAreaProps =
  | (IInputTextAreaCoreProps & { name: undefined })
  | IInputTextAreaFormikProps

export const InputTextArea = ({ name, ...otherProps }: IInputTextAreaProps) =>
  name ? (
    <InputTextAreaWithFormik name={name} {...otherProps} />
  ) : (
    <InputTextAreaCore {...otherProps} />
  )

const InputTextAreaWithFormik = (props: IInputTextAreaFormikProps) => {
  const { errors } = useFormikContext()

  return (
    <Field name={props.name}>
      {(fieldProps: FieldProps) => (
        <InputTextAreaCore {...props} {...fieldProps.field} error={get(errors, props.name)} />
      )}
    </Field>
  )
}

//

const InputTextAreaCore = ({
  className,
  classNameInput,
  colorScheme = 'gray',
  error,
  isDisabled,
  isFocused,
  isFocusedHighlight,
  label,
  name,
  noStyles,
  placeholder = 'Enter value',
  rows = 4,
  tooltip,
  value,
  onBlur,
  onChange,
  onFocus,
}: IInputTextAreaCoreProps) => {
  const defaultBgColor = cx(
    colorScheme === 'gray' && 'bg-light shadow-center-light',
    colorScheme === 'white' && 'bg-white',
    colorScheme === 'black' && 'bg-black shadow-white-shadow'
  )
  const defaultTextColor = cx(
    colorScheme === 'gray' && 'text-txt-dark',
    colorScheme === 'white' && 'text-txt-dark',
    colorScheme === 'black' && 'text-white'
  )
  const defaultPlaceholderTextColor = cx(
    colorScheme === 'gray' && 'placeholder-gray-500',
    colorScheme === 'white' && 'placeholder-gray-500',
    colorScheme === 'black' && 'placeholder-white'
  )

  const textColors = cx(
    cx(error ? 'text-danger placeholder-danger' : [defaultTextColor, defaultPlaceholderTextColor])
  )

  const labelCSS = () =>
    cx(error ? 'text-danger' : defaultTextColor, 'absolute top-1.5 left-3 text-xxs2')

  const inputCSS = () =>
    cx(
      !noStyles && [
        'group placeholder-italic dark:bg-black dark:shadow-white-shadow pt-2 pb-2.5 text-md dark:text-white focus:ring-0 text-sm rounded',
        // OG:  'group placeholder-italic bg-light dark:bg-black pt-2 pb-2.5 shadow-lg dark:shadow-white-shadow text-md text-txt-dark dark:text-white focus:ring-0 border-none block w-full text-sm rounded',
        defaultBgColor,
        textColors,
        error
          ? 'pr-20 ring-1 ring-danger ring-inner focus:ring-danger focus:ring-inner'
          : 'focus:ring-0',
        isDisabled && 'placeholder-txt-light cursor-not-allowed',
        label ? 'pt-6' : 'pt-2',
        tooltip ? 'pr-12' : 'group',
      ],
      'block w-full border-none',
      classNameInput
    )

  return (
    <div className={cx('h-full relative', isFocusedHighlight && 'animate-highlight', className)}>
      {/* LABEL */}
      {label && <span className={labelCSS()}>{label}</span>}

      {/* INPUT */}
      <textarea
        autoFocus={isFocused || isFocusedHighlight}
        className={inputCSS()}
        disabled={isDisabled}
        name={name}
        placeholder={placeholder}
        rows={rows}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      />

      {/* ERROR INDICATOR */}
      {error && (
        <Tooltip
          className={cx(`absolute top-3.5 right-${tooltip ? '12' : '5'} p-1`)}
          color="danger"
          message={error}>
          <Icon className="text-danger" name="exclamation-circle" size="lg" />
        </Tooltip>
      )}

      {/* TOOLTIP */}
      {tooltip && (
        <Tooltip className="absolute top-3.5 right-5 p-1" icon="question-circle" {...tooltip}>
          <Icon className="text-blue-500" name="question-circle" size="lg" type="regular" />
        </Tooltip>
      )}
    </div>
  )
}
