// core
import React, { createContext } from 'react'
// components
import { Footer, Header, Loader } from 'components'
// modules
import { Router } from 'modules/navigation/index'
// utils
import { IViewport, useWindowWidth } from 'utils'

// ==================== Viewport context ====================
export const ViewportContext = createContext<IViewport>({
  windowWidth: 0,
  isSmallerThan: {
    sm: false,
    md: false,
    lg: false,
    xl: false,
    '2xl': false,
  },
  isLargerThan: {
    sm: false,
    md: false,
    lg: false,
    xl: false,
    '2xl': false,
  },
})

export default function App() {
  // ==================== Hooks ====================
  const viewportValues = useWindowWidth()

  return (
    <ViewportContext.Provider value={viewportValues}>
      {/* APP WRAPPER */}
      <div className="w-full h-full flex">
        {/* SCROLLABLE PAGE CONTENTS */}
        <div
          className="w-full h-full flex flex-col max-w-full max-h-screen overflow-auto"
          // #NOTE: Needed for ScrollInAnimation.scrollableParentSelector
          id="pageContent">
          <Header />

          <React.Suspense fallback={<Loader.Fullscreen />}>
            <div className="w-full h-full flex flex-col items-center relative">
              <Router />
            </div>
          </React.Suspense>

          <Footer />
        </div>
      </div>

      {/* NOTE: this is used for select menu to apply query selector for createPortal  */}
      <div className="z-55" id="select-menu" />
    </ViewportContext.Provider>
  )
}
