// core
import React from 'react'
// components
import { Icon, IDefaultProps, Loader, TIcon, TIconName } from 'components'
import { ButtonWrapper } from './ButtonWrapper'
import cx from 'classnames'
// libraries
import { overrideTailwindClasses as two } from 'tailwind-override'
import { Regexes } from 'utils'

export type TButtonColor =
  | 'primary'
  | 'secondary'
  | 'blue'
  | 'teal'
  | 'orange'
  | 'pink'
  | 'danger'
  | 'success'
  | 'warning'
  | 'grey'
  | 'black'
  | 'white'

export interface IButtonDefaultProps extends IDefaultProps {
  /**
   * Whether to allow click event and its propagation
   *
   * @default false
   */
  allowEventPropagation?: boolean
  /**
   * Classses for the label
   */
  classNameLabel?: string
  /**
   * Background color of the button
   *
   * @usage Each color is used for a specific case:
   *  `primary` - action, `secondary` - ??? TBD ???, `danger` - delete, `success` - save, `warning` - edit, `grey` - close,
   *
   * @default 'primary'
   */
  color?: TButtonColor
  /**
   * List of icon names to display on the RIGHT side
   *
   * Order in which they're defined is the order they're rendered in
   *
   * @default undefined
   */
  icon?: TIconName
  /**
   * Type of the FontAwesome icon
   *
   * @default 'solid'
   */
  iconType?: TIcon
  /**
   * Whether the button is currently in active state
   */
  isActive?: boolean
  /**
   * Whether the button is clickable
   *
   * @default false
   */
  isDisabled?: boolean
  /**
   * Whether the button should display a spinner and be disabled while process is being executed
   *
   * @default false
   */
  isLoading?: boolean
  /**
   * Text to display in the middle of the Button
   *
   * @default undefined
   */
  label?: string
  /**
   * href value if the button is an external link.
   *
   * @default undefined
   */
  link?: string
  /**
   * Whether the wrapper should be style-less
   */
  noStyles?: boolean
  /**
   * Size of the button - changes the proportions and padding
   *
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Button's type
   *
   * @default 'button'
   */
  type?: 'submit' | 'reset' | 'button'
  /**
   * Event called when button is clicked
   *
   * @default undefined
   */
  onClick?(e: React.MouseEvent): void
}

export const ButtonDefault = ({
  className,
  isLoading = false,
  icon,
  iconType = 'solid',
  label,
  link,
  classNameLabel,
  color,
  ...otherProps
}: IButtonDefaultProps) => {
  /**
   * Concats all Tailwind classes
   * @returns string
   */
  const twCSS = (): string => {
    return two(cx('p-3 justify-center', className, icon && 'space-x-2'))
  }

  const isExternalLink = link && new RegExp(Regexes.urlWithFileExtension).test(link)

  const button = (
    <ButtonWrapper className={twCSS()} color={color} isLoading={isLoading} {...otherProps}>
      {/* SPINNER / ICON  */}
      {isLoading ? (
        <Loader className={cx('mr-2', color === 'primary' && 'text-white')} />
      ) : (
        icon && <Icon className="mr-2" name={icon} type={iconType} />
      )}

      {/* LABEL */}
      {label && <span className={cx('font-effra min-w-max', classNameLabel)}>{label}</span>}
    </ButtonWrapper>
  )

  return isExternalLink ? <a href={link}>{button}</a> : button
}
