// core
import React from 'react'
// components
import { CompanyLogo } from 'components'

export const Header = () => {
  return (
    <>
      {/*   loggedInUser && windowWidth > EWindowWidth.md ? null : ( */}
      <div className="w-full flex flex-col inset-x-0 top-0 z-20">
        <header
          className="relative min-h-70px flex items-center justify-between bg-white text-black shadow-lg px-5
                lg:px-page-md 
                2xl:px-page">
          <CompanyLogo />

          {/* LINKS - hidden on mobile*/}
          <div className="hidden w-full items-center justify-center space-x-10 md:flex">
            {/* PROD-851 - not planned for MVP
                <Link
                className="cursor-pointer hover:underline hover:font-bold hover:text-primary"
                to={ERoutes.ABOUT}>
                About
              </Link> */}
          </div>
        </header>
      </div>
    </>
  )
}
