// core
import React, { useRef } from 'react'
// components
import { IDefaultProps, Image, ScrollInAnimation, Text } from 'components'
// libraries
import cx from 'classnames'
import { useCountUp } from 'react-countup'

interface ISocialProofBarProps extends IDefaultProps {
  /**
   * Text of the first card
   * @default 'Members and Counting'
   */
  textOne?: string
  /**
   * Number on the first card
   * @default '15000'
   */
  numberOne?: number
  /**
   * Text of the second card
   * @default 'Youtube Views'
   */
  textTwo?: string
  /**
   * Number on the second card
   * @default '21000000'
   */
  numberTwo?: number
  /**
   * Text of the third card
   * @default 'Facebook Group Members'
   */
  textThree?: string
  /**
   * Number on the third card
   * @default '15000'
   */
  numberThree?: number
}

export const SocialProofBar = ({
  textOne = 'Members and Counting',
  textTwo = 'Youtube Views',
  textThree = 'Facebook Group Members',
  numberOne = 15000,
  numberTwo = 21000000,
  numberThree = 15000,
  className,
}: ISocialProofBarProps) => {
  return (
    <div
      className={cx(
        `max-w-sm mx-auto py-10 text-center px-2 xxs:px-4 xs:px-6
                    lg:gap-4  lg:max-w-screen-xl lg:grid lg:grid-cols-3 xl:gap-12`,
        className
      )}>
      <SocialProofCard
        img="images/trial-page-members-icon.svg"
        label={textOne}
        metric={numberOne}
      />

      <SocialProofCard img="images/trial-page-yt-icon.svg" label={textTwo} metric={numberTwo} />

      <SocialProofCard
        img="images/trial-page-facebook-icon.svg"
        label={textThree}
        metric={numberThree}
      />
    </div>
  )
}

interface ISocialProofCardProps extends IDefaultProps {
  img: string
  metric: number
  label: string
}

const SocialProofCard = ({ img, metric, label }: ISocialProofCardProps) => {
  // ==================== Context ====================
  const countUpRef = useRef(null)

  const { start } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: metric,
    delay: 0,
    duration: 2.5,
    separator: ',',
    suffix: '+',
  })

  return (
    <ScrollInAnimation
      // @ts-ignore
      afterAnimatedIn={() => {
        start()
      }}
      animateIn="animFadeInRight"
      className="py-4 px-2 my-4 rounded-3xl text-left shadow-center-light bg-white xxs:px-4 xs:px-7"
      duration={0}
      offset={0}>
      <div className="grid grid-cols-12 gap-2 items-center">
        <Image className="w-4/5 p-4 rounded-2xl bg-blue-lightest col-span-5 lg:w-4/5" src={img} />

        <div className="col-span-7">
          <div ref={countUpRef} className="font-sspb text-3xl" />

          <Text content={label} />
        </div>
      </div>
    </ScrollInAnimation>
  )
}
