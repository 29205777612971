// core
import React, { useCallback } from 'react'
// assets
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import { IDefaultProps, Image } from 'components'
// libraries
import cx from 'classnames'
// modules
import { ERoutes, useRouter } from 'modules/navigation'

interface ICompanyLogoProps extends IDefaultProps {
  /**
   * URL of the asset (img/svg) used for the logo, if provided, this overrides the default `logo.svg`
   * @default 'assets/img/logo.svg'
   */
  assetUrl?: string
  /**
   * URL where user is redirected when they click the logo
   * @default '/'
   */
  url?: string
}

/**
 * Renders the company logo
 */
export const CompanyLogo = ({ assetUrl, className }: ICompanyLogoProps) => {
  // ==================== Hooks ====================
  const [, goTo] = useRouter()

  // ==================== Events ====================
  const _onClick = useCallback(() => {
    goTo().ext(ERoutes.PDS)
  }, [goTo])

  return (
    <div className={cx('clickable w-12 h-12', className)} onClick={_onClick}>
      {assetUrl ? <Image src={assetUrl} /> : <Logo />}
    </div>
  )
}
