// core
import React from 'react'
// components
import { AvatarDefault, IAvatarDefaultProps } from './variants/AvatarDefault'
// variants
import { AvatarDetailed } from './variants/AvatarDetailed'

export class Avatar extends React.Component<IAvatarDefaultProps> {
  static Detailed = AvatarDetailed

  render() {
    return <AvatarDefault {...this.props} />
  }
}
