// core
import React from 'react'
// libraries
import BugsnagLib from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

export const Bugsnag = {
  /** Retrieves the API key from the .env file */
  getKey: (): string | undefined | null => process.env.REACT_APP_BUGSNAG_API_KEY,

  /** Initializes a new instance of Bugsnag */
  init: () => {
    const BUGSNAG_API_KEY = Bugsnag.getKey()

    if (BUGSNAG_API_KEY) {
      BugsnagLib.start({
        apiKey: BUGSNAG_API_KEY,
        appType: 'client',
        appVersion: process.env.REACT_APP_BUILD_NUMBER,
        // enabledBreadcrumbTypes: [
        //   'navigation', 'process','log','user','state','error','manual',// 'request', // leave custom breadcrubms in Apollo link
        // ],
        plugins: [new BugsnagPluginReact()],
        // redactedKeys: ['password', 'authorization'],
      })
    }
  },

  setUser: (userId?: string | null, userEmail?: string | null, userFullName?: string | null) => {
    if (!userId) return

    const handleError = (event: any) => {
      event.setUser(userId, userEmail, userFullName)
    }
    BugsnagLib.addOnError(handleError)

    return () => {
      BugsnagLib.removeOnError(handleError)
    }
  },

  /** Creates an ErrorBoundry */
  errorBoundary: (): React.ComponentType<any> => {
    let ErrorBoundary: React.ComponentType<any> = React.Fragment

    const bugsnagReactPlugin = BugsnagLib.getPlugin('react')

    if (bugsnagReactPlugin) {
      ErrorBoundary = bugsnagReactPlugin.createErrorBoundary(React)
    }

    return ErrorBoundary
  },
}
