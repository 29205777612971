// core
import React from 'react'
// components
import { IDefaultWrapperProps } from 'components'
import { Text } from './Text/Text'
import { Icon, TIcon, TIconName } from './Icon'
// libraries
import cx from 'classnames'
// utils

interface INoDataProps extends Omit<IDefaultWrapperProps, 'children'> {
  /**
   * Collection of components that represent fetched data/records
   * @default []
   */
  children: React.ReactNode[]
  /**
   * Name of the icon to override the default one
   * @default 'file-alt'
   */
  icon?: TIconName
  /**
   * Type of the icon to override the default one
   * @default 'light'
   */
  iconType?: TIcon
  /**
   * Whether the wrapper should render it's chidren in a row horizontally
   * @default false - will render them in a collumn vertically
   */
  isRow?: boolean
  /**
   * Text to override the default message
   * @default "There are no records"
   */
  message?: string
}

/**
 * Wrapper for displaying lists of fetched data via `children` prop.
 * If none are provided, it renders an icon and a message informing that there are no records to display
 */
export const NoData = ({
  children = [],
  className,
  icon = 'file-alt',
  iconType = 'light',
  isRow = false,
  message = 'There are no records',
}: INoDataProps) => {
  const hasChildren = children.length > 0
  const defaultCSS = `w-full h-full flex ${isRow ? 'flex-row' : 'flex-col'}`

  return (
    <div
      className={cx(
        hasChildren
          ? `${defaultCSS} ${className}`
          : `${defaultCSS} items-center justify-center space-y-10 py-40`
      )}>
      {hasChildren ? (
        children
      ) : (
        <>
          <Icon name={icon} size="3x" type={iconType} />

          <Text className="text-2xl" content={message} />
        </>
      )}
    </div>
  )
}
