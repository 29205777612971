// core
import React, { useContext, useEffect, useRef, useState } from 'react'
// components
import { IDefaultProps } from 'components'
import { ViewportContext } from 'App'
// libraries
import cx from 'classnames'
// utils
import { EWindowWidth, scrollToRef, useIsOnScreen } from 'utils'
//
import { Image } from '../Image'
import { ScrollInAnimation } from '../ScrollInAnimation'
import { Text } from '../Text/Text'

// styles
import './AttachmentStylesTabs.scss'

export const ATTACHMENT_STYLES = [
  {
    title: 'Anxious Preoccupied',
    content: `Relationships can often make you feel anxious, unsafe or insecure because you likely have a subconscious fear of abandonment. 
          
          As a result, you seek more closeness in your relationships, and can feel afraid if you sense a loved one is pulling away. `,
    assetUrl: 'images/attachment-style-ap.svg',
  },
  {
    title: 'Fearful Avoidant',
    content: `Relationships can feel chaotic, confusing and overwhelming because you swing between being avoidant and anxious.
          
          Depending on the relationship, you can shift between being "hot and cold," often feeling confused about your feelings.`,
    assetUrl: 'images/attachment-style-fa.svg',
  },
  {
    title: 'Dismissive Avoidant',
    content: `Intense emotions can feel overwhelming and can cause you to pull away from others. You may find yourself withdrawing from arguments or triggering situations.
          
          This need for independence can cause challenges in your relationships and inner conflict for you, because deep down, you want to connect with others.`,
    assetUrl: 'images/attachment-style-da.svg',
  },
  {
    title: 'Securely Attached',
    content: `You often feel comfortable and at ease in relationships. You’re also good at communicating your needs and feelings, and feel open to vulnerability in your relationships.
          
          However, sometimes you can experience difficulty when relating to those who aren’t as secure in relationships.`,
    assetUrl: 'images/attachment-style-sa.svg',
  },
]

interface IAttachmentTabsSectionProps extends IDefaultProps {
  /** Where the component is being used
   * @homepage - btns w/ blue-light bg
   * @quizpage - btns w/ primary bg
   */
  type?: 'homepage' | 'quizpage'
}

export const AttachmentStylesTabs = ({ className, type = 'homepage' }: IAttachmentTabsSectionProps) => {
  //   const [activeStyle, setActiveStyle] = useState<0 | 1 | 2 | 3 | null>(null)
  const [activeStyle, setActiveStyle] = useState<number>(0)
  const interval = useRef(null)
  const refCard = useRef<HTMLDivElement>(null)
  const { windowWidth } = useContext(ViewportContext)

  const isInViewport = useIsOnScreen(refCard)

  //   useEffect(() => {
  //     setTimeout(() => setActiveStyle(0), 1200)
  //   }, [])

  // Loop through the attachement style tabs every 7 seconds
  useEffect(() => {
    if (!isInViewport) return

    // @ts-ignore
    interval.current = setInterval(() => {
      setActiveStyle((current) => {
        return current === 3 ? 0 : current + 1
      })
    }, 7000)

    // clear on component unmount
    return () => {
      // @ts-ignore
      clearInterval(interval.current)
    }
  }, [isInViewport])

  const onClickAttachmentStyleTab = (index: number) => () => {
    setActiveStyle(index as any)
    // @ts-ignore
    clearInterval(interval.current)

    scrollToRef(refCard, windowWidth < EWindowWidth.sm ? 120 : -40)
  }

  return (
    <section ref={refCard} className={cx('w-full flex-center flex-col mb-10 lg:mb-32', className)}>
      {/* TABS */}
      <div className="attachment-tabs-section w-auto grid grid-cols-1 xs:grid-cols-2 2xl:grid-cols-4">
        {ATTACHMENT_STYLES.map((style, index) => (
          <ScrollInAnimation
            key={`attachment_style_btn_${index}`}
            animateIn="animFadeInUp"
            className="flex-center flex-col-2"
            delay={index * 200}
            style={{ maxWidth: '320px' }}>
            <button
              key={`attachment_style_btn_${index}`}
              className={cx(
                'clickable break-words flex-col-2 tracking-0.325 rounded-md px-4 py-5 shadow-lg text-xs lg:text-base lg:rounded-20 lg:px-12',
                activeStyle === index ? 'bg-white' : 'bg-primary-old text-white'
              )}
              style={{ overflowWrap: 'break-word' }}
              onClick={onClickAttachmentStyleTab(index)}>
              {style.title.toUpperCase()}
            </button>
          </ScrollInAnimation>
        ))}
      </div>

      {/* CARD */}
      <div
        className="w-full flex-center px-page-xxs mb-10 
            md:px-page-xs
            lg:px-0 lg:mb-0">
        <ScrollInAnimation
          animateIn="animFadeInUp"
          className="w-full flex-col-reverse lg:flex-row flex-center md:max-w-3/4 xl:max-w-1/2 flex md:space-x-5 lg:space-x-10 bg-white rounded-20 shadow-lg text-left px-5 lg:px-15 py-8 lg:py-10"
          delay={800}>
          <Image
            className="w-full mt-10 
                md:w-1/2 
                lg:w-56 lg:mt-0"
            src={ATTACHMENT_STYLES[activeStyle as any].assetUrl}
          />

          <div className="flex flex-1 flex-col">
            <Text.Heading
              className="mb-3"
              content={ATTACHMENT_STYLES[activeStyle as any].title}
              size={3}
            />

            <Text.Paragraph content={ATTACHMENT_STYLES[activeStyle as any].content} />
          </div>
        </ScrollInAnimation>
      </div>
    </section>
    // <div className="w-full flex-center flex-col mb-10 lg:mb-32">
    //   {/* flex items-center justify-center flex-wrap */}
    //   <div className="w-auto grid grid-cols-2 2xl:grid-cols-4 mb-10 gap-3">
    //     {ATTACHMENT_STYLES.map((style, index) => (
    //       <ScrollInAnimation
    //         key={`attachment_style_btn_${index}`}
    //         animateIn="animFadeInUp"
    //         className="w-full flex-center max-w-[320px]"
    //         delay={index * 200}>
    //         <button
    //           key={`attachment_style_btn_${index}`}
    //           className={cx(
    //             'clickable w-full flex-center tracking-0.325 rounded-md shadow-lg text-xs px-5 py-5',
    //             'lg:rounded-20 lg:text-base lg:px-12',
    //             activeStyle === index ? 'bg-white text-black font-bold' : 'bg-primary text-white'
    //           )}
    //           onClick={() => setActiveStyle(index as any)}>
    //           {style.title.toUpperCase()}
    //         </button>
    //       </ScrollInAnimation>
    //     ))}
    //   </div>

    //   {/* ILLUSTRATION + DESCRIPTION */}
    //   <div
    //     className="w-full flex-center mb-10
    //         md:px-page-xs
    //         lg:px-0 lg:mb-0">
    //     {activeStyle !== null && (
    //       <ScrollInAnimation
    //         animateIn="animFadeInUp"
    //         className="w-full md:max-w-3/4 xl:max-w-1/2 flex-col-reverse flex-center bg-white rounded-20 shadow-lg text-left py-8 px-11
    //             md:space-x-5
    //             lg:flex-row lg:space-x-10 lg:py-10 "
    //         // className="w-full flex-col-reverse lg:flex-row flex-center md:max-w-3/4 xl:max-w-1/2 flex md:space-x-5 lg:space-x-10 bg-white rounded-20 shadow-lg text-left px-5 lg:px-15 py-8 lg:py-10"
    //         delay={800}>
    //         <Image
    //           className="w-full mt-10
    //             md:w-1/2
    //             lg:w-56 lg:mt-0"
    //           src={ATTACHMENT_STYLES[activeStyle as any].assetUrl}
    //         />

    //         <div className="flex flex-1 flex-col">
    //           <Text.Heading
    //             className="mb-3"
    //             content={ATTACHMENT_STYLES[activeStyle as any].title}
    //             size={3}
    //           />

    //           <Text.Paragraph content={ATTACHMENT_STYLES[activeStyle as any].content} />
    //         </div>
    //       </ScrollInAnimation>
    //     )}
    //   </div>
    // </div>
  )
}
