// .core
import React from 'react'
// components
import { ILoaderDefaultProps } from '../LoaderDefault'
// styles
import css from './Line.module.scss'

interface ILoaderLineProps extends ILoaderDefaultProps {
  /**
   * Where to position the loading line within the parent, at the top or bottom
   */
  position?: 'bottom' | 'top'
}

/**
 * `Line` variant of animated loading indicator
 */
export const LoaderLine = ({ isLoading, position, style }: ILoaderLineProps) => {
  const _style: React.CSSProperties = { ...style }

  if (position === 'bottom') {
    _style.bottom = 0
  }

  return isLoading ? (
    <div className={css.loaderLine} style={_style}>
      <div />
    </div>
  ) : null
}
