// core
import React, { useContext } from 'react'
// components
import { CompanyLogo, IDefaultProps } from 'components'
import { Link } from './Link'
import { ViewportContext } from 'App'
import moment from 'moment'
// modules
import { ERoutes } from 'modules/navigation'
// utils
import { EWindowWidth } from 'utils'

interface IFooterProps extends IDefaultProps {}

export const Footer = ({}: IFooterProps) => {
  // ==================== Context ====================
  const { windowWidth } = useContext(ViewportContext)

  return (
    <footer
      className="footer w-full flex flex-col text-white bg-footer p-6
        md:px-page-md
        lg:pt-12 lg:pb-8
        2xl:px-page">
      <div className="footer-content flex flex-1 flex-col mb-8 lg:flex-row">
        {/* LEFT PART */}
        <div className="w-full flex flex-col items-center lg:items-start">
          {/* LOGO */}
          <CompanyLogo assetUrl="images/logo-footer.svg" className="mb-8 w-24 h-24" />

          {/* SOCIAL ICONS */}
          <div
            className="w-full flex justify-evenly pb-8 
                lg:space-x-8 lg:justify-start lg:pb-10">
            <Link.Icon
              className="text-primary-light"
              name="youtube"
              size={windowWidth <= EWindowWidth.md ? '2x' : 'lg'}
              type="brands"
              url={ERoutes.YOUTUBE}
            />
            <Link.Icon
              className="text-primary-light"
              name="twitter"
              size={windowWidth <= EWindowWidth.md ? '2x' : 'lg'}
              type="brands"
              url={ERoutes.TWITTER}
            />
            <Link.Icon
              className="text-primary-light"
              name="facebook"
              size={windowWidth <= EWindowWidth.md ? '2x' : 'lg'}
              type="brands"
              url={ERoutes.FACEBOOK}
            />
            <Link.Icon
              className="text-primary-light"
              name="instagram"
              size={windowWidth <= EWindowWidth.md ? '2x' : 'lg'}
              type="brands"
              url={ERoutes.INSTAGRAM}
            />
            <Link.Icon
              className="text-primary-light"
              name="podcast"
              size={windowWidth <= EWindowWidth.md ? '2x' : 'lg'}
              url={ERoutes.PODCASTS}
            />
          </div>
        </div>

        {/* RIGHT PART */}
        <div className="w-full flex flex-wrap text-white justify-between lg:pb-10" />
      </div>

      {/* COPYRIGHT */}
      <span className="text-right">
        © Copyright {moment().year()} The Personal Development School All rights Reserved
      </span>
    </footer>
  )
}
