// libraries
import mixpanel from 'mixpanel-browser'

/* Collection of all Mixpanel event names tracked throughout the app */
type Events =
  | 'Button Clicked'
  | 'Course Filterd'
  | 'Course Landing Page'
  | 'Course Searched'
  | 'Course Started'
  | 'Enrollment: Complete'
  | '$experiment_started'
  | 'Invoice Paid'
  | 'Lesson Finished'
  | 'Lesson Started'
  | 'Logs In'
  | 'Log Out'
  | 'Membership Ended'
  | 'Membership Started'
  | 'Quiz Finished'
  | 'Quiz Started'
  | 'Quiz Progress'
  | 'Page Viewed'
  | '$signup'
  | 'Trial Ended'
  | 'Trial Started'

export const Mixpanel = {
  /** Retrieves the API key from the .env file */
  getKey: (): string | undefined | null => process.env.REACT_APP_MIXPANEL_API_KEY,

  /** Initializes a new instance of the Mixpanel tracking object */
  init: () => {
    const MIXPANEL_API_KEY = Mixpanel.getKey()

    if (MIXPANEL_API_KEY) {
      mixpanel.init(MIXPANEL_API_KEY, {
        // config override goes here
      })
    }
  },

  /**
   * Identify a logged-in user to track their activity across device
   *
   * If you never call this method, unique visitors are tracked using a UUID generated the first time they visit the site.
   *
   * @param userId Unique ID of a currently logged in user
   */
  setUser: (userId?: string | null) => {
    if (!userId) return

    mixpanel.identify(String(userId))
  },

  /**
   * Tracks a specific event with given name and props
   *
   * @param event Name of the event to track
   * @param props Additional props to track
   */
  event: (event: Events, props: any) => {
    //   #TODO: create a "Map" of event key <-> event props a.k.a props based on an event
    mixpanel.track(event, props)
  },

  /**
   * Starts tracking time until the same event is called.
   *
   * Time tracking stops when `mixpanel.track(event)` is called with the same event
   *
   * Same thing as `console.time()` and `console.timeEnd()`
   */
  eventTime: (event: Events) => mixpanel.time_event(event),

  /**
   * Sets the Utm params of the user explicitly for the first time 
   * all first touch events are recorded and this function can only be called once
   */
  setPeopleOnce: (setEvents: any) => {
    mixpanel.people.set_once(setEvents)
  },

  /**
   * Sets the Utm params of the user
   * all last touch events are recorded and this function can be called several times
   */
  setPeople: (setEvents: any) => {
    mixpanel.people.set(setEvents)
  },
}
