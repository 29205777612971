// core
import React, { createContext, useEffect, useMemo, useReducer, useState } from 'react'
import { EWindowWidth, IViewport, TBreakpoints } from 'utils'

//  ==============================
//           E R R O R S
//  ==============================

/**
 * A custom hook for loging errors into the console
 * @param origin Where the error originated from (name of the file / method)
 * @param errors list of errors
 * @example
 * // Instead of always writing this:
 * if (query_a_error || query_b_error || query_c_error) {
 *  console.error(query_a_error, query_b_error, query_c_error)
 * }
 *
 * // You can now use this hook:
 * useConsoleErrors('LoginPage', query_a_error, query_b_error, query_c_error)
 */
export function useConsoleErrors(origin: string, ...errors: any[]) {
  useEffect(() => {
    if (errors.length) {
      //   console.group(origin)
      errors.forEach((e) => e && console.error(`Error occured at ${origin}:\n`, e))
      //   console.groupEnd()
    }
  }, [origin, errors])
}

//  ==============================
//          S E A R C H
//  ==============================

type State = {
  isHidden?: boolean
  /**
   * Current search value
   */
  value?: string | null
}

export type SearchContextOptions = State & {
  /**
   * Set search value
   */
  setValue: (value: string | null) => void
  setHidden: (hidden: boolean) => void
  /**
   * Reset search to initial values
   */
  reset: () => any
}

const defaultOptions: SearchContextOptions = {
  isHidden: false,
  value: null,
  reset: () => {
    // do nothing
  },
  setHidden: (_) => {
    // do nothing
  },
  setValue: () => {
    // do nothing
  },
}

export const SearchContext = createContext<SearchContextOptions>(defaultOptions)

function reducer(
  state: State,
  { type, value }: { type: 'value'; value: string | null } | { type: 'reset'; value: undefined }
) {
  switch (type) {
    case 'value':
      return { ...state, value: value ? value.toString() : null }
    case 'reset':
      return {
        ...state,
        value: undefined, // set to undefined to let component initialize value form local storage
      }
    default:
      throw new Error()
  }
}

export function useSearchProvider(): SearchContextOptions {
  const [searchApi, dispatch] = useReducer(reducer, {
    value: undefined,
  })

  const [isHidden, setHidden] = useState<boolean>(true)

  const contextOptions = useMemo(
    () => ({
      ...searchApi,
      isHidden,
      reset: () => {
        dispatch({ type: 'reset', value: undefined })
        setHidden(false)
      },
      setHidden,
      setValue: (value: string | null) => dispatch({ type: 'value', value }),
    }),
    [searchApi, dispatch, isHidden, setHidden]
  )

  return contextOptions
}

// ==============================
//          R E S I Z E
// ==============================
/**
 * A custom hook for getting width of window intime on resize
 * @returns windowWidth as number
 * @usage hook can be used by comparing returned windowWidth with breakpoints from 'EWindowWidth'
 */
export function useWindowWidth(): IViewport {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    function onResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', onResize)

    // initial window update
    onResize()

    return () => window.removeEventListener('resize', onResize)
  }, [])

  const breakpointNames = Object.keys(EWindowWidth).filter((key) =>
    isNaN(key as any)
  ) as TBreakpoints[]

  return {
    windowWidth,
    isLargerThan: breakpointNames.reduce(
      (arr, val) => ({
        ...arr,
        [val]: windowWidth > EWindowWidth[val],
      }),
      {}
    ) as IViewport['isLargerThan'],
    isSmallerThan: breakpointNames.reduce(
      (arr, val) => ({
        ...arr,
        [val]: windowWidth < EWindowWidth[val],
      }),
      {}
    ) as IViewport['isSmallerThan'],
  }
}

export const PageTitleContext = createContext<{ title?: string }>({ title: undefined })

// ==============================
//          R E S I Z E
// ==============================
export function useIsOnScreen(ref: React.Ref<any>) {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

  useEffect(() => {
    // @ts-ignore
    observer.observe(ref?.current)
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [])

  return isIntersecting
}

