// core
import React from 'react'
// components
import { IDefaultProps, Image, Text } from 'components'
// libraries
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// utils
import { TRIAL_PAGE } from 'utils/config'

import 'swiper/css'
import 'swiper/css/pagination'

export const TestimonialSlider = ({}: IDefaultProps) => {
  return (
    <div className="w-full flex relative" style={{ maxWidth: '100vw' }}>
      <Swiper
        autoplay={{
          delay: 6000,
          reverseDirection: true,
        }}
        className="!py-2 xxs:!py-3 xs:!py-6 !px-2 lg:!px-18"
        modules={[Autoplay, Pagination]}
        pagination={{
          bulletActiveClass: 'bg-black',
          bulletClass:
            'inline-block w-4 h-4 mx-1 bg-white border-2 rounded-full border-black cursor-pointer',
          clickable: true,
          horizontalClass: 'swiper-pagination-horizontal !bottom-16',
        }}
        slidesPerView={1}
        spaceBetween={96}>
        {TRIAL_PAGE.testimonials.map((testimonial, index) => (
          <SwiperSlide
            key={`testimonial_${index}`}
            className="p-6 py-12 shadow-centered rounded-2xl lg:px-8">
            <div className="flex mb-6">
              <Image className="mr-2" src="images/quote.svg" />
              <Image src="images/quote.svg" />
            </div>

            <div className="pl-5 border-l-[12px] border-blue-lightest">
              <div className="flex items-center mb-6">
                <Image
                  className="rounded-full overflow-hidden max-w-[40px]"
                  src={testimonial.img}
                />
                <Text className="font-semibold ml-3" content={testimonial.author} />
              </div>

              <Text useMD content={testimonial.testimonial} />
            </div>

            <div className="flex mt-6">
              <Image className="ml-auto mr-2 -scale-x-100" src="images/quote.svg" />
              <Image className="-scale-x-100" src="images/quote.svg" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
