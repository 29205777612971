// API
// import { GetAllAccessGroups_sysAccessGroups as IAccessGroup } from 'api/AccessRights/types/GetAllAccessGroups'
// libraries
// import { getTranslation, showToastErr } from 'components'
// utils
import React from 'react'
import { ECurrencies, EDateFormats, Regexes } from './constants'
import { IObject, ISelectItem } from './interfaces'
import { TFileSizeUnits } from './types'
// libraries
import { ApolloError } from '@apollo/client'
import { FormikProps } from 'formik'
import { find, findIndex, get, isEqual, omit, omitBy } from 'lodash'
import moment from 'moment'

//
// ======================================== Form - related =======================================
//

/**
 * Updates the value (and touched state) of a provided field within form w/ the value
 * @param form Formik's `form` instance containing all form's props
 * @param fieldPath path to the form's field
 */
export const bind =
  <T,>(form: FormikProps<T>, fieldPath: keyof T & string) =>
  (value: any) => {
    form.setFieldTouched(fieldPath, form.initialValues[fieldPath] !== value)
    form.setFieldValue(fieldPath, value)
  }

/**
 * Converts passed `Enum` into an array of options for any `Select` component
 * @param ENUM enum to convert
 * @param returnSelectOptions whether the returned array should have `ISelectItem` structure
 * @return array of options
 */
export const enumToArray = (ENUM: any, returnSelectOptions?: true): string[] | ISelectItem[] => {
  const arr: string[] | ISelectItem[] = []
  for (const i in ENUM) {
    arr.push(
      returnSelectOptions
        ? // Converts the `enum` into `ISelectItem` structure - used for `Select` comp.
          ({ id: String(ENUM[i]), name: `ENUMS - ${i}` } as ISelectItem)
        : // Converts the `enum` into array of its values
          (i as any)
    ) // ...is a string but TS will bitch otherwise
  }
  return arr
}

//
// ======================================== Data parsing ========================================
//

/**
 * Function to parse date into formatted string value
 * @param date date input which should be formated
 * @param format format into which will be date formatted
 * @default 'EDateFormats.DEFAULT_DATETIME'
 * @returns 'string'
 */
export const parseDate = (
  date: Date | number,
  format: EDateFormats = EDateFormats.DEFAULT_DATETIME,
  validate: boolean = true
) => {
  const dateValue = moment(date)

  if (validate) return dateValue.isValid() ? dateValue.format(format) : '-'

  return dateValue.format(format)
}

/**
 * Converts number from provided unit to number in bytes
 * @param number Number in specified unit
 * @param from Unit
 * @returns Number in bytes
 */
export const convertToBytes = (number: number, from: TFileSizeUnits): number => {
  const unitToExponent: { [key in TFileSizeUnits]: number } = {
    TB: 4,
    GB: 3,
    MB: 2,
    KB: 1,
    B: 0,
  }

  return number * Math.pow(1000, unitToExponent[from]) // this is 1000 and not 1024, because we are using kB/MB/GB and not kiB/MiB/GiB
}

/**
 * A unified method for parsing API errors
 * @param error Error received from the API
 */
export const parseAPIErrors = (error: ApolloError) => {
  // This can be expanded upon if need be (custom error parsing etc.)
  const errorVariables = error.graphQLErrors.map((error) => error.extensions.variables)

  //   showToastErr(error.message as TranslationKeys, errorVariables[0])
}

/**
 * a pure function that returns modified version of provided array based on selected parse method
 * @param array a collection of data (both primitive & complex) that will be parsed
 *
 * @example
 * const updatedValues = parseArray<string>(previousValues).add(newValues, 'end')
 */
export const parseArray = <T,>(collection: T[]) => {
  const arr: T[] = [...collection]
  const bObjectArray: boolean = typeof arr[0] === 'object'

  return {
    /**
     * Adds element(s) to the array at given place / index
     * @param values elements to add to the array
     * @param at place || index where to add said elements
     * @param bUniqueOnly whether to check for existing duplicates, element is added only if it's unique
     * @returns collection w/ added elements at specified place/index
     */
    add: (values: T[], at: 'start' | 'end' | number = 'end', bUniqueOnly?: boolean): T[] => {
      const duplicates = parseArray([...arr, ...values]).duplicates('get')
      let valuesToAdd = [...values]

      if (bUniqueOnly && bObjectArray) {
        console.warn(
          'parseArray.add - type object && bUniqueOnly \n\t Adding uniques only to object[] is not yet supported ! \n\t Returning default array.'
        )

        return collection
      }

      if (bUniqueOnly && duplicates.length) {
        valuesToAdd = parseArray(values).remove(duplicates.length, undefined, [...duplicates])

        console.warn(
          "parseArray.add - bUniqueOnly \n\t VALUE you're trying to add already exists within the array !"
        )
      }

      if (at === 'start') {
        arr.unshift(...valuesToAdd)
      } else if (at === 'end') {
        arr.push(...valuesToAdd)
      } else {
        arr.splice(at, 0, ...valuesToAdd)
      }

      return [...arr]
    },

    addOrRemove: (values: T[]) => {
      values.map((value) => {
        const index = arr.findIndex((i) => i === value)

        if (index >= 0) {
          arr.splice(index, 1)
        } else {
          arr.push(value)
        }
      })

      return arr
    },

    /**
     * Based on passed action, either removes the duplictes from collection, or returns them
     * @param action what to do with the duplicates, either `remove` or `get` them
     * @param key by which key of the object should the duplicates be indentifieds
     * @returns all duplicate items within an array `||` array without any duplicates
     */
    duplicates: (action: 'get' | 'remove', key?: keyof T): T[] => {
      // Working w/ object-type array
      if (bObjectArray && key) {
        const tempObj: IObject<T> = {}

        arr.forEach((item) => {
          // @ts-ignores
          tempObj[item[key]] = item
        })

        const newArr = []
        for (const objKey in tempObj) newArr.push(tempObj[objKey])

        return arr
      }

      // Working w/ primitive-type array
      return action === 'get'
        ? arr.filter((i, ii) => arr.indexOf(i) !== ii)
        : arr.filter((i, ii) => arr.indexOf(i) === ii)
    },

    /**
     * Loops through provided collection of values and tries to find them in the original array
     * @param values collection of values to find
     * @param bIndexOnly whether to return only indexes of searched values or the values themselves
     * @param functionIterator iteration method that gets executed within `_.findIndex` if arr[0] === object
     * @returns either a collection w/ indexes of searched values or values themselves found in the orinal array
     */
    find: (
      values: T[],
      bIndexOnly?: boolean,
      functionIterator?: (i: T) => boolean
    ): T[] | number[] => {
      const res: T[] | number[] = []

      if (bObjectArray) {
        if (!functionIterator) {
          arr.map((originalElement, originalElementIndex) =>
            values.map((searchedElement) => {
              if (isEqual(originalElement, searchedElement)) {
                bIndexOnly
                  ? (res as number[]).push(originalElementIndex)
                  : (res as T[]).push(searchedElement)
              }
            })
          )
          return res
        }

        const foundObject = find(arr, functionIterator)

        if (bIndexOnly) return [findIndex(arr, functionIterator)]

        return foundObject ? [foundObject] : []
      }

      values.map((val) => {
        if (bIndexOnly) {
          const index = arr.findIndex((i) => i === val)
          if (index >= 0) (res as number[]).push(index)
        } else {
          const element = arr.find((i) => i === val)
          if (element) (res as T[]).push(element)
        }
      })

      return res
    },

    /**
     * Oposite to `add()`, removes elements from the array at given place / index
     * @param count number of elements to remove
     * @param values elements to remove from the array
     * @param at place || index from where the elements should be removed
     */
    remove: (count: number, at: 'start' | 'end' | number = 'end', values?: T[]): T[] => {
      if (values) {
        ;(parseArray(arr).find(values, true) as number[]).map((valueIndex, index) =>
          arr.splice(valueIndex - index, 1)
        )
        return [...arr]
      }

      if (at === 'start') {
        arr.splice(0, count)
      } else if (at === 'end') {
        arr.splice(arr.length - 1, count)
      } else if (at >= 0) {
        arr.splice(at, count)
      }

      return [...arr]
    },

    /**
     * Searches for provided `property` through collection of entries, and compares it to specified `searchTerm`
     * @param property property to search for within entries of the collection
     * @param searchTerm value of the `property` to search for -- ! ONLY STRINGS ARE SUPPORTED !
     */
    searchBy: (property: keyof T, searchTerm: string): T[] => {
      if (!collection.length) {
        if (!bObjectArray) {
          console.warn(
            'parseArray.searchBy - array type is NOT an object ! \n\t searchBy method works only with object[], please provided correct array type. \n\t Returning default array.'
          )
        }

        return collection
      }

      return arr.filter((i) => {
        const foundValue = get(i, property)

        if (typeof foundValue === 'string')
          return foundValue.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      })
    },
  }
}

export const parseNumber = (num?: number | string, fallbackValue: number = 0) => {
  /**
   * Checker for cases when a pure string/word (non-number wrapped in quotation marks, e.g: "hello") is passed as a `num` parameter
   */
  const bInvalid = isNaN(Number(num))
  const number = bInvalid ? fallbackValue : Number(num)

  bInvalid &&
    console.error(
      'parseNumber()\n' +
        '\n\t Provided number is a pure string and cannot be converted to a number !\n' +
        '\n\t Please provide either a valid number (int, float) or a number wrapped in quotation marks.\n' +
        '\n\t Using fallback value (0) instead.'
    )

  return {
    /**
     * Retrieves the max value from number array
     */
    getMax: (numbers: number[]) => (numbers.length ? Math.max(...numbers) : undefined),

    /**
     * Retrieves the min value from number array
     */
    getMin: (numbers: number[]) => (numbers.length ? Math.min(...numbers) : undefined),

    isInt: () => (bInvalid ? false : Number.isInteger(number)),

    isNan: (): boolean => bInvalid, // isNaN(number),

    isNegative: (bZeroIncluded: boolean = false): boolean =>
      bZeroIncluded ? number <= 0 : number < 0,

    isPositive: (bZeroIncluded: boolean = false): boolean =>
      bZeroIncluded ? number >= 0 : number > 0,

    isValid: () => !bInvalid,

    /**
     * Whether the number is within specified range
     */
    isWithinRange: (min: number, max: number): boolean =>
      bInvalid ? false : number >= min && number <= max,

    /**
     * Generates a random number (can be restricted)
     * @param min bottom limit
     * @param  max upper limit
     * @return A random number, restricted within bounds of `min` && `max` - if provided
     */
    random: (min: number = 0, max: number = 0) => Math.floor(Math.random() * (max - min + 1) + min),

    /**
     * Rounds the number
     * @param decimalCount how many decimals should be left after rounding
     * @param to either force-rounds the number up or down, regardless of the actual decimal values
     */
    round: (decimalCount: number = 0, to?: 'up' | 'down'): number => {
      // Rounding down - regardless of 1st decimal value (e.g.: passing `12.9` will return `12`)
      if (to === 'down') return Math.floor(number)

      // Rounding up - regardless of 1st decimal value (e.g.: passing `12.1` will return `13`)
      if (to === 'up') return Math.ceil(number)

      // Default rounding - based on 1st decimal, also limits the number of decimals, def.: 0
      return Number(number.toFixed(decimalCount))
    },

    /**
     * Converts the number into a currency value
     * @param currency which currency mark to append - def. value can be specified from global config
     * @param position where to append the currency mark to, before || after the number
     * @param bAddSpacing whether to add single empty space between the number and the currency
     */
    toCurrency: (
      currency: keyof typeof ECurrencies,
      position: 'start' | 'end' = 'start',
      bAddSpacing?: boolean
    ): string =>
      position === 'start'
        ? `${ECurrencies[currency]}${bAddSpacing ? ' ' : ''}${number}`
        : `${number}${bAddSpacing ? ' ' : ''}${ECurrencies[currency]}`,

    /**
     * Converts the number into an integer - removes any decimals
     */
    toInt: () => Math.trunc(number),

    toNumber: () => number,

    toString: () => String(number),

    /**
     * Converts the number into a percentage value
     * @param bRound whether to round the value
     * @param bAddSpacing whether to add single empty space between the number and the percentage mark
     */
    toPercent: (bRound?: boolean, bAddSpacing?: boolean): string =>
      `${bRound ? parseNumber(number).round() : number}${bAddSpacing ? ' ' : ''}%`,
  }
}

export const parseObject = <T extends IObject>(object: T) => {
  return {
    clone: () => new Object(object),

    get: (propPath: string, fallbackValue: any) => get(object, propPath, fallbackValue),

    isEmpty: (propertiesToCheck?: (keyof T)[]): boolean | boolean[] => {
      if (propertiesToCheck) {
        return propertiesToCheck.map((property) => !!get(object, property))
      }

      return Object.keys(object).length === 0
    },

    isEqual: (compareObject: IObject): boolean => isEqual(object, compareObject),

    /**
     * Maps through the source object and removes provided values/props
     * @param deleteIterator list of props to remove from the source object
     * @param functionIterator iteration method that gets executed within `_.omitBy`
     */
    purge: (propPaths: (keyof T)[] = [], omitByIterator?: (i: T) => boolean): T[] | IObject =>
      omitByIterator ? omitBy<T>(object as any, omitByIterator) : omit<T>(object, propPaths),
  }
}

export const parseString = (string: string) => {
  return {
    findIndexOf: (substring: string, searchStartIndex: number = 0): number | undefined => {
      const substringIndex: number = string.indexOf(substring, searchStartIndex)
      return parseNumber(substringIndex).isPositive(true) ? substringIndex : undefined
    },

    /**
     * Converts provided `string` into a floating number, if possible
     *
     * If number converstion isn't possible, reverts to `fallbackValue`
     *
     * @why If there was no validation, this: `parseString('word').toFloat()` would return `NaN`, this way it returns `0` or custom fallback value
     */
    toFloat: (fallbackValue: number = 0) =>
      Number.parseFloat(parseNumber(string, fallbackValue).toString()),

    /**
     * Converts provided `string` into a integer number, if possible
     *
     * If number converstion isn't possible, reverts to `fallbackValue`
     *
     * @why If there was no validation, this: `parseString('word').toInt()` would return `NaN`, this way it returns `0` or custom fallback value
     */
    toInt: (fallbackValue: number = 0) =>
      Number.parseInt(parseNumber(string, fallbackValue).toString()),

    toLowerCase: () => string.toLowerCase(),

    toUpperCase: () => string.toUpperCase(),

    trim: () => string.trim(),

    truncate: (length: number, addElipsis?: boolean) =>
      string.length > length ? (string.substring(0, length) + addElipsis ? '...' : '') : string,
  }
}

//
// ======================================== HTML events-related ========================================
//

/**
 * Stops the `propagation` and prevents `default` behaviour of a provided event
 * Executes a callback method afterwards, if provided
 * @param e any type of HTML event
 * @param callback an optional method called after the event has stopped
 * @example
 *  <div onClick={stopEvent(this.updateEntry)} />
 */
export const stopEvent = (e: React.MouseEvent | MouseEvent) => {
  e.preventDefault()
  e.stopPropagation()
}

//
// ======================================== Color conversion ========================================
//

export const RGBToHex = (red: any, green: any, blue: any) => {
  let r = red.toString(16)
  let g = green.toString(16)
  let b = blue.toString(16)

  if (r.length === 1) r = '0' + r
  if (g.length === 1) g = '0' + g
  if (b.length === 1) b = '0' + b

  return '#' + r + g + b
}

export const hexToRGB = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : { red: 0, blue: 0, green: 0 }
}

/**
 * Creates a contrast version of provided HEX color be reversing it
 * @param hex HEX value of a color
 * @returns the contrast (opossite) version of the provided color
 */
export function reverseColor(hex: string): string {
  if (!Regexes.hex.test(hex)) {
    console.error('reverseColor \n\t provided color was not HEX !')
    return '#fff'
  }

  return (Number(`0x1${hex.slice(1)}`) ^ 0xffffff).toString(16).substr(1).toUpperCase()
}

//
// ======================================== Others ========================================
//

/**
 * Check if proprety is function
 * @param func Property to check
 */
export const isFunction = (func: any) => {
  return typeof func === 'function'
}

/**
 * Whether the current enviroment is 'developement'
 * @returns {boolean}
 */
export const isOnDevEnv = (): boolean => {
  return process.env.NODE_ENV === 'development'
}

/**
 * Run callback if it is callable
 * @param  callback Function to run if it is a function
 * @param  args Argument which we pass to callback function
 */
export const runCallback = (callback: any, ...args: any[]) => {
  return isFunction(callback) ? callback.apply(this, args) : undefined
}

/**
 * Extracts initials from passed name
 * @param str Text to parse
 */
export const getInitials = (str: string) =>
  str
    .split('')
    .filter((a) => a.match(/[A-Z]/))
    .join('')
    .toUpperCase()

export const scrollToRef = (ref: any, offset: number = 0) =>
  window.scrollTo(0, ref.current.offsetTop + offset)

export const scrollToTop = () => window.scrollTo(0, 0)

/**
 * Convert first letter to uppercase
 */
export const toUppercaseFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Convert text to camel case
 */
export const toCamelCase = (str: string) => {
  return str
    .split(/\s+/g)
    .map((word) => toUppercaseFirstLetter(word))
    .join('')
}

export const formatPrice = (price: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  return formatter.format(price)
}
