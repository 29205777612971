// core
import React from 'react'
// components
import { ButtonDefault, IButtonDefaultProps } from './ButtonDefault'
// libraries
import cx from 'classnames'
import { overrideTailwindClasses as two } from 'tailwind-override'

interface IButtonPrimaryProps extends IButtonDefaultProps {}

export const ButtonPrimary = ({
  className,
  color = 'primary',
  ...otherProps
}: IButtonPrimaryProps) => (
  <ButtonDefault
    className={two(
      cx('justify-center font-bold text-base tracking-widest rounded-full px-8 py-2', className)
    )}
    color={color}
    {...otherProps}
  />
)
