// libraries
import sha3 from 'crypto-js/sha256'
import { ERoutes } from 'modules/navigation'
import ReactPixel from 'react-facebook-pixel'
import Cookies from 'universal-cookie'

interface IFBQLead {
  email: string
  phone?: string
}

export const FBQ = {
  /** Retrieves the API key from the .env file */
  getKey: (): string | undefined | null => process.env.REACT_APP_FB_PIXEL_ID,

  /** Initializes a new instance of the Google Tag Manager object */
  init: () => {
    const FB_API_KEY = FBQ.getKey()
    if (FB_API_KEY) {
      ReactPixel.init('1256706227835831')
    }
  },
  /**
   * Tracks a specific event with given category, action and props
   *
   * @param args Event config
   */
  event: (title: string, args?: any) => {
    ReactPixel.track(title, args)
  },
  trackLead: async (args: IFBQLead) => {
    const eventId = String(sha3(args.email))
    const cookies = new Cookies()

    FBQ.event('Lead', { eventId: eventId })
    await fetch(ERoutes.FB_CAPI_LEAD, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: args.email,
        phone: args.phone,
        fbp: cookies.get('_fbp'),
        fbc: cookies.get('_fbc'),
        event_id: eventId,
      }),
    }).catch((err) => {
      console.error(err)
    })
  },
}
