// core
import React from 'react'
// components
import { Icon, IDefaultProps, TIconName } from 'components'
// libraries
import cx from 'classnames'

export interface IAvatarDefaultProps extends IDefaultProps {
  /**
   * Img alt prop
   *
   * @default 'Avatar'
   */
  alt?: string
  /**
   * Use icon as avatar
   *
   * @default 'user'
   */
  icon?: TIconName | null
  /**
   * Avatar size - another sizes will be added when needed
   *
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Avatar image url
   */
  url?: string | null
  onClick?: () => void
}

export const AvatarDefault = ({
  alt = 'Avatar',
  icon = 'user',
  size = 'medium',
  url,
  onClick,
  className,
}: IAvatarDefaultProps) => {
  const content = url ? (
    <img alt={alt} src={url} />
  ) : icon ? (
    <Icon className="text-gray-400" name={icon} size="lg" />
  ) : null

  const twCSS = (): string => {
    const defaultClasses = 'flex items-center justify-center rounded-full overflow-hidden'
    const sizeLarge = size === 'large' && 'w-14 h-14'
    const sizeMedium = size === 'medium' && 'w-10 h-10'
    const sizeSmall = size === 'small' && 'w-6 h-6'

    return cx(
      defaultClasses,
      sizeLarge,
      sizeMedium,
      sizeSmall,
      !url && 'border',
      onClick && 'cursor-pointer',
      className
    )
  }

  return (
    <div className={twCSS()} onClick={onClick}>
      {content}
    </div>
  )
}
