export const reCAPTCHA_key = '6Le2q8ceAAAAAPrgIkZVlxbw-Eq82VNkT4kpTnNY'
export const reCAPTCHA_secret = '6Le2q8ceAAAAALvRxiHc2agvYyHQdMRcOIgi9VSB'
export const AvochadoAuthId = '5lAooDzAQa'
export const AvochadoAuthSecret = '39c9d25c7086469b'
export const ActiveCampaignAPIToken =
  '08bce57d826bd260b9b137d20243850e66cf8e09f62a781e8a2e83ae608db5a33e1cc581'

interface IContent {
  title: string
  description?: string
  content?: string
  content2?: string
  assetUrl?: string
}

interface IResult {
  description: IContent
  strengths: string[]
  challanges: string[]
  howWasItFormed: IContent
  whatDoesItMean: IContent
  wordsOfEcouragement: IContent
  //   graph: IContent
  secondaryStyle: IContent
  //   recommendedCourses: string[]
  nextSteps: IContent
  otherQuizzes: IContent
}

export interface IResults {
  AP: IResult
  DA: IResult
  FA: IResult
  SA: IResult
}

export enum EStraightToOfferTestKey {
  'ap' = 'Straight to Offer AP',
  'da' = 'Straight to Offer DA',
  'fa' = 'Straight to Offer FA',
  'sa' = 'Straight to Offer SA',
}

type TStyleAssociation = 'ap' | 'fa' | 'da' | 'sa'

interface IQuestion {
  question: string
  styleAssociation: TStyleAssociation
  styleAssociationValue: number // 1
}

export interface IQuiz {
  id: string
  type: 'romantic' | 'family' | 'friends'
  title: string
  description: string
  color: 'teal' | 'orange' | 'pink'
  colorSecondary: 'bg-teal-secondary' | 'bg-orange-secondary' | 'bg-pink-secondary'
  colorLight: 'bg-teal-light' | 'bg-orange-light' | 'bg-pink-light'
  colorDark: 'bg-teal-dark' | 'bg-orange-dark' | 'bg-pink-dark'
  image: string
  isNew: boolean

  introBgDesktop: string
  introBgMobile: string

  questions: IQuestion[]

  results: IResults
}

export enum EMixpanelKey {
  'BUTTON_CLICKED' = 'Button Clicked',
  'EXPERIMENT_STARTED' = '$experiment_started',
  'PAGE_VIEWED' = 'Page Viewed',
  'QUIZ_STARTED' = 'Quiz Started',
  'QUIZ_FINISHED' = 'Quiz Finished',
  'QUIZ_PROGRESS' = 'Quiz Progress',
  'SIGNUP' = '$signup',
}

export const CONFIG: IQuiz[] = [
  // Romantic
  {
    id: '1',
    type: 'romantic',
    title: 'Romantic Quiz',
    description:
      'Do you struggle to build healthy and happy relationships that last? Take the quiz to discover your unique challenges and patterns in romantic relationships.',
    image: 'svg/quiz-romantic.svg',
    isNew: false,
    color: 'pink',
    colorSecondary: 'bg-pink-secondary',
    colorLight: 'bg-pink-light',
    colorDark: 'bg-pink-dark',

    introBgDesktop: 'svg/quiz-results-romantic-intro-bg.svg',
    introBgMobile: 'svg/quiz-results-romantic-intro-bg-mobile.svg',

    questions: [
      // 1
      {
        question:
          'I can be very emotionally present when I am starting to date, but it takes me a while to share vulnerable things about myself.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      // 2
      {
        question: ' I practice excellent self-care tendencies for both myself and my partner. ',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      // 3
      {
        question: ' I often put my partner on a big pedestal and feel inferior to them.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      //   4
      {
        question: ' I feel irritated or impatient easily around my partner.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      //   5
      {
        question: 'I feel comfortable around my partner and enjoy their company.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      // 6
      {
        question: ' I often struggle to trust a partner in my romantic relationships.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      // 7
      {
        question:
          ' I need a lot more space from my romantic partners than they usually need from me.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      // 8
      {
        question:
          ' I will sometimes call or text repetitively when I’m not getting a response from my loved one.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      // 9
      {
        question:
          'I consistently communicate my feelings and needs in romantic relationships and actively listen  to others when they communicate theirs. I easily work to make effective compromises.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      // 10
      {
        question:
          'I tend to be out of touch with my emotions quite frequently and it can take me a long time to  develop romantic feelings for someone.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      // 11
      {
        question:
          'I am very attuned to my partner’s needs and notice when there is any change in behavior. This  sometimes can create stress or a feeling of distrust when a pattern changes in someone else’s  behavior.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      // 12
      {
        question: 'I do not like spending time alone and would prefer to be with my partner. ',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      // 13
      {
        question: 'I constantly want to be emotionally closer to my partner.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      // 14
      {
        question: 'I know how to process my emotions effectively when I feel upset. ',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      // 15
      {
        question:
          'I do not like when people ask me personal questions when we are getting to know each other.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      // 16
      {
        question:
          'I worry that my partner will fall out of love with me or eventually get bored of me.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      // 17
      {
        question:
          'I feel a lot of shame when others give me feedback about my behavior in our relationship.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      // 18
      {
        question:
          'I have very strong emotions in relationships and can easily go from hot to cold.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      // 19
      {
        question: 'I strongly dislike feeling vulnerable to others.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      // 20
      {
        question:
          'If I notice my partner showing any signs of coldness, I panic and want to get closer as quickly as possible.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      // 21
      {
        question:
          'I do not hold grudges easily or keep resentments long. I do, however, know how to set a healthy  boundary with an unhealthy person if necessary.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      // 22
      {
        question: 'I often express anger very strongly when I feel hurt, powerless or betrayed.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      // 23
      {
        question:
          'I don’t feel as if any boundaries are necessary between myself and a romantic partner.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      // 24
      {
        question:
          'I feel as though conflict is resolvable and feel equipped to work through problems effectively.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      // 25
      {
        question:
          'It is not uncommon for me to experience inward emotional turbulence throughout the duration  of my romantic relationship.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      // 26
      {
        question: 'I can be cold and stand-offish at times when I don’t know others very well.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      // 27
      {
        question: 'I often feel very hot or very cold towards my partner or loved one.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      // 28
      {
        question: 'I know that I am worthy of a healthy, happy relationship.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
    ],

    results: {
      FA: {
        description: {
          title: 'Fearful Avoidant',
          content: `As a fearful avoidant, romantic relationships can be very confusing, overwhelming and cause a lot of inward emotional turbulence for you. 
                      
          This is because you experience both anxious and avoidant tendencies, which can cause you to swing between being hot and cold with your romantic partners. 

          On one hand, you deeply crave love, connection and closeness, but on the other – you deeply fear it. This constant straddling between two opposites is what causes you to “flip flop” so much. 
          
          Some common characteristics of a fearful avoidant attachment style include:`,
        },

        strengths: [
          'Highly empathetic',
          'Attuned to the emotions and needs of others',
          'Can connect deeply with others',
        ],

        challanges: [
          '“Hot and cold” in relationships due to confusion and fears',
          'Experiences distrust due to a fear of betrayal',
          'Prone to anger and resentment',
        ],

        howWasItFormed: {
          title: 'How Was Your Attachment Style Formed?',
          content: `As you may already know, attachment styles are formed during childhood (although they may shift based on experiences you have later in life). 

            In childhood, it is likely that you experienced some form of emotional volatility in your household which caused you to subconsciously associate love with betrayal. 

            As a result, you likely struggle with feeling comfortable with vulnerability in your adulthood romantic relationships, and oscillate between craving connection and fearing it.`,

          assetUrl: 'svg/quiz-results-fa.svg',
        },

        whatDoesItMean: {
          title: 'How Do These Characteristics Play Out In Romantic Relationships?',
          content: `When you first start dating someone, you’re often very emotionally present, which makes your love interests feel very seen and connected. 

          But over time, you may start to pull away when:
          
          • the stakes feel higher and more vulnerability is involved
          • when emotions get too intense (yours or the other person’s)
          • old wounds are triggered 
          • your needs go unmet
          
          This is usually where the dynamic becomes more challenging, particularly if you’re dating someone who is more anxiously attached, because as you pull away, they try to regain closeness by leaning in, which only makes you pull further away.`,
          content2: `And as a result, relationships can feel very confusing and painful for both you and your partners. 

            It’s likely that you’re also naturally empathetic and highly attuned to the needs and emotions of others, which is actually a bit of a superpower of yours! But the downside to this is that you notice when there is a small change in behavior – and then may attach negative meaning to it.
          
            This can cause feelings of distrust that your partner will betray you, and as a result, can trigger strong emotional responses, resentment and a lot of stress in your relationships. `,
          assetUrl: 'svg/quiz-results-bg.svg',
        },

        wordsOfEcouragement: {
          title: '',
          content: `The good news is that you do have a chance as an adult to change these automatic, subconscious patterns. And once you do, you can build and maintain a healthy and lasting relationship that makes you feel safe, calm, loved and deeply connected. `,
          assetUrl: '',
        },

        // graph: {
        //   title: 'Your Secondary Attachment Style & Why It Matters',
        //   content: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

        //               This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be subconsciously sabotaging your relationships or causing you inner turmoil.`,
        //   assetUrl: '',
        // },

        secondaryStyle: {
          title: 'Your Secondary Attachment Style & Why It Matters',
          description: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

          This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be subconsciously sabotaging your relationships or causing you inner turmoil.`,
        },

        // recommendedCourses: ['1', '2', '3'],

        nextSteps: {
          title: 'Your Next Steps And Recommended Courses',
          content: `What we recommend is that you first work on your primary attachment style – which is the style you see at the top of your chart with the highest percentage, and then after that, move onto your secondary attachment style, which is your next highest percentage.

          To help you get started with your transformation, below we have recommended some specific courses based on your results that’ll help you become more securely attached and build lasting relationships. 
          
          Many of our students who have taken these courses have reported being more securely attached within months of doing the course work. So we’re sure if you put in the effort, the same is possible for you too. `,
        },

        otherQuizzes: {
          title: 'What’s Your Attachment Style With Friends, Family & Romantic Partners?',
          content: `Did you know that your attachment style is the single largest predictor of whether your relationships thrive… or whether they hardly ever survive?

          And not just your family romantic relationships – but your relationships with your friends and family romantic relationships too
          
          To discover your attachment style in each of these areas - take another quiz!`,
        },
      },
      DA: {
        description: {
          title: 'Dismissive Avoidant',
          content: `As someone with a dismissive avoidant attachment style, it’s likely that you feel uncomfortable with vulnerability and intense emotions.

          And as a result, you may withdraw in emotionally triggering situations and highly value your individuality. 
          
          Common characteristics of a dismissive avoidant attachment style include:`,
        },

        strengths: [
          'Analytical, logical and practical when making decisions',
          'Can be deeply empathetic and attuned to people’s feelings',
          'Intelligent, good imagination',
        ],

        challanges: [
          'Fears commitment and giving up sense of self',
          'Experiences inner turmoil due to conflicting needs – fears vulnerability and connection, but deeply craves it',
          'Can be very sensitive to criticism',
        ],

        howWasItFormed: {
          title: 'How Was Your Attachment Style Formed?',
          content: `As you may already know, attachment styles are formed during childhood (although they may shift based on experiences you have later in life). 

          In childhood, it is likely that you experienced some form of emotional neglect – meaning your needs around connection were largely unmet. You may have also been shamed for having certain emotions.
          
          So as an adult, you find it difficult, and even overwhelming, to connect with others.`,
          assetUrl: 'svg/quiz-results-da.svg',
        },

        whatDoesItMean: {
          title: 'How Do These Characteristics Play Out In Romantic Relationships?',
          content: `It’s likely that you need a lot more space from romantic partners than what they need from you. 

          When you first start dating someone, you may find it quite challenging to open up as you struggle with vulnerability and don’t like to be asked personal questions. 
          
          In fact, intense emotions can feel overwhelming because you might have been shamed for them as a child. This is why as an adult, you might prefer to get your needs for connection met through things rather than people. 
          
          This can cause your romantic partners to think that you don’t need them. When in fact, you really do what to connect, it’s just that vulnerability and connection can feel scary. As a result of this conflict in needs, you experience a lot of inner turmoil.`,
          content2: ``,
          assetUrl: 'svg/quiz-results-bg.svg',
        },

        wordsOfEcouragement: {
          title: '',
          content: `The good news is that you do have a chance as an adult to change these automatic, subconscious patterns. And once you do, you can build and maintain a healthy and lasting relationship that makes you feel safe, calm, loved and connected.`,
        },

        secondaryStyle: {
          title: 'Your Secondary Attachment Style & Why It Matters',
          description: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

          This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be subconsciously sabotaging your relationships or causing you inner turmoil.`,
        },

        nextSteps: {
          title: 'Your Next Steps And Recommended Courses',
          content: `What we recommend is that you first work on your primary attachment style – which is the style you see at the top of your chart with the highest percentage, and then after that, move onto your secondary attachment style, which is your next highest percentage.

          To help you get started with your transformation, below we have recommended some specific courses based on your results that’ll help you become more securely attached and build lasting relationships. 
          
          Many of our students who have taken these courses have reported being more securely attached within months of doing the course work. So we’re sure if you put in the effort, the same is possible for you too. `,
        },

        otherQuizzes: {
          title: 'What’s Your Attachment Style With Friends, Family & Romantic Partners?',
          content: `Did you know that your attachment style is the single largest predictor of whether your relationships thrive… or whether they hardly ever survive?

          And not just your family romantic relationships – but your relationships with your friends and family romantic relationships too
          
          To discover your attachment style in each of these areas - take another quiz!`,
        },
      },
      AP: {
        description: {
          title: 'Anxious Preoccupied',
          content: `As someone who has an anxious preoccupied attachment style, relationships can make you feel unsafe, and on edge because you have a subconscious fear that you’ll be abandoned. 

          This can lead to people pleasing in your relationships as you may put other people on pedestals and abandon your own wants, needs and boundaries to keep others close.
          
          This imbalance can then lead to resentment and may inadvertently result in the very thing you fear the most: your love interests pulling away.
          
          Common characteristics of a anxious preoccupied attachment style include:`,
        },

        strengths: [
          'Highly empathetic and giving with time, energy and emotions',
          'Attuned to the emotions, needs and wants of others',
          'A natural at connecting deeply with others',
        ],

        challanges: [
          'May abandon yourself through excessive people pleasing to avoid being left',
          'Can feel quite insecure in relationships, which can lead to “needy” behaviors',
          'Struggles to identify and communicate needs and boundaries, which leads to resentment',
        ],

        howWasItFormed: {
          title: 'How Was Your Attachment Style Formed?',
          content: `As you may already know, attachment styles are formed during childhood (although they may shift based on experiences you have later in life). 

          In childhood, it is likely that you experienced inconsistent emotional support – meaning your needs around connection were sometimes unmet.
          
          As a result, you often felt abandoned as a child, so as an adult, you may try to make up for this by people pleasing in your romantic relationships.`,
          assetUrl: 'svg/quiz-results-ap.svg',
        },

        whatDoesItMean: {
          title: 'How Do These Characteristics Play Out In Romantic Relationships?',
          content: `Dating can make you feel unsettled, and even sometimes insecure, especially if the person you’re dating has more avoidant tendencies.

          It’s likely that you’re an expert at reading other people, and may instinctively people-please in an attempt to keep others close to you. 
          
          This is a subconscious strategy to stop them from leaving you – it is not intentional. But what this means is that you often find yourself doing things for other people so that you feel loved, even if it goes against what you really want or need.`,
          content2: `This can secretly make you feel resentful and angry, and a bit conducive to a long-term relationship. 

          Anxious attachments also tend to be very empathetic and highly attuned to the needs and emotions of others, which means you notice subtle changes in behavior. 
          
          The downside of this is that if you notice any subtle changes in behavior, you may panic and try to get closer to them again as quickly as possible. This fear can be so strong that you end up doing things such as repeatedly texting or calling, even if they don’t respond.`,
          assetUrl: 'svg/quiz-results-bg.svg',
        },

        wordsOfEcouragement: {
          title: '',
          content: `The good news is that you do have a chance as an adult to change these automatic, subconscious patterns. And once you do, you can build and maintain a healthy and lasting relationship that makes you feel safe, calm, loved and deeply connected.`,
        },

        secondaryStyle: {
          title: 'Your Secondary Attachment Style & Why It Matters',
          description: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

          This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be subconsciously sabotaging your relationships or causing you inner turmoil.`,
        },

        nextSteps: {
          title: 'Your Next Steps And Recommended Courses',
          content: `What we recommend is that you first work on your primary attachment style – which is the style you see at the top of your chart with the highest percentage, and then after that, move onto your secondary attachment style, which is your next highest percentage.

          To help you get started with your transformation, below we have recommended some specific courses based on your results that’ll help you become more securely attached and build lasting relationships. 
          
          Many of our students who have taken these courses have reported being more securely attached within months of doing the course work. So we’re sure if you put in the effort, the same is possible for you too. `,
        },

        otherQuizzes: {
          title: 'What’s Your Attachment Style With Friends, Family & Romantic Partners?',
          content: `Did you know that your attachment style is the single largest predictor of whether your relationships thrive… or whether they hardly ever survive?

          And not just your family romantic relationships – but your relationships with your friends and family romantic relationships too
          
          To discover your attachment style in each of these areas - take another quiz!`,
        },
      },
      SA: {
        description: {
          title: 'Secure Attachment',
          content: `As a securely-attached individual, it’s likely that you feel at ease in relationships because you’re comfortable with communicating your needs, expressing your emotions and setting boundaries. 

          You’re also comfortable with letting people into your intimate space and being vulnerable because you’re confident in who you are as a person.
          
          Common characteristics of a secure attachment style include:`,
        },

        strengths: [
          'At ease in relationships',
          'Natural ability to express needs, boundaries and emotions',
          'Comfortable with vulnerability and intimacy',
        ],

        challanges: [
          'Can find it difficult to relate to those with more insecure attachment styles',
          'May feel stuck or frustrated in relationships that make you unhappy',
          'Can feel confused when challenges arise',
        ],

        howWasItFormed: {
          title: 'How Was Your Attachment Style Formed?',
          content: `As you may already know, attachment styles are formed during childhood (although they may shift based on experiences you have later in life). 

          It’s likely that you had a healthy relationship with both of your caregivers during childhood. 
          
          You were probably encouraged to express yourself, and your needs for emotional and physical support were consistently met. 
          
          That’s why as an adult, it’s easier for you to create supportive, communicative relationships with others.`,
          assetUrl: 'svg/quiz-results-sa.svg',
        },

        whatDoesItMean: {
          title: 'How Do These Characteristics Play Out In Romantic Relationships?',
          content: `As someone who is securely attached, it’s likely that you’re able to consistently communicate your feelings and needs in romantic relationships, and actively listen when others communicate theirs. 

          Because you feel confident in who you are, and know that you’re deserving of a healthy, happy relationship, you don’t enter into dating or relationships with many fears. 
          
          As a result, you’re able to express yourself authentically and vet potential partners from a secure place. 
          
          When in a relationship, you tend to really enjoy your partner’s company and put care and effort into nurturing them and the relationship.
          
          You’re also able to process your emotions when you get upset and you don’t hold onto grudges for long periods. `,
          content2: `However, you might experience problems if you struggle to relate to your partner when they’re not as secure in relationships as you are. 

          If you’re with someone who has a lot of fears, insecurities or who can’t communicate needs, boundaries and emotions as easily as you can, it can be difficult for you to understand their behavior. 
          
          This can lead to conflict and both parties feeling misunderstood and unseen.
          
          What’s more, when you’re feeling this way, it can trigger your secondary attachment style.`,
          assetUrl: 'svg/quiz-results-bg.svg',
        },

        wordsOfEcouragement: {
          title: '',
          content: `The good news is, with the right tools, your relationships can feel deeply connected, harmonious and safe.`,
        },

        secondaryStyle: {
          title: 'Your Secondary Attachment Style & Why It Matters',
          description: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

          This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be subconsciously sabotaging your relationships or causing you inner turmoil.`,
        },

        nextSteps: {
          title: 'Your Next Steps And Recommended Courses',
          content: `What we recommend is that you first work on your primary attachment style – which is the style you see at the top of your chart with the highest percentage, and then after that, move onto your secondary attachment style, which is your next highest percentage.

          To help you get started with your transformation, below we have recommended some specific courses based on your results that’ll help you become more securely attached and build lasting relationships. 
          
          Many of our students who have taken these courses have reported being more securely attached within months of doing the course work. So we’re sure if you put in the effort, the same is possible for you too. `,
        },

        otherQuizzes: {
          title: 'What’s Your Attachment Style With Friends, Family & Romantic Partners?',
          content: `Did you know that your attachment style is the single largest predictor of whether your relationships thrive… or whether they hardly ever survive?

          And not just your family romantic relationships – but your relationships with your friends and family romantic relationships too
          
          To discover your attachment style in each of these areas - take another quiz!`,
        },
      },
    },
  },

  // Family
  {
    id: '2',
    type: 'family',
    title: 'Family Quiz',
    description:
      'Do you struggle to break free from certain patterns and behaviors within your family? Take this quiz to understand why.',
    image: 'svg/quiz-family.svg',
    isNew: true,
    color: 'teal',
    colorSecondary: 'bg-teal-secondary',
    colorLight: 'bg-teal-light',
    colorDark: 'bg-teal-dark',

    introBgDesktop: 'svg/quiz-results-family-intro-bg.svg',
    introBgMobile: 'svg/quiz-results-family-intro-bg-mobile.svg',

    questions: [
      {
        question:
          'When I feel hurt, I want to push my family members away and sometimes will say mean things that I regret later.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I try to rely as little as possible on my family for emotional support. We do not usually discuss emotions with each other.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      {
        question:
          'I am good at listening to other people’s needs in my family as well as at expressing my own.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I have a habit of chronically people-pleasing family members in hopes that they will value me  more.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question: 'I avoid being alone or excluded by family members at all costs.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question:
          'If I have a conflict with a member of my family, I will express my feelings and try to understand  what caused them to act that way.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question: 'There is a history of chronic conflict and chaos in my family dynamic.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I avoid too much emotional closeness with my family members and prefer to keep things light, easy and distant.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      {
        question:
          'I sometimes feel as though I am constantly chasing after love and approval from members in my family.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question:
          'I find it difficult to trust others in my family dynamic and am often suspicious.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I find that setting boundaries comes naturally to me and I am comfortable communicating about them.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I often threaten to stop talking to members of my family but usually change my mind later.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I often avoid conflict and feel very hurt by criticism from anyone in my family.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      {
        question:
          'I talk about any resentments I have with members of my family and try to actively work through them in healthy ways.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I become afraid and anxious if I feel a family member being disconnected or distant.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question:
          'I spend much more time wondering about what others think of me than I spend reflecting about  what I personally want out of life/what makes me happy.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question:
          'I am often either very present with family members or quite avoidant with family members –  there is usually not a middle ground.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question:
          'If I don’t feel included in family events or conversations, I can become sad and anxious.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question: 'I often feel protective over my space, privacy and belongings.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      {
        question: 'I am emotionally stable in my family relationships.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I tend to operate in extremes between being very emotionally available and then requiring time  and space to myself.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question: 'I find it too easy to open up to people and sometimes “over-share” with others.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question:
          'I do not express my needs to others easily because I prefer to meet my needs on my own.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      {
        question: 'I prefer to avoid any physical affection with family members (ex. Hugs).',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      {
        question: 'I hunger for closeness, but often fear being truly vulnerable to my family.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question: 'I feel that it is easy to express my needs to members of my family.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question: 'I trust my family members and know that we can rely on one another if needed.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question:
          'My family members know that I am independent and prefer to handle life situations on my own.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
    ],

    results: {
      FA: {
        description: {
          title: 'Fearful Avoidant',
          content: `As a fearful avoidant, it’s likely that there is a history of chronic conflict in your family dynamics.

          As a result, you experience both anxious and avoidant tendencies, which cause you to swing between craving connection and being emotionally present, to fearing vulnerability and withdrawing from your family.
          
          Some common characteristics of a fearful avoidant attachment style include:`,
        },

        strengths: [
          'Highly empathetic',
          'Attuned to the emotions and needs of others',
          'Can connect deeply with others',
        ],

        challanges: [
          'Oscillate between being emotionally available and very withdrawn',
          'When hurt, you push family away and say things that you later regret',
          'Often suspicious and finds it difficult to trust family members',
        ],

        howWasItFormed: {
          title: 'How Was Your Attachment Style Formed?',
          content: `As you may already know, attachment styles are formed during childhood (although they may shift based on experiences you have later in life). 

          In childhood, it is likely that you experienced some form of emotional volatility in your household which caused you to subconsciously associate closeness with betrayal and conflict. 
          
          As a result, vulnerability may make you feel uncomfortable in your family, even though subconsciously, you desperately crave it.`,
          assetUrl: 'svg/quiz-results-fa.svg',
        },

        whatDoesItMean: {
          title: 'How Do These Characteristics Play Out In Family Relationships?',
          content: `As a fearful avoidant, it’s likely that you experienced a lot of chaos and conflict in your family dynamic growing up. 

          As a result, in adulthood, you may associate vulnerability, closeness and love – with betrayal and fear. 
          
          On one hand, you hunger for closeness and want to be vulnerable with your family, but you also have built-in negative associations to this kind of intimacy, which puts you on high alert. 
          
          It’s hard for you not to be suspicious of their intentions, so you find it difficult to trust as you have a deep fear of betrayal.
          
          This conflict between wanting connection and also fearing it causes you to oscillate between being very emotionally present, and being very withdrawn and needing space.
          
          Often there is no middle ground, which can cause a lot of inner unrest, and conflict with family members. `,
          content2: `It’s likely that you also struggle to communicate your needs and boundaries – and that other people in your family do, too. 

          Which means that resentments can brew and strong emotional responses are not uncommon, which often later leads to regret or family members temporarily cutting each other off.
          
          However, you still crave love, connection and closeness. And so does your family. It’s just that you have negative associations to vulnerability, making it harder for you to let down your guard, communicate your needs and boundaries, and to trust that just maybe, behind their own pain, they want what’s best for you. `,
          assetUrl: 'svg/quiz-results-bg.svg',
        },

        wordsOfEcouragement: {
          title: '',
          content: `The good news is that you do have a chance as an adult to change these automatic, subconscious patterns, and to learn how to relate with your family in healthy ways so you can feel safe, calm and connected.`,
        },

        // graph: {
        //   title: 'Your Secondary Attachment Style & Why It Matters',
        //   content: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

        //               This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be subconsciously sabotaging your relationships or causing you inner turmoil.`,
        //   assetUrl: '',
        // },

        secondaryStyle: {
          title: 'Your Secondary Attachment Style & Why It Matters',
          description: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

          This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be subconsciously sabotaging your relationships with your family members, and causing you inner turmoil. `,
        },

        // recommendedCourses: ['1', '2', '3'],

        nextSteps: {
          title: 'Your Next Steps And Recommended Courses',
          content: `What we recommend is that you first work on your primary attachment style – which is the style you see at the top of your chart with the highest percentage, and then after that, move onto your secondary attachment style, which is your next highest percentage.

          To help you get started with your transformation, below we have recommended some specific courses based on your results that’ll help you become more securely attached and build healthy relationships with your family. 
          
          Many of our students who have taken these courses have reported being more securely attached within months of doing the course work. So we’re sure if you put in the effort, the same is possible for you too.`,
        },

        otherQuizzes: {
          title: 'What’s Your Attachment Style With Friends, Family & Romantic Partners?',
          content: `Did you know that your attachment style is the single largest predictor of whether your relationships thrive… or whether they hardly ever survive?

          This is not just your family relationships – but your relationships with your friends and romantic relationships too.
          
          To discover your attachment style in each of these areas - take another quiz!`,
        },
      },
      DA: {
        description: {
          title: 'Dismissive Avoidant',
          content: `As someone with a dismissive avoidant attachment style, it’s likely that you rely on your family as little as possible for emotional support, and that you rarely discuss emotions with each other. 

          To avoid conflict and criticism, you prefer to keep things light and easy, and to manage your life with as little involvement from others as possible.
          
          Common characteristics of a dismissive avoidant attachment style include:`,
        },

        strengths: [
          'Analytical, logical and practical when making decisions',
          'Can be deeply empathetic and attuned to people’s feelings',
          'Can be very supportive with more practical matters',
        ],

        challanges: [
          'Feels very uncomfortable being vulnerable or too emotional with family',
          'Very private and protective over personal space, belongings and life',
          'Avoids conflict and feels hurt easily by criticism',
        ],

        howWasItFormed: {
          title: 'How Was Your Attachment Style Formed?',
          content: `As you may already know, attachment styles are formed during childhood (although they may shift based on experiences you have later in life). 

          In childhood, it is likely that you experienced some form of emotional neglect – meaning your needs around connection were largely unmet. You may have also been shamed for having certain emotions.
          
          So as an adult, you find it difficult, and even overwhelming, to connect emotionally with others.`,
          assetUrl: 'svg/quiz-results-da.svg',
        },

        whatDoesItMean: {
          title: 'How Do These Characteristics Play Out In Family Relationships?',
          content: `It’s likely that you rely on your family as little as possible for emotional support, or any support for that matter, as you’re used to meeting your own needs. 

          In fact, emotions are rarely discussed within the family, and to keep this “safe” distance between others and your emotions, you prefer to keep things light and easy, and to avoid conflict at all costs. 
          
          And if conflict does arise, you often feel very hurt if criticized because it reminds you of when you were shamed or criticized as a child for expressing certain emotions, or for getting something wrong.
          
          You also may become easily annoyed when others infringe on your personal space as you’re very protective of your time and belongings.`,
          content2: `Although it might seem like you’re fiercely independent and perfectly equipped to meet all of your needs, this separation from those you love causes you a lot of inner turmoil.

          The thing is, you really do want to connect on a deeper level, it’s just that vulnerability and intense emotions can make you feel scared and overwhelmed. 
          
          You got so used to taking care of your emotions and needs as a child, you may not know how to let people in as an adult.`,
          assetUrl: 'svg/quiz-results-bg.svg',
        },

        wordsOfEcouragement: {
          title: '',
          content: `The good news is that you do have a chance as an adult to change these automatic, subconscious patterns so you can build healthy, safe and connected relationships with your family as an adult.`,
        },

        secondaryStyle: {
          title: 'Your Secondary Attachment Style & Why It Matters',
          description: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

          This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be subconsciously sabotaging your relationships with your family members, and causing you inner turmoil. `,
        },

        nextSteps: {
          title: 'Your Next Steps And Recommended Courses',
          content: `What we recommend is that you first work on your primary attachment style – which is the style you see at the top of your chart with the highest percentage, and then after that, move onto your secondary attachment style, which is your next highest percentage.

          To help you get started with your transformation, below we have recommended some specific courses based on your results that’ll help you become more securely attached and build healthy relationships with your family. 
          
          Many of our students who have taken these courses have reported being more securely attached within months of doing the course work. So we’re sure if you put in the effort, the same is possible for you too.`,
        },

        otherQuizzes: {
          title: 'What’s Your Attachment Style With Friends, Family & Romantic Partners?',
          content: `Did you know that your attachment style is the single largest predictor of whether your relationships thrive… or whether they hardly ever survive?

          This is not just your family relationships – but your relationships with your friends and romantic relationships too.
          
          To discover your attachment style in each of these areas - take another quiz!`,
        },
      },
      AP: {
        description: {
          title: 'Anxious Preoccupied',
          content: `As someone who has an anxious preoccupied attachment style, you may have a deep fear that you’ll be excluded or abandoned by your family.

          This fear causes you to subconsciously people please in an attempt to maintain the relationships with your family. However, this behaviour can cause you to neglect your personal needs and create challenges in your family dynamics. 
          
          Some common characteristics of an anxious preoccupied attachment style include:`,
        },

        strengths: [
          'Highly empathetic and giving with time, energy and emotions',
          'Attuned to the emotions, needs and wants of others',
          'A natural at connecting deeply with others',
        ],

        challanges: [
          'Constant worry that your family will exclude or abandon you',
          'Prone to people pleasing and self-abandonment to avoid being left out',
          'Feels like you’re constantly chasing after love and approval by family members',
        ],

        howWasItFormed: {
          title: 'How Was Your Attachment Style Formed?',
          content: `As you may already know, attachment styles are formed during childhood (although they may shift based on experiences you have later in life). 

          In childhood, it’s likely that you experienced inconsistent emotional support – meaning your needs around connection were sometimes unmet.
          
          As a result, you often felt abandoned as a child, so as an adult, you chronically people please and seek approval to avoid being abandoned.`,
          assetUrl: 'svg/quiz-results-ap.svg',
        },

        whatDoesItMean: {
          title: 'How Do These Characteristics Play Out In Family Relationships?',
          content: `It’s likely that you may feel insecure in your family relationships because you have a deep, subconscious fear that they’ll abandon or exclude you.

          In fact, this fear might be so strong that you have a habit of chronically people pleasing in the hope that they’ll love and approve of you more. 
          
          This is a subconscious strategy to keep yourself safe – it’s not intentional. But as a result, it’s likely that you abandon yourself constantly in an attempt to get your family’s validation.
          
          You may even find yourself spending a lot of time wondering what others think of you and how happy they are with your life choices, rather than you focusing on what you want in life.`,
          content2: `That being said, you’re most likely a natural at connecting deeply, and you’re highly attuned to the emotions and needs of others. But the downside to this is if you notice a subtle shift in the family dynamic where you feel less connected, or you discover that you’ve been left out of a family event, you can become quite sad or anxious. 

          To restore connection, you may people please and do anything to regain closeness and get their approval, especially as you don’t like to spend too much time on your own. This keeps you trapped in a cycle of self-abandonment, which can lead to insecurity, loneliness and challenges with self-love. 
          
          It’s likely that you feel very comfortable with sharing personal information about yourself with your family, but as you struggle with boundaries, sometimes you may overshare in an attempt to feel connected. `,
          assetUrl: 'svg/quiz-results-bg.svg',
        },

        wordsOfEcouragement: {
          title: '',
          content: `The good news is that you do have a chance as an adult to change these automatic, subconscious patterns. And once you do, you can build and maintain healthy family relationships that make you feel safe, calm, loved and deeply connected.`,
        },

        secondaryStyle: {
          title: 'Your Secondary Attachment Style & Why It Matters',
          description: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

          This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be subconsciously sabotaging your relationships with your family members, and causing you inner turmoil. `,
        },

        nextSteps: {
          title: 'Your Next Steps And Recommended Courses',
          content: `What we recommend is that you first work on your primary attachment style – which is the style you see at the top of your chart with the highest percentage, and then after that, move onto your secondary attachment style, which is your next highest percentage.

          To help you get started with your transformation, below we have recommended some specific courses based on your results that’ll help you become more securely attached and build healthy relationships with your family. 
          
          Many of our students who have taken these courses have reported being more securely attached within months of doing the course work. So we’re sure if you put in the effort, the same is possible for you too.`,
        },

        otherQuizzes: {
          title: 'What’s Your Attachment Style With Friends, Family & Romantic Partners?',
          content: `Did you know that your attachment style is the single largest predictor of whether your relationships thrive… or whether they hardly ever survive?

          This is not just your family relationships – but your relationships with your friends and romantic relationships too.
          
          To discover your attachment style in each of these areas - take another quiz!`,
        },
      },
      SA: {
        description: {
          title: 'Secure Attachment',
          content: `As a securely-attached individual, it’s likely that you feel at ease with your family because you’re comfortable with communicating your needs, expressing your emotions and setting boundaries. 

          Overall, you feel emotionally stable in your family relationships and like you can trust them to be there for you when needed.
          
          Common characteristics of a secure attachment style include:`,
        },

        strengths: [
          'Feel comfortable with giving and receiving love and support ',
          'Natural ability to express needs, boundaries and emotions ',
          'Feels emotionally stable and able to resolve conflicts when they arise',
        ],

        challanges: [
          'Can struggle to relate with family members who have an insecure attachment style',
          'May feel frustrated with people who often have emotional problems',
          'Can feel confused when challenges arise and other people don’t communicate effectively',
        ],

        howWasItFormed: {
          title: 'How Was Your Attachment Style Formed?',
          content: `As you may already know, attachment styles are formed during childhood (although they may shift based on experiences you have later in life). 

          It’s likely that you had a healthy relationship with your caregivers during childhood. 
          
          You were probably encouraged to express yourself, and your needs for emotional and physical support were consistently met. 
          
          That’s why as an adult, it’s easy for you to maintain and build supportive, communicative relationships with your family.`,
          assetUrl: 'svg/quiz-results-sa.svg',
        },

        whatDoesItMean: {
          title: 'How Do These Characteristics Play Out In Family Relationships?',
          content: `As someone who is securely attached, it’s likely that you’re able to consistently communicate your feelings and needs with your family and actively listen when they express theirs. 

          If conflicts do arise, or someone oversteps a boundary, you know how to effectively resolve the issue and communicate what you need in the situation, while giving the other person a chance to express how they feel.
          
          You’re also able to self-soothe and deal with personal problems on your own when they arise, yet you’re also comfortable with sharing personal information and struggles with your family. 
          
          Overall, you feel emotionally stable in your family dynamic and trust them to offer support when needed.`,
          content2: `However, where you might experience problems is that you can struggle to relate to family members who have a more insecure attachment style.

          It can be hard for you to understand why they don’t just calmly say what they mean and need, or why they regularly have emotional struggles with others, or within themselves.
          
          This can lead to conflict and both parties feeling misunderstood and unseen.
          
          What’s more, when you’re feeling this way, it can trigger your secondary attachment style – which might make you become more avoidant or anxious, depending on the situation.`,
          assetUrl: 'svg/quiz-results-bg.svg',
        },

        wordsOfEcouragement: {
          title: '',
          content: `The good news is, you can learn how to better understand and relate to your loved ones and reprogram your secondary attachment style so that you can improve your relationships and feel more connected, harmonious and calm.`,
        },

        secondaryStyle: {
          title: 'Your Secondary Attachment Style & Why It Matters',
          description: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

          This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be subconsciously sabotaging your relationships with your family members, and causing you inner turmoil. `,
        },

        nextSteps: {
          title: 'Your Next Steps And Recommended Courses',
          content: `What we recommend is that you first work on your primary attachment style – which is the style you see at the top of your chart with the highest percentage, and then after that, move onto your secondary attachment style, which is your next highest percentage.

          To help you get started with your transformation, below we have recommended some specific courses based on your results that’ll help you become more securely attached and build healthy relationships with your family. 
          
          Many of our students who have taken these courses have reported being more securely attached within months of doing the course work. So we’re sure if you put in the effort, the same is possible for you too.`,
        },

        otherQuizzes: {
          title: 'What’s Your Attachment Style With Friends, Family & Romantic Partners?',
          content: `Did you know that your attachment style is the single largest predictor of whether your relationships thrive… or whether they hardly ever survive?

          This is not just your family relationships – but your relationships with your friends and romantic relationships too.
          
          To discover your attachment style in each of these areas - take another quiz!`,
        },
      },
    },
  },

  // Friends
  {
    id: '3',
    type: 'friends',
    title: 'Friends Quiz',
    description:
      'How do you feel and show up in your friendships? Take this quiz to discover how you connect in your friendships.',
    image: 'svg/quiz-friends.svg',
    isNew: true,
    color: 'orange',
    colorSecondary: 'bg-orange-secondary',
    colorLight: 'bg-orange-light',
    colorDark: 'bg-orange-dark',

    introBgDesktop: 'svg/quiz-results-friends-intro-bg.svg',
    introBgMobile: 'svg/quiz-results-friends-intro-bg-mobile.svg',

    questions: [
      {
        question:
          'I can oscillate between being very emotionally available in friendships to becoming very  withdrawn, especially when I feel that my emotional energy has been over-used.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I am able to show up to support close friends regularly and I also feel comfortable receiving  support from my close friends.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question: 'I sometimes become needy and afraid of being abandoned by my friends.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question: 'I prefer to keep my distance from sharing anything emotional with my friends.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      {
        question:
          'I feel completely comfortable around my friends and consistently enjoy their company.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I can easily feel taken advantage of by friendships, leaving me to feel angry or resentful.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I feel very irritable and annoyed when others infringe on my need for space or time alone.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      {
        question: 'I prefer not to spend as little time alone as possible.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question:
          'I make clear and conscious choices about what type of friends I want to surround myself with.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question: 'I tend to be out of touch with my emotions quite frequently.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      {
        question: 'I hold onto grudges in friendships.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I would rather go to my friends with any problems I’m facing at work because I have difficulty  working things through on my own.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question: 'I actively fear being excluded by my friends.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question: 'I know how to process my emotions and meet my own needs when I get upset.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I am slow to make friends, but will often keep the same friends for very long periods of time.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      {
        question: 'I worry that my friends will lose interest in me or eventually get bored of me.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question: 'I am effective at compromising and communicating with friends.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I have a tendency to hold things in when I’m upset with friends and sometimes I eventually cut  them off instead of discussing these things.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question: 'I strongly dislike feeling vulnerable in front of my friends.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      {
        question:
          'If I notice my friends showing any signs of coldness, I panic and want to get closer as quickly as  possible.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question:
          'I am good at forgiving if a friend hurts me, but I will also set healthy boundaries if I feel I need  to.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question: 'I often express anger very strongly when I feel hurt, powerless or betrayed.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question:
          'I don’t feel as if I boundaries are ever necessary between myself and a close friend.',
        styleAssociation: 'ap',
        styleAssociationValue: 1,
      },
      {
        question:
          'I feel as though conflict is resolvable and feel equipped to work through problems effectively.',
        styleAssociation: 'sa',
        styleAssociationValue: 1,
      },
      {
        question:
          'It is not uncommon for me to experience inward emotional turbulence as well as frequent  arguments in my friendships.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question: 'I can be cold and stand-offish at times when I don’t know others very well.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
      {
        question:
          'I often take a very long time to have emotional or vulnerable conversations of any kind in  friendships.',
        styleAssociation: 'fa',
        styleAssociationValue: 1,
      },
      {
        question: 'I know that I am worthy of healthy, happy friendships.',
        styleAssociation: 'da',
        styleAssociationValue: 1,
      },
    ],

    results: {
      FA: {
        description: {
          title: 'Fearful Avoidant',
          content: `As a fearful avoidant, friendships can cause a lot of inward emotional turbulence for you. 

          This is because you experience both anxious and avoidant tendencies, which can cause you to swing between being very emotionally available, to being very withdrawn and even drained by your friendships. 
          
          On one hand, you deeply crave connection and closeness, but on the other – friendships can bring up a lot of fears and uneasiness, too.
          
          Some common characteristics of a fearful avoidant attachment style include:`,
        },

        strengths: [
          'Highly empathetic',
          'Attuned to the emotions and needs of others',
          'Can connect deeply with others',
        ],

        challanges: [
          'Oscillate between being emotionally available and very withdrawn',
          'Holds onto grudges and can experience frequent arguments in friendshsips',
          'Often feels taken advantage of, which causes anger and resentment',
        ],

        howWasItFormed: {
          title: 'How Was Your Attachment Style Formed?',
          content: `As you may already know, attachment styles are formed during childhood (although they may shift based on experiences you have later in life). 

          In childhood, it is likely that you experienced some form of emotional volatility in your household which caused you to subconsciously associate closeness with betrayal or disappointment. 
          
          As a result, vulnerability may make you feel uncomfortable in your friendships, even though deep down, you desperately crave it.`,
          assetUrl: 'svg/quiz-results-fa.svg',
        },

        whatDoesItMean: {
          title: 'How Do These Characteristics Play Out In Friendships?',
          content: `As a fearful avoidant, it is likely that you have some fears around betrayal and your worthiness, which can put you on high alert in your friendships.

          On one hand, when you first click with a new friend, you can be extremely emotionally present and great at forming a deep connection. 
          
          However, if you start to feel like your emotional energy is being overused or like you’re being taken advantage of, you can become very withdrawn. 
          
          This is because as a fearful avoidant, you sometimes struggle to communicate needs and boundaries with people, and can fall into people pleasing behaviors which can make you feel exhausted.`,
          content2: `And rather than communicate how you’re feeling or establish necessary boundaries if someone has upset you or overstepped the mark, you internalize these feelings which can lead to resentment and anger. 

          Over time, this can lead to grudges and frequent arguments in your friendships, or you may even cut friends out of your life rather than share how you feel.
          
          The problem is that you deeply crave love, connection and closeness. It’s just that you have some negative associations to intimacy, which stem from your childhood. This can make it harder for you to let down your guard and trust that others really do want to be a good friend to you.`,
          assetUrl: 'svg/quiz-results-bg.svg',
        },

        wordsOfEcouragement: {
          title: '',
          content: `The good news is that you do have a chance as an adult to change these automatic, subconscious patterns. And once you do, you can build and maintain healthy and lasting friendships that make you feel safe, calm, loved and deeply connected.`,
        },

        secondaryStyle: {
          title: 'Your Secondary Attachment Style & Why It Matters',
          description: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

          This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be preventing you from having deeply connected and harmonious friendships, or causing you inner turmoil.`,
        },

        nextSteps: {
          title: 'Your Next Steps And Recommended Courses',
          content: `What we recommend is that you first work on your primary attachment style – which is the style you see at the top of your chart with the highest percentage, and then after that, move onto your secondary attachment style, which is your next highest percentage.

          To help you get started with your transformation, below we have recommended some specific courses based on your results that’ll help you become more securely attached and build lasting friendships. 
          
          Many of our students who have taken these courses have reported being more securely attached within months of doing the course work. So we’re sure if you put in the effort, the same is possible for you too. `,
        },

        otherQuizzes: {
          title: 'What’s Your Attachment Style With Friends, Family & Romantic Partners?',
          content: `Did you know that your attachment style is the single largest predictor of whether your relationships thrive… or whether they hardly ever survive?

          This is not just your family relationships – but your relationships with your friends and romantic relationships too.
          
          To discover your attachment style in each of these areas - take another quiz!`,
        },
      },
      DA: {
        description: {
          title: 'Dismissive Avoidant',
          content: `As someone with a dismissive avoidant attachment style, it’s likely that you take your time building friendships as you find it very difficult to be vulnerable or share anything emotional early on.

          And as a result, you may come across as being stand-offish, or even get annoyed when others infringe on your personal space or try to get too close too soon.
          
          Common characteristics of a dismissive avoidant attachment style include:`,
        },

        strengths: [
          'Analytical, logical and practical when making decisions',
          'Can be deeply empathetic and attuned to people’s feelings',
          'Once trust has been built, will keep friends for a very long time',
        ],

        challanges: [
          'Dislikes being vulnerable or too emotional with friends',
          'Is often out of touch with emotions and can come across as being “cold”',
          'Can feel annoyed when others infringe on your personal space',
        ],

        howWasItFormed: {
          title: 'How Was Your Attachment Style Formed?',
          content: `As you may already know, attachment styles are formed during childhood (although they may shift based on experiences you have later in life). 

          In childhood, it is likely that you experienced some form of emotional neglect – meaning your needs around connection were largely unmet. You may have also been shamed for having certain emotions.
          
          So as an adult, you find it difficult, and even overwhelming, to connect with others.`,
          assetUrl: 'svg/quiz-results-da.svg',
        },

        whatDoesItMean: {
          title: 'How Do These Characteristics Play Out In Friendships?',
          content: `It’s likely that you need a lot of space from your friends, and can get irritated easily or come across as being “cold” when they infringe on your personal space, or if they ask you too many personal questions.

          This is because you’re often out of touch with your emotions and feel very uncomfortable with being vulnerable, or sharing anything emotional, especially early on in a friendship.
          
          In fact, the reason intense emotions can feel so overwhelming is because you might have been shamed for them as a child. This is why as an adult, you might prefer to get your needs for connection met through things, rather than people. `,
          content2: `Given this, it can take you a really long time to build close friendships, but once you do, you tend to keep the same friends for a very long time. Plus, given your logical, analytical mind, you can be great at giving practical advice!

          But when it comes to connecting emotionally with friends, you like to keep a divide between them and the vulnerable part of yourself. 
          
          This can cause issues in your friendship, and also make you feel very lonely. The thing is, you really do what to connect, it’s just that vulnerability and connection can feel scary. And as a result of this conflict in needs, you experience a lot of inner turmoil.`,
          assetUrl: 'svg/quiz-results-bg.svg',
        },

        wordsOfEcouragement: {
          title: '',
          content: `The good news is that you do have a chance as an adult to change these automatic, subconscious patterns. And once you do, you can build and maintain healthy and lasting friendships that make you feel safe, calm, loved and connected. `,
        },

        secondaryStyle: {
          title: 'Your Secondary Attachment Style & Why It Matters',
          description: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

          This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be preventing you from having deeply connected and harmonious friendships, or causing you inner turmoil.`,
        },

        nextSteps: {
          title: 'Your Next Steps And Recommended Courses',
          content: `What we recommend is that you first work on your primary attachment style – which is the style you see at the top of your chart with the highest percentage, and then after that, move onto your secondary attachment style, which is your next highest percentage.

          To help you get started with your transformation, below we have recommended some specific courses based on your results that’ll help you become more securely attached and build lasting friendships. 
          
          Many of our students who have taken these courses have reported being more securely attached within months of doing the course work. So we’re sure if you put in the effort, the same is possible for you too. `,
        },

        otherQuizzes: {
          title: 'What’s Your Attachment Style With Friends, Family & Romantic Partners?',
          content: `Did you know that your attachment style is the single largest predictor of whether your relationships thrive… or whether they hardly ever survive?

          This is not just your family relationships – but your relationships with your friends and romantic relationships too.
          
          To discover your attachment style in each of these areas - take another quiz!`,
        },
      },
      AP: {
        description: {
          title: 'Anxious Preoccupied',
          content: `As someone who has an anxious preoccupied attachment style, you may feel quite insecure in your friendships because you have a deep fear that you’ll be excluded or abandoned. 

          These fears may cause you to abandon yourself to please others and put your friends on pedestals, which causes an imbalance and may lead to “needy” behaviors in an attempt to get closer to them. 
          
          Some common characteristics of an anxious preoccupied attachment style include:`,
        },

        strengths: [
          'Highly empathetic and giving with time, energy and emotions',
          'Attuned to the emotions, needs and wants of others',
          'A natural at connecting deeply with others',
        ],

        challanges: [
          'Constant worry that friends will exclude you, abandon you, or will get bored with you',
          'Prone to people pleasing and self-abandonment to avoid being left',
          'Struggle to identify and communicate needs & boundaries, and to self-soothe when upset',
        ],

        howWasItFormed: {
          title: 'How Was Your Attachment Style Formed?',
          content: `As you may already know, attachment styles are formed during childhood (although they may shift based on experiences you have later in life). 

          In childhood, it is likely that you experienced inconsistent emotional support – meaning your needs around connection were sometimes unmet.
          
          As a result, you often felt abandoned as a child, so as an adult, you abandon yourself in your friendships to avoid being left by others.`,
          assetUrl: 'svg/quiz-results-ap.svg',
        },

        whatDoesItMean: {
          title: 'How Do These Characteristics Play Out In Friendships?',
          content: `It’s likely that you feel quite insecure in your friendships because you fear being abandoned, excluded or like others might get bored of you.

          These fears can cause you to become very preoccupied with how safe and liked you feel in your social circles, which leads to people pleasing to keep people close to you.
          
          This is a subconscious strategy to stop them from leaving you – it is not intentional. 
          
          But what this means is that you often find yourself doing things for other people so that you feel loved, even if it goes against what you really want or need.
          
          What’s more, because you’re so outwardly focused on others, you might even find it hard to identify what your needs and boundaries actually are, which can lead to resentment and you living in a state of continual self-abandonment.`,
          content2: `It’s likely that you rely on your friends to help you self-soothe and deal with problems, rather than being able to calm yourself and work through issues on your own first. 

          You’re most probably also very empathetic and highly attuned to the needs and emotions of others, which makes you a great friend. 
          
          But the downside to this superpower is that if you notice any subtle changes in behavior, you panic and may even assume that you’ve done something wrong. As a result, you’ll try to regain closeness as soon as possible, especially as you don’t like to spend too much time on your own. `,
          assetUrl: 'svg/quiz-results-bg.svg',
        },

        wordsOfEcouragement: {
          title: '',
          content: `The good news is that you do have a chance as an adult to change these automatic, subconscious patterns. And once you do, you can build and maintain healthy and lasting friendships that make you feel safe, calm, loved and deeply connected.`,
        },

        secondaryStyle: {
          title: 'Your Secondary Attachment Style & Why It Matters',
          description: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

          This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be preventing you from having deeply connected and harmonious friendships, or causing you inner turmoil.`,
        },

        nextSteps: {
          title: 'Your Next Steps And Recommended Courses',
          content: `What we recommend is that you first work on your primary attachment style – which is the style you see at the top of your chart with the highest percentage, and then after that, move onto your secondary attachment style, which is your next highest percentage.

          To help you get started with your transformation, below we have recommended some specific courses based on your results that’ll help you become more securely attached and build lasting friendships. 
          
          Many of our students who have taken these courses have reported being more securely attached within months of doing the course work. So we’re sure if you put in the effort, the same is possible for you too. `,
        },

        otherQuizzes: {
          title: 'What’s Your Attachment Style With Friends, Family & Romantic Partners?',
          content: `Did you know that your attachment style is the single largest predictor of whether your relationships thrive… or whether they hardly ever survive?

          This is not just your family relationships – but your relationships with your friends and romantic relationships too.
          
          To discover your attachment style in each of these areas - take another quiz!`,
        },
      },
      SA: {
        description: {
          title: 'Secure Attachment',
          content: `As a securely-attached individual, it’s likely that you feel at ease in friendships because you’re comfortable with communicating your needs, expressing your emotions and setting boundaries. 

          As a result, you find it easy to make friends and to give and receive support, because deep down, you know that you’re worthy of healthy, happy friendships.
          
          Common characteristics of a secure attachment style include:`,
        },

        strengths: [
          'At ease in friendships – feels comfortable with giving and receiving support',
          'Natural ability to express needs, boundaries and emotions',
          'You believe that you’re worthy of healthy, happy friendships',
        ],

        challanges: [
          'Can find it difficult to relate to those with an insecure attachment style',
          'May feel frustrated with friends who have constant emotional problems',
          'Can feel confused when challenges arise and other people don’t communicate properly',
        ],

        howWasItFormed: {
          title: 'How Was Your Attachment Style Formed?',
          content: `As you may already know, attachment styles are formed during childhood (although they may shift based on experiences you have later in life). 

          It’s likely that you had a healthy relationship with both of your caregivers during childhood. 
          
          You were probably encouraged to express yourself, and your needs for emotional and physical support were consistently met. 
          
          That’s why as an adult, it’s easier for you to create supportive, communicative friendships with others.`,
          assetUrl: 'svg/quiz-results-sa.svg',
        },

        whatDoesItMean: {
          title: 'How Do These Characteristics Play Out In Friendships?',
          content: `As someone who is securely attached, it’s likely that you’re able to consistently communicate your feelings and needs in friendships, and actively listen when others express theirs. 

          Because you feel comfortable in the relationship to yourself, you feel at ease around your friends and consistently enjoy their company. 
          
          And if conflicts do arise, or someone oversteps a boundary, you know how to effectively resolve the issue and communicate what you need in the situation. 
          
          You’re no stranger to compromising where necessary too, and enjoying giving and receiving support in equal measures.
          
          You’re also able to self-soothe and deal with personal problems on your own when they arise, yet you’re also comfortable with sharing personal information and struggles with your friends.`,
          content2: `However, where you might experience problems is that you can struggle to relate to your friends if they have a more insecure attachment style, and therefore may have more anxiety and fears in friendships than you. 

          It can be hard for you to understand why they don’t just calmly say what they mean and need, or why they can be more sensitive.
          
          Or why they regularly have emotional struggles with others, or within themselves.
          
          This can lead to conflict and both parties feeling misunderstood and unseen.
          
          What’s more, when you’re feeling this way, it can trigger your secondary attachment style – which might make you become more avoidant or anxious, depending on the situation.`,
          assetUrl: 'svg/quiz-results-bg.svg',
        },

        wordsOfEcouragement: {
          title: '',
          content: `The good news is, you can learn how to better understand and relate to your loved ones and reprogram your secondary attachment style so that you can improve your relationships and feel more connected, harmonious and calm. `,
        },

        secondaryStyle: {
          title: 'Your Secondary Attachment Style & Why It Matters',
          description: `Attachment styles are formed in varying proportions based on the experiences that you may have had. This means that you may carry other characteristics from other attachment styles that surface in specific situations.

          This is why it’s important to also address your secondary attachment style so you can work on any additional behaviors or thinking patterns that might be preventing you from having deeply connected and harmonious friendships, or causing you inner turmoil.`,
        },

        nextSteps: {
          title: 'Your Next Steps And Recommended Courses',
          content: `What we recommend is that you first work on your primary attachment style – which is the style you see at the top of your chart with the highest percentage, and then after that, move onto your secondary attachment style, which is your next highest percentage.

          To help you get started with your transformation, below we have recommended some specific courses based on your results that’ll help you become more securely attached and build lasting friendships. 
          
          Many of our students who have taken these courses have reported being more securely attached within months of doing the course work. So we’re sure if you put in the effort, the same is possible for you too. `,
        },

        otherQuizzes: {
          title: 'What’s Your Attachment Style With Friends, Family & Romantic Partners?',
          content: `Did you know that your attachment style is the single largest predictor of whether your relationships thrive… or whether they hardly ever survive?

          This is not just your family relationships – but your relationships with your friends and romantic relationships too.
          
          To discover your attachment style in each of these areas - take another quiz!`,
        },
      },
    },
  },
]

export const EXTERNALQUIZQUESTIONS = [
  {
    question: 'Are you currently in a relationship (Recent breakup? Married, single, divorced?)',
    association: 'relationship',
  },
  {
    question:
      'I can be very emotionally present with others (friends, family, partners, strangers), but it takes me a while to build trust and share vulnerable things about myself.',
    association: 'fa',
  },
  {
    question: 'I often put other people in my life on a big pedestal. (Partner, friends, family)',
    association: 'ap',
  },
  {
    question:
      'I feel naturally comfortable and safe expressing my feelings and needs to loved ones.',
    association: 'sec',
  },
  {
    question: 'I feel very upset when others infringe on my need for space or time alone. ',
    association: 'da',
  },
  {
    question:
      "I am willing to work through challenges in a relationship before suddenly trying to leave the relationship itself. (If you're not in a relationship, think of how you would handle conflict in a partnership.)",
    association: 'sec',
  },
  {
    question: 'I tend to be out of touch with my emotions quite frequently.',
    association: 'da',
  },
  {
    question:
      "I am very attuned to others' needs and often put them before my own and resent it later.",
    association: 'fa',
  },
  {
    question:
      'I constantly want to be emotionally closer to my partner. This can also apply to my close friendships or romantic interests.',
    association: 'ap',
  },
  {
    question: 'I am effective at compromising and communicating.',
    association: 'sec',
  },
  {
    question:
      'It is very difficult for me to set boundaries unless I am angry. I can sometimes set excessive boundaries and push people away too dramatically out of anger.',
    association: 'fa',
  },
  {
    question:
      'If I notice my partner showing any signs of coldness, I panic and want to get closer as quickly as possible. This often happens to me in friendships as well.',
    association: 'ap',
  },
  {
    question:
      'It is not uncommon for me to experience inward emotional turbulence throughout the duration of my romantic relationship. This applies to close family members as well.',
    association: 'fa',
  },
  {
    question:
      'I often feel very hot or very cold towards my partner or family members. I tend to operate in extremes in how I relate to others.',
    association: 'fa',
  },
  {
    question: 'I know that I am worthy of a healthy, happy relationship. ',
    association: 'sec',
  },
  {
    question:
      'When I feel hurt by a loved one, I often have a strong fight or flight response. I find myself wanting to push this person as far away as possible. (Friends, family, romantic relationship).',
    association: 'fa',
  },
  {
    question:
      'I do not feel as though I need anything from my romantic partner or loved ones. It can be difficult to conceptualize how others would meet a lot of my needs.',
    association: 'da',
  },
  {
    question: 'I do not enjoy being out of romantic relationships. I often fear being alone.',
    association: 'ap',
  },
  {
    question:
      "If a loved one's behavior hurts me, I will express my feelings and try to understand what caused them to act that way. ",
    association: 'sec',
  },
  {
    question:
      'I hunger for closeness, but I fear the emotional difficulty of it at the same time (friends, family, romantic partners). ',
    association: 'fa',
  },
  {
    question:
      'I do not like making social plans with others in advance. I often fear being trapped by commitments with other people. ',
    association: 'da',
  },
  {
    question: 'I find that setting boundaries comes naturally to me. ',
    association: 'sec',
  },
  {
    question: 'I focus much more on the relationships in my life than I do on myself.',
    association: 'ap',
  },
  {
    question: 'I often feel protective over my space, privacy and belongings. ',
    association: 'da',
  },
  {
    question:
      'I generally feel invaded when my partner or loved ones demand too much physical affection. ',
    association: 'da',
  },
  {
    question:
      'I would prefer to spend most of my free time with my partner if I were to be in a romantic relationship. It would be hard for me to want to do things separately.',
    association: 'ap',
  },
  {
    question: 'I feel that it is easy to be vulnerable with my romantic partner or loved ones.',
    association: 'sec',
  },
  {
    question:
      'I find that my partner or loved ones usually emotionally recover from conflict before I do. ',
    association: 'da',
  },
  {
    question: 'I deeply fear being abandoned by my partner or love interests. ',
    association: 'ap',
  },
  {
    question: 'I have previous knowledge of attachment theory.',
    association: 'attach-theory',
  },
  {
    question: 'How old are you?',
    association: 'age',
    options: ['18-24', '25-34', '35-44', '45-54', '55+'],
  },
  {
    question: 'What is your gender?',
    association: 'gender',
    options: ['Male', 'Female', 'Other', 'Prefer not to say'],
  },
]

export const ROYAL_RUMBLE = {
  ap: {
    TITLE: 'Anxious Preoccupied',

    YOUTUBE_URL: 'https://storage.googleapis.com/pds_videos/RoyalRumbleAPshortvideo.mp4',

    BANNER_SEGMENT: {
      headline: `Your fear of abandonment may be getting in the way of you forming healthy relationships that last`,
      copy: `You’re an extremely empathetic person who has mastered the art of connecting with others on a deep and real level. You may be a social butterfly or deeply value being in long-term relationships. You’re an expert at reading people and are the person your friends turn to when they need help. Yet, ironically, your relationships are often a huge source of your pain in your life.

      Because you have such an enormous desire for deep connections, losing the people closest to you is one of your biggest fears. This deep fear causes you to do whatever it takes to stay close to them, even if it means sacrificing your own needs and boundaries in the process.  
      
      Your underlying fear of abandonment is amplified in romantic relationships, especially when you find yourself dating someone who likes their space, someone who isn’t very emotionally expressive, or someone who doesn’t appear to really “need” you. When you’re met with these situations, you constantly feel like you’re giving and giving and giving, but getting nothing back in return. All of this leads you to wonder “why aren’t I enough?”.
      
      Because of this internal struggle, it’s common for you to become angry and resentful towards your partner when they’re not meeting your expectations. Not knowing how to express yourself best, you may pick fights with your partner constantly, pushing them away, only to reaffirm your beliefs that you’re going to be abandoned to end up alone.`,
    },

    FAMILIAR_SEGMENTS: {
      traits: [
        [
          {
            title: 'Worrying About Breakups',
            copy: 'Once you’ve formed a strong romantic connection and feel attached to them, you soon start to worry about how long it’s going to last for.',
          },

          {
            title: 'Keeping Score',
            copy: 'Because of your skill at reading people, you can be hyper-sensitive to how much your partner is (or isn’t) loving you, over-analyzing your interactions.',
          },
        ],

        [
          {
            title: 'Jumping to Conclusions',
            copy: 'You feel intense feelings of panic when you sense your loved one is pulling away. Unanswered texts can get your head spinning.',
          },

          {
            title: 'Fearing Abandonment',
            copy: 'You spend a great deal of time feeling on edge worrying that the person or people you’ve put the most trust in might leave you one day.',
          },
        ],

        [
          {
            title: 'Taking Forever to Heal',
            copy: 'Rather than accepting relationship endings guilt-free, you catch yourself telling yourself “I’m unlovable” or “I’m not good enough”.',
          },

          {
            title: 'Putting Yourself Last',
            copy: 'You spend so much time putting other people’s wants and needs that you do a poor job assessing what you need and want from others.',
          },
        ],
      ],
      headline: `If you relate to these statements and if it feels like your relationships have pushed you to the point of exhaustion, you’re not alone. And here's what you need to understand:`,

      subHeadline: `**You are not broken! These are just your patterns; and your patterns can be changed.**`,

      subHeadlineMobile: `You are not broken!

These are just your patterns; and your patterns can be changed.`,
    },

    ATTACHMENT_ORIGIN_SEGMENT: {
      copy1: `Your attachment style and the relationship challenges it brings are **not your fault**, they are a reflection of your story. 

An Anxious Preoccupied attachment style is what you end up with if you received inconsistent emotional support from your primary caregivers during your childhood. It doesn’t mean that you were severely neglected, but maybe in your early years your parents weren’t around much due to their work schedule or because they were busy taking care of your siblings. Or maybe they were present physically, but were cold or withdrawn, unable to express themselves, relate to you, or understand what you needed from them at the time.`,

      copy2: `These early experiences contribute to how you perceive love and the role you play in your relationships to earn it. When you’ve felt forgotten or unheard as a child, you may try to make up for this through several behaviors in your adulthood. You might people-please those you care about and do whatever it takes to get closer to them. All of this is a subconscious coping mechanism that ties in with your fear of abandonment and is a subconscious strategy to stop them from leaving you. 

The problem is that carrying your fears and anxious behaviors into adulthood can lead to major imbalances in your relationships and can sabotage special connections.`,

      heading1: `
      Not developing an awareness of your patterns or taking action to break them is a receipt for painful heartbreak.`,

      heading2: `
      What if I told you there was a way to change your attachment style and your beliefs without having to spend thousands of dollars on therapy or coaching sessions?`,
    },

    THAIS_SEGMENT: {
      copy: `
      The Anxious Preoccupied to Securely Attached Program is your personal invitation to break free from your attachment trauma and create the love, safety, and connection that you deeply crave – and know, deep down, that you do deserve.`,
    },

    GAINACCESS_SEGMENT: `This online program is designed to show you, step by step, how to reprogram your attachment style by teaching you highly-targeted and effective tools based on the latest research and my decades of experience counseling couples and individuals. 

My system differs from other personal development programs because it’s designed to **work directly with your subconscious mind** – which is ESSENTIAL for any lasting change to occur.`,

    HOWPROGRAMWORK_SEGMENT: [
      `Register for the program`,

      `Make your way through the program’s online videos at your own pace`,

      `Access your workbooks and complete your exercises`,

      `Attend optional weekly webinars and Q&As`,

      `Participate in our online communities for further discussions as you please`,

      `Get your certificate of completion`,

      `Access your course materials again whenever you need a refresh`,
    ],

    WHATPROGRAMCOVER_SEGMENT: {
      copy: `The Anxious Preoccupied to Securely Attached Program gives you access to **5 tailored online courses** you can complete on your own schedule. Through hours of videos, highly effective exercises, and my support through intimate webinars, we’ll together to tackle a list of other emotional issues that you might deal with in your life that come from your attachment trauma, like:
      `,

      bullets: [
        `Codependency and enmeshment`,

        `Having a lack of boundaries`,

        `People-pleasing`,

        `Self sabotaging`,

        `Difficulty communicating needs`,

        `Having trust issues`,
      ],
    },

    LEARNHOWTO_SEGMENT: [
      {
        title: 'Make your way',
        copy: 'Navigate love with less fear, distress and anxiety.',
        deskImageURL: 'images/rr-learn-tl-1.svg',
      },

      {
        title: 'Feel seen',
        copy: 'Feel seen, heard, and connected in your relationships with others.',
        deskImageURL: 'images/rr-learn-tl-2.svg',
      },

      {
        title: 'Feel confident',
        copy: 'Improve the relationship within yourself.',
        deskImageURL: 'images/rr-learn-tl-3.svg',
      },

      {
        title: 'Be certain',
        copy: 'Gain a sense of certainty in your relationships.',
        deskImageURL: 'images/rr-learn-tl-4.svg',
      },
    ],

    EXCLUSIVEBONUS_SEGMENT: [
      `Access to an **additional 55+ courses** on challenges associated with your attachment style that you can choose to take at any suitable time.`,

      `My **weekly live webinars** where you can ask me your own questions, share your experience, and discuss your challenges in an informal and intimate environment with other members.`,

      `**Priority access** to all upcoming promotions, offers, and new programs at The Personal Development School.`,

      `The chance to attend **our regular events and socials** to connect with myself, The Personal Development School team, and other members.`,

      `An invitation to our **online forum and Facebook group** where you have the opportunity to interact with a supportive community like-minded individuals who are equally dedicated to their healing.`,
    ],

    STILLNOTSURE_SEGMENT: {
      copy1: `**We hear you.** 

You could tackle your healing on your own, reading dozens of books on attachment styles, or turn to your friends or journaling practices to work through your traumas. But many of our members come to us after attempting these healing methods **and seeing limited results or change**. 
                  
Sometimes what you need is more support, and guidance from someone who will take you through the exercises you need to really understand yourself, face yourself, and re-program your beliefs. Speaking from experience and from the experience of our members, it’s extremely difficult to do that all on your own.`,

      copy2: `Or maybe you feel like you’re better off working with a therapist or relationship coach in a private capacity. We are big advocates of seeking therapy here at The Personal Development School, but advise you to consider the amount of time, money, and insurance benefits budget it can take you to shop for the right one. 

      Even then, the risk with some types of talk therapies or working with a relationship coach is that not all types of therapy are designed to tackle attachment trauma, which lives in the body and deep in your subconscious. Without working with the most qualified type of therapist trained to help you do the unconscious work to heal your core wounds, you can end up seeing limited progress for quite the monthly bill (between $150-200 per session).`,

      copy3: `
      We are committed to helping you gain more self awareness and teaching you how to develop the habits and thought processes you need to see long-lasting change.`,
    },
    TESTIMONIAL_SEGMENT: [
      {
        avatar: 'images/avatar_carrie.jpeg',
        name: 'Carrie L',
        testimonial: `Hi! I’m Carrie from northern California, USA. I’ve been a member of the PDS school since around June, 2020. This experience has been a life changer for me.  I have always been a seeker and self-improvement fan, but the courses and webinars with Thais as well as the zoom meetings with the community led by Mike, have given me actionable tools and a supportive safe space to discuss and practice them.  I am so fortunate to have found this school and so many friendly folks to share this journey!`,
      },

      {
        avatar: 'images/avatar_tappy.png',
        name: 'Tapanga P',
        testimonial: `I discovered PDS shortly after my 19th birthday, even being one of the youngest in the school, everyone is so welcoming!!
        I really love how Thais Gibson covers so many topics and goes above and beyond to cater to the needs of the members. The post-webinar breakout rooms have so many brilliant, and like-minded individuals who I always look forward to talking to!
        PDS has had such an amazing impact on my relationships, my self-esteem and has given me valuable self-awareness. The tools within PDS have provided me with the strength, and ability to heal, almost completely from my anxiety, and I feel so much more confident within myself 😊`,
      },

      {
        avatar: 'images/avatar_jake.png',
        name: 'Jake J',
        testimonial: `PDS has impacted my life immensely. From creating a healthy relationship to self, improving my family dynamics, impacting my awareness of my subconscious programming, to providing the skills/tools for improving my communication with others in all relationships. It has honestly changed my life for the better and am on my path towards becoming a more secure person. Not to sound too much like an infomercial, but I don’t know where I would be in my life without this place. They have created such an amazing community that is extremely supportive and is full of wonderful people. PDS has grown into something I am very proud to be a part of and look forward to seeing where this journey takes me.`,
      },

      {
        avatar: 'images/avatar_leona.jpg',
        name: 'Leona',
        testimonial: `Joining PDS was the single best decision I made last year as it opened my eyes to the possibility that I could dramatically improve the quality of my relationships and that my past did not have to define my future. I learned about my attachment style, core wounds and personality needs and how these have been affecting the quality of my life currently. Thais gives clear instructions as to how you can reprogramme your subconscious mind, learn to communicate more effectively and excel in all 7 areas of your life to get rid of so many roadblocks that may be holding you back from living your best life. The PDS community is a truly supportive environment for personal growth and healing, my only regret is that I did not find all this out sooner as it has been truly life changing for me! ❤️ 
        `,
      },
    ],
    BESTSELF_SEGMENT: {
      subheading: `All of this is achievable through as little as 10 minutes of coursework a day.`,

      bullets: [
        [
          {
            title: '1. No More Anxious Energy',
            copy: `Instead of being a ball of anxious energy in relationships, you feel calm, safe, seen and connected.`,
          },
          {
            title: '2. Healing More Quickly ',
            copy: `Having the tools to recover from breakups in a much healthier way with compassion for yourself.`,
          },
        ],

        [
          {
            title: '3. Accepting Yourself ',
            copy: `Feeling deserving of love — without needing to overgive or strive for perfection to feel accepted by others.`,
          },
          {
            title: '4. Being Able to Self-Soothe',
            copy: `Being able to pause, reflect, and regulate your emotions instead of reacting on auto-pilot or taking situations personally.`,
          },
        ],

        [
          {
            title: '5. Feeling Less Alone',
            copy: `Because no matter what life throws at you, you know there is one person who finally loves you unconditionally and who will never abandon you again - and that’s YOU.`,
          },
          {
            title: '6. Knowing Yourself Better',
            copy: `Being attuned to your needs, your wants, and your boundaries. Having a healthier relationship with yourself knowing you always have your own back.`,
          },
        ],

        [
          {
            title: '7. No Longer Fearing Your Future ',
            copy: `Feeling confident in your future and assured that with all your emotional awareness and mastery, you will no doubt end up with the person that’s right for you.`,
          },
        ],
      ],
    },
    MYQUESTION_SEGMENT: {
      subheading1: `I get it — you might feel paralyzed by the material you have to learn, worried about facing your traumas, or feel doubtful that you have what it takes to commit to yourself. 

**But we’re going to do this together. My team and I will be there to support you every step of the way.**`,

      subheading2: `**The biggest risk you can take is doing nothing at all.** 

Signing up for a program at The Personal Development School is your chance to step in and put an end to your vicious attachment cycles.
                    
**Too many people let their attachment styles, their beliefs, and their subconscious relationship patterns control their whole lives. But not you. It doesn’t have to be you too.**`,

      bullets: [
        `Repeating your same relationship cycles over and over again with no way out, not knowing where to start to create real change.`,

        `Continuing to feel a deep sense of disappointment with yourself for the choices you make when you attempt to get closer to people and salvage relationships that are not in alignment with what you want.`,

        `Having to go through the motions of yet another painful breakup a few months down the line.`,

        `Worrying constantly about living up to the fear that you’re going to end up alone.`,

        `Spending day to day struggling to shut out the voices in your head that say “I can’t change”, “I’m not good enough”, and “everyone I love will leave me”.`,
      ],
    },

    OFFER_SEGMENT: {
      copy: `Facing yourself to create true, lasting change from within is challenging. We get it.  
And healing your attachment traumas and reprogramming your thoughts, patterns, and beliefs doesn’t happen overnight. 
                
**But it CAN happen with the right support and guidance. That’s what my team and I are here for.** 
                
All you need to do is take that brave first step. 
                
You’ve landed here because you’re being given the chance to radically transform yourself by gaining the tools you’ve been missing. 
                
By making a small yet substantial investment in bettering yourself, you will be on your way to developing the skills you’ve been missing to attract, create, develop, and keep the relationships you want to live a fulfilling life 
                
It’s time to look at your emotional self in the mirror. 
                
**Do you like what you see? Or are you ready to step up?**
                
Enroll in The Anxious Preoccupied to Securely Attached Program now and prepare to create the safe home within yourself you’ve been looking for all along.`,

      bullets: [
        `An additional 55+ personal development courses on attachment styles improving relationships and emotional mastery`,
        `Weekly live webinars with Thais Gibson`,
        `Access to 100s of pre-recorded webinars`,
        `Private online forum, support groups, and Facebook group`,
        `Regular events and socials`,
        `24/7 live chat functionality`,
      ],
    },
    FAQs: [
      {
        title:
          'I just want to heal my attachment style with a single course, I’m not sure I want to become a monthly member. Can’t I just purchase the course relevant to my attachment style?',

        content: `The monthly membership offers an integrated approach to healing your attachment style, your emotions and your relationships by targeting your subconscious mind.
    
Included with the monthly membership are courses on reprogramming each of the 4 attachment styles – but as you’ll discover, **attachment trauma often causes other issues** – such as codependency or people pleasing – to name a few – which require their own courses. So to equip you with the tools you need to best heal your attachment style, the membership gives you **access to 45+ courses – giving you access to a “university” of emotional support**.
    
Plus, included with the membership is access to **weekly LIVE webinars and Q&A calls**. As attachment trauma was formed as a result of the relationships in your early years – it can **best be healed in relationship with others**. So through our webinars, online community (forum), group chats and Facebook group – you’ll be supported by me and other members and receive a more **integrated approach to healing**.
    
We have found that out of the thousands of students who have become more securely attached, they have done so as a result of this integrated approach rather than taking single courses on their own.
    
This is deep, life-changing work, and I want to provide you with the best support possible.`,
      },

      {
        title: 'Can I cancel anytime?',
        content: `Absolutely. If you feel that the Personal Development School is not for you, **you can cancel your membership at any time – no questions asked.**
    
We have a very high success rate of students becoming more securely attached and fulfilled in all areas after becoming members of the school, and I’m confident that if you become a member – you will too. But if for whatever reason it no longer feels like the right fit or time for you, you can cancel.`,
      },

      {
        title: 'I’m on the fence about becoming a member. Do you offer a money-back guarantee?',
        content: `Yes, to help you make the best decision for you, we offer a no-questions asked 7-day money back guarantee – meaning there is absolutely no risk if you become a member today, and then later decide that the school is not for you.`,
      },

      {
        title:
          'Wow, that’s a lot of courses and support I get access to, why is it so reasonably priced?',
        content: `The short answer is: I want to help as many people as I possibly can!
    
I’m incredibly passionate about sharing what’s become my life’s work and this integrated approach to healing attachment trauma because **I know it works – as long as you do**. I have developed a system and tools that are highly effective – and I want to make them as accessible as possible.`,
      },

      {
        title: 'I’m considering other courses or therapy – why should I trust you?',
        content: `It’s important you feel confident in your decision to work with me.
    
I have added some testimonials on this page, but I urge you to do your own research by **checking out the Personal Development School’s YouTube Channel** to see thousands of user-generated reviews on the hundreds of videos I create to see if my style resonates with you.`,
      },
    ],
  },

  da: {
    TITLE: 'Dismissive Avoidant',

    YOUTUBE_URL: 'https://storage.googleapis.com/pds_videos/RoyalRumbleDAshortvideo.mp4',

    BANNER_SEGMENT: {
      headline: `Your fear of vulnerability may be getting in the way of you forming healthy relationships that last`,
      copy: `You like to do your own thing and value your freedom, space, and independence. Because you thrive independently, you value your time spent out of romantic relationships. You are a very logical and practical person when it comes to making decisions and appreciate whenever people are direct, clear, and specific with what they need from you, be it at work, or in your friendships, family, or romantic relationships. Identifying as more of a “thinker” than a “feeler”, you are much more comfortable expressing what you think about situations to friends and family than what you feel about them. Being vulnerable with others is difficult for you and oversharing or being around intense emotions generally makes you feel uncomfortable. You feel a lot more in control and relaxed when you are able to choose when and where you’ll be with people, how close, and for how long. You’ve never been great at putting language to your feelings or sitting with them in the present so you might have a tendency to escape into your work, books, podcasts, video games, the news, or travel instead, enjoying the more mental rather than emotional investments they require from you.  

      When it comes to romantic relationships, you can have a pretty hard time navigating them. They often feel overwhelming and you can never quite understand what’s expected from you. It seems like no matter what you do, you just can’t seem to get it right. Incredibly self-sufficient with an attitude that the only person you can rely on is yourself, you have a hard time believing that you should ever have to rely on a partner to meet your needs. When faced with the pressures of meeting someone else’s needs, you can feel suffocated. Long term relationships and long term commitments can make you feel trapped at one point or another and you worry constantly that being involved might mean losing that independence that you value so much.
      
      Your only strategy when you feel this inner conflict is to detach from others, distance yourself, and get very protective over your time and space alone. It’s so common for you to go to great lengths to avoid letting people in, that it can come across like you don’t need your partner, friends, or family at all. The truth is that you do value their connection, you just feel wildly misunderstood and don’t know how to get out of your head or let your guard down to truly connect.`,
    },
    FAMILIAR_SEGMENTS: {
      traits: [
        [
          {
            title: 'Keeping Walls Up',
            copy: 'You enjoy making new connections and having conversations but prefer to keep up a strong wall. Part of you fears people truly knowing or seeing you for who you are. It’s almost like you’re keeping some sort of secret.',
          },

          {
            title: 'Taking Criticism to Heart',
            copy: 'You are quite sensitive to criticism though you may never show it. When bosses, partners, or friends make comments on something you haven’t done perfectly, you take it to heart and can end up attacking yourself for your flaws.',
          },
        ],
        [
          {
            title: 'Having Relationship Anxiety',
            copy: 'You can enjoy the dating phase of relationships but start to get extremely irritable and anxious when relationships start to demand your consistent time, energy, and attention.',
          },

          {
            title: 'Avoiding Vulnerability',
            copy: 'As much as you would like to learn how to be vulnerable with others, you equate being vulnerable with bringing some sort of bad consequences, so will always back out.',
          },
        ],
        [
          {
            title: 'Feeling Trapped',
            copy: 'When it comes to being in a romantic relationship, you simply cannot help feeling trapped and suffocated by your partner’s demands, even when you truly love the person.',
          },

          {
            title: 'Shutting Others Out',
            copy: 'You have had several arguments with partners, family, and friends who repeat that you appear “cold”, have your walls up too high, and are shutting people out.',
          },
        ],
        [
          {
            title: 'Switching Off Your Emotions',
            copy: 'Believing that emotions make you weak, you’re a master at diminishing them to a degree you secretly know is concerning. You may not have cried to unleashed any anger or disappointment in years as a result.',
          },

          {
            title: 'Constantly Being Blamed',
            copy: 'In romantic relationships, it’s a normal occurrence for you to feel like you are always being blamed for things you shouldn’t be blamed for.',
          },
        ],
      ],
      headline: `If you relate to these statements and if you worry that you’ll never be able to feel safe, open up, truly connect, and have fulfilling relationships, you’re not alone. And here's what you need to understand:`,
      subHeadline: `**You are not broken! These are just your patterns; and your patterns can be changed.**`,
      subHeadlineMobile: `You are not broken!

These are just your patterns; and your patterns can be changed.`,
    },
    ATTACHMENT_ORIGIN_SEGMENT: {
      copy1: `Your attachment style and the relationship challenges it brings are not your fault, they are a reflection of your story. 

A Dismissive Avoidant attachment style is what you end up with if you received a lack of emotional support or harsh criticism from your primary caregivers during your childhood. Maybe in your early years your parents weren’t around much due to their work schedule or because they were busy taking care of your siblings. Or maybe they were quite harsh on you when you expressed your feelings, shaming you for them or telling you, for example, that crying was “wrong”. You might have grown up in a home with a controlling or authoritarian caregiver who placed very high expectations on you. When you didn’t meet their idea of perfection, you were criticized or even shamed for falling below the mark.`,
      copy2: `As a result, you learned to take care of your own needs for emotional connection and developed a subconscious fear or block around opening up to others. Because you didn't feel like you were able to express and show your entire self to your parents, you now have a hard time feeling safe when doing that to others. Learning that displaying certain emotions was wrong, you became an expert at bottling things up and repressing your emotions for no one to see. And since you were regularly criticized and were always trying to meet impossible expectations, it’s likely that today you’re very sensitive to criticism and can feel a lot of shame around your own imperfections.

With a past that has made emotional connection feel unpredictable and even scary, you can now find it difficult and overwhelming to connect with others, regularly withdrawing back to yourself as the only person you can rely on. 
    
The problem is that carrying your fear of connection and your avoidant behaviors into your adulthood can lead to major imbalances in your relationships as you push people away and can sabotage special connections.
    `,
      heading1: `
    Not developing an awareness of your patterns or taking action to break them is a recipe for destructive isolation, painful heartbreak, and a lack of fulfilling relationships in your life.`,
      heading2: `
    What if I told you there was a way to break these unconscious patterns and beliefs, re-write your story, and change your attachment style without having to spend thousands of dollars on therapy or coaching sessions?`,
    },
    THAIS_SEGMENT: {
      copy: `
    The Dismissive Avoidant to Securely Attached Program is your personal invitation to break free from your attachment trauma and destructive isolation patterns to create the love, safety, and connection that you deeply crave – and know, deep down, that you do deserve.`,
    },
    GAINACCESS_SEGMENT: `This online program is designed to show you, step by step, how to reprogram your attachment style by teaching you highly-targeted and effective tools based on the latest research and my decades of experience counseling couples and individuals. 

My system differs from other personal development programs because it’s designed to **work directly with your subconscious mind** – which is ESSENTIAL for any lasting change to occur.`,
    HOWPROGRAMWORK_SEGMENT: [
      `Register for the program`,

      `Make your way through the program’s online videos at your own pace`,

      `Access your workbooks and complete your exercises`,

      `Attend optional weekly webinars and Q&As`,

      `Participate in our online communities for further discussions as you please`,

      `Get your certificate of completion`,

      `Access your course materials again whenever you need a refresh`,
    ],
    WHATPROGRAMCOVER_SEGMENT: {
      copy: `The Dismissive Avoidant to Securely Attached course gives you access to **5 tailored online courses** you can complete at your own convenience. 

Through hours of videos, highly effective exercises, and my support through intimate webinars, we’ll together to tackle a list of other emotional issues that you might deal with in your life that come from your attachment trauma, like:`,
      bullets: [
        `Bottling things up`,

        `Feeling constant anxiety around your relationships`,

        `Putting up walls`,

        `Avoiding your emotions and the emotions of those around you`,
      ],
    },
    LEARNHOWTO_SEGMENT: [
      {
        title: 'Understand your emotions',
        copy: 'Get comfortable with experiencing the full range of your feelings.',
        deskImageURL: 'images/rr-learn-tl-1.svg',
      },
      {
        title: 'Reprogram core wounds',
        copy: 'And the painful stories you tell yourself.',
        deskImageURL: 'images/rr-learn-tl-2.svg',
      },
      {
        title: 'Express your needs',
        copy: 'So you no longer have to shut down or withdraw to cope.',
        deskImageURL: 'images/rr-learn-tl-3.svg',
      },
      {
        title: 'Learn to compromise',
        copy: 'Distinguish between compromising and making sacrifices to yourself.',
        deskImageURL: 'images/rr-learn-tl-4.svg',
      },
    ],
    EXCLUSIVEBONUS_SEGMENT: [
      `Access to an **additional 55+ courses** on challenges associated with your attachment style that you can choose to take at any suitable time.`,

      `My **weekly live webinars** where you can ask me your own questions, share your experience, and discuss your challenges in an informal and intimate environment with other members.`,

      `**Priority access** to all upcoming promotions, offers, and new programs at The Personal Development School.`,

      `The chance to attend **our regular events and socials** to connect with myself, The Personal Development School team, and other members.`,

      `An invitation to our **online forum and Facebook group** where you have the opportunity to interact with a supportive community like-minded individuals who are equally dedicated to their healing.`,
    ],
    STILLNOTSURE_SEGMENT: {
      copy1: `**We hear you.** 

You could tackle your healing on your own, reading dozens of books on attachment styles, or turn to your friends or journaling practices to work through your traumas. But many of our members come to us after attempting these healing methods **and seeing limited results or change**. 
                  
Sometimes what you need is more support, and guidance from someone who will take you through the exercises you need to really understand yourself, face yourself, and re-program your beliefs. Speaking from experience and from the experience of our members, it’s extremely difficult to do that all on your own.`,
      copy2: `Or maybe you feel like you’re better off working with a therapist or relationship coach in a private capacity. We are big advocates of seeking therapy here at The Personal Development School, but advise you to consider the amount of time, money, and insurance benefits budget it can take you to shop for the right one. 

Even then, the risk with some types of talk therapies or working with a relationship coach is that not all types of therapy are designed to tackle attachment trauma, which lives in the body and deep in your subconscious. Without working with the most qualified type of therapist trained to help you do the unconscious work to heal your core wounds, you can end up seeing limited progress for quite the monthly bill (between $150-200 per session).`,
      copy3: `
We are committed to helping you gain more self awareness and teaching you how to develop the habits and thought processes you need to see long-lasting change.`,
    },
    TESTIMONIAL_SEGMENT: [
      {
        avatar: 'images/avatar_carrie.jpeg',
        name: 'Carrie L',
        testimonial: `Hi! I’m Carrie from northern California, USA. I’ve been a member of the PDS school since around June, 2020. This experience has been a life changer for me.  I have always been a seeker and self-improvement fan, but the courses and webinars with Thais as well as the zoom meetings with the community led by Mike, have given me actionable tools and a supportive safe space to discuss and practice them.  I am so fortunate to have found this school and so many friendly folks to share this journey!`,
      },
      {
        avatar: 'images/avatar_tappy.png',
        name: 'Tapanga P',
        testimonial: `I discovered PDS shortly after my 19th birthday, even being one of the youngest in the school, everyone is so welcoming!!
        I really love how Thais Gibson covers so many topics and goes above and beyond to cater to the needs of the members. The post-webinar breakout rooms have so many brilliant, and like-minded individuals who I always look forward to talking to!
        PDS has had such an amazing impact on my relationships, my self-esteem and has given me valuable self-awareness. The tools within PDS have provided me with the strength, and ability to heal, almost completely from my anxiety, and I feel so much more confident within myself 😊`,
      },
      {
        avatar: 'images/avatar_jake.png',
        name: 'Jake J',
        testimonial: `PDS has impacted my life immensely. From creating a healthy relationship to self, improving my family dynamics, impacting my awareness of my subconscious programming, to providing the skills/tools for improving my communication with others in all relationships. It has honestly changed my life for the better and am on my path towards becoming a more secure person. Not to sound too much like an infomercial, but I don’t know where I would be in my life without this place. They have created such an amazing community that is extremely supportive and is full of wonderful people. PDS has grown into something I am very proud to be a part of and look forward to seeing where this journey takes me.`,
      },
      {
        avatar: 'images/avatar_leona.jpg',
        name: 'Leona',
        testimonial: `Joining PDS was the single best decision I made last year as it opened my eyes to the possibility that I could dramatically improve the quality of my relationships and that my past did not have to define my future. I learned about my attachment style, core wounds and personality needs and how these have been affecting the quality of my life currently. Thais gives clear instructions as to how you can reprogramme your subconscious mind, learn to communicate more effectively and excel in all 7 areas of your life to get rid of so many roadblocks that may be holding you back from living your best life. The PDS community is a truly supportive environment for personal growth and healing, my only regret is that I did not find all this out sooner as it has been truly life changing for me! ❤️
        `,
      },
    ],
    BESTSELF_SEGMENT: {
      subheading: `The Dismissive Avoidant to Securely Attached Program is the missing piece to help you get there.`,
      bullets: [
        [
          {
            title: '1. Letting People in',
            copy: `Saying goodbye to a sense of shame you carry about yourself and feeling like you can allow people to get to know you for who you are. No more keeping people at arm’s length or keeping secrets.`,
          },
          {
            title: '2. Understanding What’s Expected',
            copy: `Having a much better understanding of what your partners, friends, and family need from you and knowing how to deliver what they need without so much ambiguity.`,
          },
        ],
        [
          {
            title: '3. No More Attacking Yourself',
            copy: `Being able to respond to criticism less intensely, without attacking yourself or carrying the weight of negative feedback for days.`,
          },
          {
            title: '4. Kissing Your Anxiety Goodbye',
            copy: `Feeling a new sense of calm in relationships as they continue to grow. No longer feeling anxious and resentful when partners demand your time and attention.`,
          },
        ],
        [
          {
            title: '5. Feeling Free',
            copy: `No longer feeling trapped, suffocated, and agitated when entering long-term relationships and commitments. Being able to give your partner your very best self as a result.`,
          },
          {
            title: '6. Accepting Help',
            copy: `Having the skills you need to rely on and accept the help of others as you cope with pressure, anxiety and fears that can arise in everyday life.`,
          },
        ],
        [
          {
            title: '7. Feeling Your Emotions',
            copy: `No longer spending significant emotional energy to diminish your negative feelings, and knowing how to sit with them and let them pass instead.`,
          },
          {
            title: '8. Practicing Being Vulnerable',
            copy: `Having healthier beliefs about what it means to be vulnerable. Being able to practice being vulnerable with the people closest to you.`,
          },
        ],
      ],
    },
    MYQUESTION_SEGMENT: {
      subheading1: `I get it — you might feel paralyzed by the material you have to learn, worried about facing your traumas, or feel doubtful that you have what it takes to commit to yourself. 

**But we’re going to do this together. My team and I will be there to support you every step of the way.**`,
      subheading2: `**The biggest risk you can take is doing nothing at all.** 

Signing up for a program at The Personal Development School is your chance to step in and put an end to your vicious attachment cycles.
                    
**Too many people let their attachment styles, their beliefs, and their subconscious relationship patterns control their whole lives. But not you. It doesn’t have to be you too.**`,
      bullets: [
        `Repeating your same relationship cycles over and over again with no way out, not knowing where to start to create real change.`,

        `Pushing yourself deeper into a lonely isolation and continuing to shut the world out, leaving your friends, family, and partner perplexed.`,

        `Never learning how to get in tune with your feelings and risking potentially close and supportive relationships that could last a lifetime.`,

        `Continuing to deal with a consistent and unshakable sense of anxiety around all your relationships, be it your friendships, partnerships, family, or working relationships.`,

        `Forever battling with the criticizing and crippling voices in your head that tell you “bad things will happen when I open up,”, “I am defective,” “I’m just not cut for relationships,” and “people want too much from me.”`,

        `Never learning any new and healthy coping strategies for coping with your fears. Forever pulling away from people only to realize you don’t feel any better when you’re all alone.`,
      ],
    },
    OFFER_SEGMENT: {
      copy: `Facing yourself to create true, lasting change from within is challenging. We get it.  
And healing your attachment traumas and reprogramming your thoughts, patterns, and beliefs doesn’t happen overnight. 
          
**But it CAN happen with the right support and guidance. That’s what my team and I are here for.** 
          
All you need to do is take that brave first step. 
          
You’ve landed here because you’re being given the chance to radically transform yourself by gaining the tools you’ve been missing. 
          
By making a small yet substantial investment in bettering yourself, you will be on your way to developing the skills you’ve been missing to attract, create, develop, and keep the relationships you want to live a fulfilling life 
          
It’s time to look at your emotional self in the mirror. 
          
**Do you like what you see? Or are you ready to step up?**
          
Enroll in The Dismissive Avoidant to Securely Attached Program now and prepare to create the safe home within yourself you’ve been looking for all along.`,
      bullets: [
        `An additional 55+ personal development courses on attachment styles improving relationships and emotional mastery`,
        `Weekly live webinars with Thais Gibson`,
        `Access to 100s of pre-recorded webinars`,
        `Private online forum, support groups, and Facebook group`,
        `Regular events and socials`,
        `24/7 live chat functionality`,
      ],
    },
    FAQs: [
      {
        title:
          'I just want to heal my attachment style with a single course, I’m not sure I want to become a monthly member. Can’t I just purchase the course relevant to my attachment style?',
        content: `The monthly membership offers an integrated approach to healing your attachment style, your emotions and your relationships by targeting your subconscious mind.

Included with the monthly membership are courses on reprogramming each of the 4 attachment styles – but as you’ll discover, **attachment trauma often causes other issues** – such as codependency or people pleasing – to name a few – which require their own courses. So to equip you with the tools you need to best heal your attachment style, the membership gives you **access to 45+ courses – giving you access to a “university” of emotional support**.

Plus, included with the membership is access to **weekly LIVE webinars and Q&A calls**. As attachment trauma was formed as a result of the relationships in your early years – it can **best be healed in relationship with others**. So through our webinars, online community (forum), group chats and Facebook group – you’ll be supported by me and other members and receive a more **integrated approach to healing**.

We have found that out of the thousands of students who have become more securely attached, they have done so as a result of this integrated approach rather than taking single courses on their own.

This is deep, life-changing work, and I want to provide you with the best support possible.`,
      },
      {
        title: 'Can I cancel anytime?',
        content: `Absolutely. If you feel that the Personal Development School is not for you, **you can cancel your membership at any time – no questions asked.**

We have a very high success rate of students becoming more securely attached and fulfilled in all areas after becoming members of the school, and I’m confident that if you become a member – you will too. But if for whatever reason it no longer feels like the right fit or time for you, you can cancel.`,
      },
      {
        title: 'I’m on the fence about becoming a member. Do you offer a money-back guarantee?',
        content: `Yes, to help you make the best decision for you, we offer a no-questions asked 7-day money back guarantee – meaning there is absolutely no risk if you become a member today, and then later decide that the school is not for you.`,
      },
      {
        title:
          'Wow, that’s a lot of courses and support I get access to, why is it so reasonably priced?',
        content: `The short answer is: I want to help as many people as I possibly can!

I’m incredibly passionate about sharing what’s become my life’s work and this integrated approach to healing attachment trauma because **I know it works – as long as you do**. I have developed a system and tools that are highly effective – and I want to make them as accessible as possible.`,
      },
      {
        title: 'I’m considering other courses or therapy – why should I trust you?',
        content: `It’s important you feel confident in your decision to work with me.

I have added some testimonials on this page, but I urge you to do your own research by **checking out the Personal Development School’s YouTube Channel** to see thousands of user-generated reviews on the hundreds of videos I create to see if my style resonates with you.`,
      },
    ],
  },
  fa: {
    TITLE: 'Fearful Avoidant',

    YOUTUBE_URL: 'https://storage.googleapis.com/pds_videos/RoyalRumbleFAshortvideo.mp4',

    BANNER_SEGMENT: {
      headline: `Your fear of betrayal may be getting in the way of you forming healthy relationships that last`,
      copy: `You’re a very high achiever who sets top standards for yourself. A bit of a workaholic with a strong drive, you are usually extremely focused on some aspect of your life, be it your work, fitness, or creative projects. Because you can be so focused on achievement and value making impactful contributions, it’s normal for you to spend a great deal of time outside of romantic relationships, thriving on your own instead.

      At your core, you’re an extremely compassionate, empathetic, and giving person with a strong desire for deep connection. Creating space for your friends, family, and romantic partners comes easy to you. You enjoy supporting them through their life challenges and get called a “good listener” quite often. When it comes to opening up and being vulnerable with them yourself though, you are a lot more reserved. Having a deep-rooted fear that your loved ones might betray you at any time, you prefer to keep your walls up for safety. Because you have such great capacity to feel for other people and can become heavily involved with their emotions, you like to balance this out with lots of time and space on your own. You are also probably one of the most skilled people you know when it comes to reading people. You can’t help but be hyper-attuned to body language micro-expressions at any given moment and are the first to notice when someone “is a bit off”.
      
      Harmonious romantic relationships don’t come easy to you. Yours are usually passionate but emotionally volatile and overwhelming. If you’re dating someone who likes their space, you may become anxious that they might betray or leave you. And if you’re dating someone who constantly tries to get closer to you, you get turned off and need to distance yourself. Love can feel like an exhausting dance as you step towards intimacy – only to then take a step back because you feel smothered and need to regain your space. This can be incredibly painful for both you and your romantic partners – as depending on the relationship, your mental state can shift modes from: “I want you… come closer!” to “Slow down, not THAT close!”.`,
    },
    FAMILIAR_SEGMENTS: {
      traits: [
        [
          {
            title: 'Always On Alert',
            copy: 'Even when you’re in strong romantic relationships and friendships, you are highly suspicious of people at the same time, always scanning for signs of betrayal or wondering if they really do like you.',
          },

          {
            title: 'Constant Dissatisfaction',
            copy: 'When you’re in a relationship, you can experience an underlying sense of worry that you could be missing out on something better, which can make you feel guilty.',
          },
        ],
        [
          {
            title: 'Instant Turn-Offs',
            copy: 'For some reason you can never pinpoint, you can be quite attracted to new people, but instantly change your mind when they’re interested in you.',
          },

          {
            title: 'Intense Feelings of Anger and Resentment',
            copy: 'When you “over-give” and struggle to set healthy boundaries – you can feel taken advantage of only to later feel angry and resentful.',
          },
        ],
        [
          {
            title: 'Feelings of Low Self-Worth',
            copy: 'You may have had several relationships where you felt unseen and weren’t reassured, and ended up telling yourself things like “I’m unlovable” or “I don’t matter”.',
          },

          {
            title: 'Not Feeling Deserving Enough',
            copy: 'On some level, you’re convinced that there’s something wrong with you and that you need to fix whatever “it” is before you can be fully loved.',
          },
        ],
      ],
      headline: `If you relate to these statements and if you worry that you’ll never be able to feel safe, trust, and have fulfilling relationships, you’re not alone. And here's what you need to understand:`,
      subHeadline: `**You are not broken! These are just your patterns; and your patterns can be changed.**`,
      subHeadlineMobile: `You are not broken!

These are just your patterns; and your patterns can be changed.`,
    },
    ATTACHMENT_ORIGIN_SEGMENT: {
      copy1: `Your attachment style and the relationship challenges it brings are not your fault, they are a reflection of your story. 

A Fearful Avoidant attachment style is what arises when you’ve experienced inconsistent emotional support from your primary caregivers in your early childhood. You most likely came from a tumultuous household that was filled with polarity. Maybe you had a strong emotional connection with one of your parents, but a lot of avoidance with the other. Or maybe one (or both) of your parents were emotionally supportive but at infrequent times, so you never really knew what to expect. If either one of them was very hot and cold with you, you probably began to understand that their love was conditional — based on you performing well or behaving a certain way.`,
      copy2: `Being exposed to such unpredictability in your childhood means that you subconsciously developed an impending sense of betrayal in relationships, came to believe that vulnerability will result in pain, and started to believe that love is something that has to be earned. Now as an adult, you have grown up with an innate sense of distrust that you just can’t shake, always wondering what people’s true intentions are, never feeling entirely safe to let your guard down, and resentfully over-giving to get approval from the people closest to you.  

The problem is that these attitudes and beliefs about love and connection are now draining your energy and blocking you from your chance at developing the strong sense of trust and personal boundaries that you need to build the long-lasting and fulfilling relationships you truly want. 
    
The problem is that carrying your fear of betrayal and your avoidant behaviors into your adulthood can lead to major imbalances in your relationships as you push people away and can sabotage special connections.`,
      heading1: `
    Not developing an awareness of your patterns or taking action to break them is a recipe for destructive isolation, painful heartbreak, and a lack of fulfilling relationships in your life.`,
      heading2: `
    What if I told you there was a way to break these unconscious patterns and beliefs, re-write your story, and change your attachment style without having to spend thousands of dollars on therapy or coaching sessions?`,
    },
    THAIS_SEGMENT: {
      copy: `
    The Fearful Avoidant to Securely Attached Program is your personal invitation to break free from your attachment trauma and destructive patterns to create the love, safety, and connection that you deeply crave – and know, deep down, that you do deserve.`,
    },
    GAINACCESS_SEGMENT: `This online program is designed to show you, step by step, how to reprogram your attachment style by teaching you highly-targeted and effective tools based on the latest research and my decades of experience counseling couples and individuals. 

My system differs from other personal development programs because it’s designed to **work directly with your subconscious mind** – which is ESSENTIAL for any lasting change to occur.`,
    HOWPROGRAMWORK_SEGMENT: [
      `Register for the program`,

      `Make your way through the program’s online videos at your own pace`,

      `Access your workbooks and complete your exercises`,

      `Attend optional weekly webinars and Q&As`,

      `Participate in our online communities for further discussions as you please`,

      `Get your certificate of completion`,

      `Access your course materials again whenever you need a refresh`,
    ],
    WHATPROGRAMCOVER_SEGMENT: {
      copy: `The Fearful Avoidant to Securely Attached course gives you access to **5 tailored online courses** you can complete at your own convenience. 

Through hours of videos, highly effective exercises, and my support through intimate webinars, we’ll together to tackle a list of other emotional issues that you might deal with in your life that come from your attachment trauma, like:`,
      bullets: [
        `Struggling to trust and give people the benefit of the doubt`,
        `Feeling constant anxiety around your relationships`,
        `Putting up walls`,
        `Avoiding your emotions `,
      ],
    },
    LEARNHOWTO_SEGMENT: [
      {
        title: 'Reprogram betrayal wounds',
        copy: 'Let go of painful fears that block you from trusting others.',
        deskImageURL: 'images/rr-learn-tl-1.svg',
      },
      {
        title: 'Communicate better',
        copy: 'When you need more space or more connection.',
        deskImageURL: 'images/rr-learn-tl-2.svg',
      },
      {
        title: 'Self-soothe',
        copy: 'When experiencing anger or resentment.',
        deskImageURL: 'images/rr-learn-tl-3.svg',
      },
      {
        title: 'Create passionate relationships',
        copy: 'Without feeling like you’re on high alert all the time.',
        deskImageURL: 'images/rr-learn-tl-4.svg',
      },
    ],
    EXCLUSIVEBONUS_SEGMENT: [
      `Access to an **additional 55+ courses** on challenges associated with your attachment style that you can choose to take at any suitable time.`,

      `My **weekly live webinars** where you can ask me your own questions, share your experience, and discuss your challenges in an informal and intimate environment with other members.`,

      `**Priority access** to all upcoming promotions, offers, and new programs at The Personal Development School.`,

      `The chance to attend **our regular events and socials** to connect with myself, The Personal Development School team, and other members.`,

      `An invitation to our **online forum and Facebook group** where you have the opportunity to interact with a supportive community like-minded individuals who are equally dedicated to their healing.`,
    ],
    STILLNOTSURE_SEGMENT: {
      copy1: `**We hear you.** 

You could tackle your healing on your own, reading dozens of books on attachment styles, or turn to your friends or journaling practices to work through your traumas. But many of our members come to us after attempting these healing methods **and seeing limited results or change**. 
                  
Sometimes what you need is more support, and guidance from someone who will take you through the exercises you need to really understand yourself, face yourself, and re-program your beliefs. Speaking from experience and from the experience of our members, it’s extremely difficult to do that all on your own.`,
      copy2: `Or maybe you feel like you’re better off working with a therapist or relationship coach in a private capacity. We are big advocates of seeking therapy here at The Personal Development School, but advise you to consider the amount of time, money, and insurance benefits budget it can take you to shop for the right one. 

Even then, the risk with some types of talk therapies or working with a relationship coach is that not all types of therapy are designed to tackle attachment trauma, which lives in the body and deep in your subconscious. Without working with the most qualified type of therapist trained to help you do the unconscious work to heal your core wounds, you can end up seeing limited progress for quite the monthly bill (between $150-200 per session).`,
      copy3: `
We are committed to helping you gain more self awareness and teaching you how to develop the habits and thought processes you need to see long-lasting change.`,
    },
    TESTIMONIAL_SEGMENT: [
      {
        avatar: 'images/avatar_carrie.jpeg',
        name: 'Carrie L',
        testimonial: `Hi! I’m Carrie from northern California, USA. I’ve been a member of the PDS school since around June, 2020. This experience has been a life changer for me.  I have always been a seeker and self-improvement fan, but the courses and webinars with Thais as well as the zoom meetings with the community led by Mike, have given me actionable tools and a supportive safe space to discuss and practice them.  I am so fortunate to have found this school and so many friendly folks to share this journey!`,
      },
      {
        avatar: 'images/avatar_tappy.png',
        name: 'Tapanga P',
        testimonial: `I discovered PDS shortly after my 19th birthday, even being one of the youngest in the school, everyone is so welcoming!!
        I really love how Thais Gibson covers so many topics and goes above and beyond to cater to the needs of the members. The post-webinar breakout rooms have so many brilliant, and like-minded individuals who I always look forward to talking to!
        PDS has had such an amazing impact on my relationships, my self-esteem and has given me valuable self-awareness. The tools within PDS have provided me with the strength, and ability to heal, almost completely from my anxiety, and I feel so much more confident within myself 😊`,
      },
      {
        avatar: 'images/avatar_jake.png',
        name: 'Jake J',
        testimonial: `PDS has impacted my life immensely. From creating a healthy relationship to self, improving my family dynamics, impacting my awareness of my subconscious programming, to providing the skills/tools for improving my communication with others in all relationships. It has honestly changed my life for the better and am on my path towards becoming a more secure person. Not to sound too much like an infomercial, but I don’t know where I would be in my life without this place. They have created such an amazing community that is extremely supportive and is full of wonderful people. PDS has grown into something I am very proud to be a part of and look forward to seeing where this journey takes me.`,
      },
      {
        avatar: 'images/avatar_leona.jpg',
        name: 'Leona',
        testimonial: `Joining PDS was the single best decision I made last year as it opened my eyes to the possibility that I could dramatically improve the quality of my relationships and that my past did not have to define my future. I learned about my attachment style, core wounds and personality needs and how these have been affecting the quality of my life currently. Thais gives clear instructions as to how you can reprogramme your subconscious mind, learn to communicate more effectively and excel in all 7 areas of your life to get rid of so many roadblocks that may be holding you back from living your best life. The PDS community is a truly supportive environment for personal growth and healing, my only regret is that I did not find all this out sooner as it has been truly life changing for me! ❤️
        `,
      },
    ],
    BESTSELF_SEGMENT: {
      subheading: `The Fearful Avoidant to Securely Attached Program is the missing piece to help you get there.`,
      bullets: [
        [
          {
            title: '1. Feeling Relaxed',
            copy: `Instead of feeling anxious or confused in your relationships, you feel calm, safe and at ease because you’re no longer on high alert all the time.`,
          },
          {
            title: '2. Having an Open Heart',
            copy: `Welcoming new connections with an open heart, without questioning people’s intentions, putting aside your expectation of being betrayed.`,
          },
        ],
        [
          {
            title: '3. Knowing How to Self-Soothe',
            copy: `Trusting that you’ll always be able to work through challenges and will have your own back, no matter what life throws at you.`,
          },
          {
            title: '4. Being Kinder To Yourself',
            copy: `Allowing yourself to take healthy breaks from your career and passion projects without it impacting your sense of self worth.`,
          },
        ],
        [
          {
            title: '5. Having More Self Awareness',
            copy: `Knowing exactly when you’re being triggered by too much closeness or too much inconsistency and being able to identify and communicate your anxieties to others then and there.`,
          },
          {
            title: '6. Feeling Less Alone',
            copy: `Having a new sense of love for yourself and who you are. Ridding yourself of the voices in your head that tell you that you’re not good enough.`,
          },
        ],
      ],
    },
    MYQUESTION_SEGMENT: {
      subheading1: `I get it — you might feel paralyzed by the material you have to learn, worried about facing your traumas, or feel doubtful that you have what it takes to commit to yourself. 

**But we’re going to do this together. My team and I will be there to support you every step of the way.**`,
      subheading2: `**The biggest risk you can take is doing nothing at all.** 

Signing up for a program at The Personal Development School is your chance to step in and put an end to your vicious attachment cycles.
                  
**Too many people let their attachment styles, their beliefs, and their subconscious relationship patterns control their whole lives. But not you. It doesn’t have to be you too.**`,
      bullets: [
        `Pushing away more and more people when they try to get close to you and foster potentially meaningful, long lasting relationships.`,

        `Putting yourself in more arguments where you’ve unintentionally overanalyzed your partner’s behavior, accusing them of betrayal.`,

        `Continuing to question your relationships and ask yourself “do they really like me?”`,

        `Facing intense feelings of anger the next time you give too much only to receive too little back.`,

        `Continuing to battle with your perfectionism and high standards that only perpetuate your feelings of inadequacy.`,

        `Living a life of feeling unworthy, unlovable, and like you don’t matter.`,
      ],
    },
    OFFER_SEGMENT: {
      copy: `Facing yourself to create true, lasting change from within is challenging. We get it.  
And healing your attachment traumas and reprogramming your thoughts, patterns, and beliefs doesn’t happen overnight. 
          
**But it CAN happen with the right support and guidance. That’s what my team and I are here for.** 
          
All you need to do is take that brave first step. 
          
You’ve landed here because you’re being given the chance to radically transform yourself by gaining the tools you’ve been missing. 
          
By making a small yet substantial investment in bettering yourself, you will be on your way to developing the skills you’ve been missing to attract, create, develop, and keep the relationships you want to live a fulfilling life 
          
It’s time to look at your emotional self in the mirror. 
          
**Do you like what you see? Or are you ready to step up?**
          
Enroll in The Fearful Avoidant to Securely Attached Program now and prepare to create the safe home within yourself you’ve been looking for all along.`,
      bullets: [
        `An additional 55+ personal development courses on attachment styles improving relationships and emotional mastery`,
        `Weekly live webinars with Thais Gibson`,
        `Access to 100s of pre-recorded webinars`,
        `Private online forum, support groups, and Facebook group`,
        `Regular events and socials`,
        `24/7 live chat functionality`,
      ],
    },
    FAQs: [
      {
        title:
          'I just want to heal my attachment style with a single course, I’m not sure I want to become a monthly member. Can’t I just purchase the course relevant to my attachment style?',
        content: `The monthly membership offers an integrated approach to healing your attachment style, your emotions and your relationships by targeting your subconscious mind.

Included with the monthly membership are courses on reprogramming each of the 4 attachment styles – but as you’ll discover, **attachment trauma often causes other issues** – such as codependency or people pleasing – to name a few – which require their own courses. So to equip you with the tools you need to best heal your attachment style, the membership gives you **access to 45+ courses – giving you access to a “university” of emotional support**.

Plus, included with the membership is access to **weekly LIVE webinars and Q&A calls**. As attachment trauma was formed as a result of the relationships in your early years – it can **best be healed in relationship with others**. So through our webinars, online community (forum), group chats and Facebook group – you’ll be supported by me and other members and receive a more **integrated approach to healing**.

We have found that out of the thousands of students who have become more securely attached, they have done so as a result of this integrated approach rather than taking single courses on their own.

This is deep, life-changing work, and I want to provide you with the best support possible.`,
      },
      {
        title: 'Can I cancel anytime?',
        content: `Absolutely. If you feel that the Personal Development School is not for you, **you can cancel your membership at any time – no questions asked.**

We have a very high success rate of students becoming more securely attached and fulfilled in all areas after becoming members of the school, and I’m confident that if you become a member – you will too. But if for whatever reason it no longer feels like the right fit or time for you, you can cancel.`,
      },
      {
        title: 'I’m on the fence about becoming a member. Do you offer a money-back guarantee?',
        content: `Yes, to help you make the best decision for you, we offer a no-questions asked 7-day money back guarantee – meaning there is absolutely no risk if you become a member today, and then later decide that the school is not for you.`,
      },
      {
        title:
          'Wow, that’s a lot of courses and support I get access to, why is it so reasonably priced?',
        content: `The short answer is: I want to help as many people as I possibly can!

I’m incredibly passionate about sharing what’s become my life’s work and this integrated approach to healing attachment trauma because **I know it works – as long as you do**. I have developed a system and tools that are highly effective – and I want to make them as accessible as possible.`,
      },
      {
        title: 'I’m considering other courses or therapy – why should I trust you?',
        content: `It’s important you feel confident in your decision to work with me.

I have added some testimonials on this page, but I urge you to do your own research by **checking out the Personal Development School’s YouTube Channel** to see thousands of user-generated reviews on the hundreds of videos I create to see if my style resonates with you.`,
      },
    ],
  },
  sa: {
    TITLE: 'Secure',

    YOUTUBE_URL: 'https://storage.googleapis.com/pds_videos/RoyalRumbleSAshortvideo.mp4',

    BANNER_SEGMENT: {
      headline: `But without knowing how to communicate best with different types of partners, you may keep ending up in unfulfilling relationships`,
      copy: `You’re a warm and friendly person who feels comfortable in relationships (both intimate and platonic). You connect with people easily, generally feel confident in who you are, and have a strong sense of self-worth. You are quite good at communicating your needs, feelings, and opinions to others. Letting people in is easy for you because you feel safe, can set healthy boundaries, and have the skills to step away from situations that don’t deserve your time. When faced with relationship problems with your partner, friends, family, or coworkers, you see most problems as solvable and have strong conflict resolution skills. When you are faced with situations that are triggering, you are able to sit with your emotions and regulate them appropriately. When it comes to supporting others, you are an empathetic listener and are happy to be their rock. Your romantic relationships are generally stable and you don’t have a problem with long-term commitments. However, you can sometimes end up in relationships that feel wildly out of balance and that can drain you. Although you are very adaptable to different types of people, you sometimes struggle to understand how they operate and what exactly you need to do to fully support them.`,
    },
    FAMILIAR_SEGMENTS: {
      traits: [
        [
          {
            title: 'Getting Frustrated with Poor Communication',
            copy: 'You get frustrated when others struggle to express themselves. For example, you can find it very hard to understand when someone has emotional outbursts after bottling up their resentments for months.',
          },

          {
            title: 'Finding it Difficult to Relate to Insecure People',
            copy: 'Despite your empathetic nature, you can have a hard time relating to people’s fears of commitment, abandonment issues, or trust issues having never experienced these same things yourself.',
          },
        ],
        [
          {
            title: 'Being in Unfulfilling Relationships',
            copy: 'Even though you have the skills to make your relationships work, you have ended up in some relationships that made you feel frustrated, anxious, or stuck.',
          },
        ],
      ],
      headline: `If you relate to these statements and if it feels like your relationships have pushed you to the point of exhaustion, you’re not alone. And here's what you need to understand:`,
      subHeadline: `**With the right tools, you can make sure that your relationships always feel harmonious.**`,
      subHeadlineMobile: `**With the right tools, you can make sure that your relationships always feel harmonious.**`,
    },
    ATTACHMENT_ORIGIN_SEGMENT: {
      copy1: `Your attachment style and the relationship challenges it brings are a reflection of your story.

if your attachment style is Securely Attached, it’s likely that you had a healthy relationship with both of your caregivers during childhood. You were probably encouraged to express yourself, and your needs for emotional and physical support were consistently met. That’s why as an adult, it’s easier for you to create supportive, communicative relationships with others. Unlike the 3 insecure attachment styles – Anxious-Preoccupied, Dismissive Avoidant and Fearful Avoidant – you don’t experience much anxiety or fear when in a relationship because you didn’t experience attachment traumas in your relationships as a child.`,
      copy2: `Insecure attachment styles (Anxious Preoccupied, Fearful Avoidant, Dismissive Avoidant) can have a tendency to bottle up their emotions, pull away, send mixed signals by coming close and then moving away again, have difficulty communicating their needs, or putting up huge walls all in an effort to manage their deep-rooted fears of abandonment or betrayal. All of this can be extremely hard to relate to as someone who has never had the same traumatic experiences. 

A second thing to note is although you might have scored as Securely Attached on your quiz, everyone also has their secondary attachment style which can get triggered in particularly emotional situations. Depending on the type of partner you’re with and the situation, your secondary attachment style can surface, bringing along all of its associated relationship challenges. For that reason, it’s worthwhile to spend some time exploring the different attachment styles to enhance your self awareness in an effort to improve your relationships.
    `,
      heading1: `
    Not developing awareness of your own patterns or taking the time to understand the different attachment styles can lead to repetitive confusion in your relationships.`,
      heading2: `
    What if I told you there was a simple way to take your relationships to the next level by gaining the knowledge you’ve been missing?`,
    },
    THAIS_SEGMENT: {
      copy: `
    This is your personal invitation to improve your self awareness and communication skills even further and create the love and connection that you deserve.`,
    },
    GAINACCESS_SEGMENT: `My system differs from other personal development programs because it’s designed to work directly with your subconscious mind – which is ESSENTIAL for any lasting change to occur. 

All courses are intended to improve your emotional well-being, self-awareness, and communication skills. 
You also get access to in-depth quizzes to help you discover your secondary attachment style, and how your attachment style can change with friends, family, and partners. This will arm you with new awareness on which situations cause you to shift into an insecure attachment style.`,
    HOWPROGRAMWORK_SEGMENT: [
      `Register for the program`,

      `Make your way through the program’s online videos at your own pace`,

      `Access your workbooks and complete your exercises`,

      `Attend optional weekly webinars and Q&As`,

      `Participate in our online communities for further discussions as you please`,

      `Get your certificate of completion`,

      `Access your course materials again whenever you need a refresh`,
    ],
    WHATPROGRAMCOVER_SEGMENT: {
      copy: `The Emotional Healing Program gives you access to **8 tailored online courses** you can complete at your own convenience. These courses tackle topics including:
      `,
      bullets: [
        `Belief reprogramming`,
        `Discovering, embracing, and fulfilling your personal needs`,
        `Overcoming unworthiness`,
        `Releasing resentment`,
        `Learning forgiveness`,
        `Reparenting your inner child`,
      ],
    },
    LEARNHOWTO_SEGMENT: [
      {
        title: 'Communicate appropriately',
        copy: 'With partners who have insecure attachment styles.',
        deskImageURL: 'images/rr-learn-tl-1.svg',
      },
      {
        title: 'Understand others better',
        copy: 'Relate to and support others through their challenges.',
        deskImageURL: 'images/rr-learn-tl-2.svg',
      },
      {
        title: 'Develop self awareness',
        copy: 'By uncovering your secondary attachment style.',
        deskImageURL: 'images/rr-learn-tl-3.svg',
      },
      {
        title: 'Create healthy relationships',
        copy: 'That feel harmonious, connected, and that last.',
        deskImageURL: 'images/rr-learn-tl-4.svg',
      },
    ],
    EXCLUSIVEBONUS_SEGMENT: [
      `Access to an **additional 55+ courses** on challenges associated with your attachment style that you can choose to take at any suitable time.`,

      `My **weekly live webinars** where you can ask me your own questions, share your experience, and discuss your challenges in an informal and intimate environment with other members.`,

      `**Priority access** to all upcoming promotions, offers, and new programs at The Personal Development School.`,

      `The chance to attend **our regular events and socials** to connect with myself, The Personal Development School team, and other members.`,

      `An invitation to our **online forum and Facebook group** where you have the opportunity to interact with a supportive community like-minded individuals who are equally dedicated to their healing.`,
    ],
    STILLNOTSURE_SEGMENT: {
      copy1: `**We hear you.** 

You could tackle your healing on your own, reading dozens of books on attachment styles, or turn to your friends or journaling practices to work through your traumas. But many of our members come to us after attempting these healing methods **and seeing limited results or change**. 
            
Sometimes what you need is more support, and guidance from someone who will take you through the exercises you need to really understand yourself, face yourself, and re-program your beliefs. Speaking from experience and from the experience of our members, it’s extremely difficult to do that all on your own.`,
      copy2: ``,
      copy3: `
We are committed to helping you gain more self awareness and teaching you how to develop the habits and thought processes you need to see long-lasting change.`,
    },
    TESTIMONIAL_SEGMENT: [
      {
        avatar: 'images/avatar_carrie.jpeg',
        name: 'Carrie L',
        testimonial: `Hi! I’m Carrie from northern California, USA. I’ve been a member of the PDS school since around June, 2020. This experience has been a life changer for me.  I have always been a seeker and self-improvement fan, but the courses and webinars with Thais as well as the zoom meetings with the community led by Mike, have given me actionable tools and a supportive safe space to discuss and practice them.  I am so fortunate to have found this school and so many friendly folks to share this journey!`,
      },
      {
        avatar: 'images/avatar_tappy.png',
        name: 'Tapanga P',
        testimonial: `I discovered PDS shortly after my 19th birthday, even being one of the youngest in the school, everyone is so welcoming!!
        I really love how Thais Gibson covers so many topics and goes above and beyond to cater to the needs of the members. The post-webinar breakout rooms have so many brilliant, and like-minded individuals who I always look forward to talking to!
        PDS has had such an amazing impact on my relationships, my self-esteem and has given me valuable self-awareness. The tools within PDS have provided me with the strength, and ability to heal, almost completely from my anxiety, and I feel so much more confident within myself 😊`,
      },
      {
        avatar: 'images/avatar_jake.png',
        name: 'Jake J',
        testimonial: `PDS has impacted my life immensely. From creating a healthy relationship to self, improving my family dynamics, impacting my awareness of my subconscious programming, to providing the skills/tools for improving my communication with others in all relationships. It has honestly changed my life for the better and am on my path towards becoming a more secure person. Not to sound too much like an infomercial, but I don’t know where I would be in my life without this place. They have created such an amazing community that is extremely supportive and is full of wonderful people. PDS has grown into something I am very proud to be a part of and look forward to seeing where this journey takes me.`,
      },
      {
        avatar: 'images/avatar_leona.jpg',
        name: 'Leona',
        testimonial: `Joining PDS was the single best decision I made last year as it opened my eyes to the possibility that I could dramatically improve the quality of my relationships and that my past did not have to define my future. I learned about my attachment style, core wounds and personality needs and how these have been affecting the quality of my life currently. Thais gives clear instructions as to how you can reprogramme your subconscious mind, learn to communicate more effectively and excel in all 7 areas of your life to get rid of so many roadblocks that may be holding you back from living your best life. The PDS community is a truly supportive environment for personal growth and healing, my only regret is that I did not find all this out sooner as it has been truly life changing for me! ❤️
        `,
      },
    ],
    BESTSELF_SEGMENT: {
      subheading: `The Emotional Healing Program at The Personal Development School is the missing piece to help you get there.`,
      bullets: [
        [
          {
            title: '1. You were able to “get” your loved ones',
            copy: `You knew how to get your loved ones to open up to you about their fears and struggles in a healthy way because you have a much deeper understanding of what they’ve been through.`,
          },
          {
            title: '2. Were aware of what situations trigger you',
            copy: `By knowing your secondary attachment style, you could put a finger on what’s causing you anxiety in certain situations and relationships.`,
          },
        ],
        [
          {
            title: '3. You developed new lifelong communication tools',
            copy: `You had everything you needed to create healthy, connected relationships.`,
          },
          {
            title: '4. You felt relaxed and content in your relationships',
            copy: `You were able to consistently get the love and respect that you deserve.`,
          },
        ],
        [
          {
            title: '5. You had no more trouble building and maintaining relationships ',
            copy: `Having easy and harmonious relationships because you now have the tools to resolve challenges.`,
          },
        ],
      ],
    },
    MYQUESTION_SEGMENT: {
      subheading1: `I get it — you might feel paralyzed by the material you have to learn or might feel doubtful that you have what it takes to commit to a program.

**But my team and I will be there to support you every step of the way.**`,
      subheading2: `**The biggest risk you can take is doing nothing at all.** 

**Signing up for a program at The Personal Development School is your chance to maximize your awareness and put an end to misunderstandings and relationships that are not serving you.**`,
      bullets: [
        `Ending up in more unfulfilling relationships.`,

        `Never quite knowing why an anxious side of you comes out sometimes.`,

        `Facing more communication barriers between yourself and your partner.`,

        `Never quite understanding different types of people.`,

        `Constantly trying to support others the best you can but still getting it wrong.`,
      ],
    },
    OFFER_SEGMENT: {
      copy: `Doing the work to create true, lasting change from within is challenging. We get it. 

But all you need to do is take the first step.

**You’ve landed here because you’re being given the chance to radically transform yourself and your life by gaining the tools you’ve been missing.** 
          
By making a small yet substantial investment in bettering yourself, you will be on your way to developing the skills you’ve been missing to develop stronger connections and open yourself up to the relationships you need to live a fulfilling life. 
          
Get your membership started to dive straight into The Emotional Healing Program and prepare to start connecting with others in a much better way.`,
      bullets: [
        `An additional 55+ personal development courses on attachment styles improving relationships and emotional mastery`,
        `Weekly live webinars with Thais Gibson`,
        `Access to 100s of pre-recorded webinars`,
        `Private online forum, support groups, and Facebook group`,
        `Regular events and socials`,
        `24/7 live chat functionality`,
      ],
    },
    FAQs: [
      {
        title:
          'I just want to heal my attachment style with a single course, I’m not sure I want to become a monthly member. Can’t I just purchase the course relevant to my attachment style?',
        content: `The monthly membership offers an integrated approach to healing your attachment style, your emotions and your relationships by targeting your subconscious mind.

Included with the monthly membership are courses on reprogramming each of the 4 attachment styles – but as you’ll discover, **attachment trauma often causes other issues** – such as codependency or people pleasing – to name a few – which require their own courses. So to equip you with the tools you need to best heal your attachment style, the membership gives you **access to 45+ courses – giving you access to a “university” of emotional support**.

Plus, included with the membership is access to **weekly LIVE webinars and Q&A calls**. As attachment trauma was formed as a result of the relationships in your early years – it can **best be healed in relationship with others**. So through our webinars, online community (forum), group chats and Facebook group – you’ll be supported by me and other members and receive a more **integrated approach to healing**.

We have found that out of the thousands of students who have become more securely attached, they have done so as a result of this integrated approach rather than taking single courses on their own.

This is deep, life-changing work, and I want to provide you with the best support possible.`,
      },
      {
        title: 'Can I cancel anytime?',
        content: `Absolutely. If you feel that the Personal Development School is not for you, **you can cancel your membership at any time – no questions asked.**

We have a very high success rate of students becoming more securely attached and fulfilled in all areas after becoming members of the school, and I’m confident that if you become a member – you will too. But if for whatever reason it no longer feels like the right fit or time for you, you can cancel.`,
      },
      {
        title: 'I’m on the fence about becoming a member. Do you offer a money-back guarantee?',
        content: `Yes, to help you make the best decision for you, we offer a no-questions asked 7-day money back guarantee – meaning there is absolutely no risk if you become a member today, and then later decide that the school is not for you.`,
      },
      {
        title:
          'Wow, that’s a lot of courses and support I get access to, why is it so reasonably priced?',
        content: `The short answer is: I want to help as many people as I possibly can!

I’m incredibly passionate about sharing what’s become my life’s work and this integrated approach to healing attachment trauma because **I know it works – as long as you do**. I have developed a system and tools that are highly effective – and I want to make them as accessible as possible.`,
      },
      {
        title: 'I’m considering other courses or therapy – why should I trust you?',
        content: `It’s important you feel confident in your decision to work with me.

I have added some testimonials on this page, but I urge you to do your own research by **checking out the Personal Development School’s YouTube Channel** to see thousands of user-generated reviews on the hundreds of videos I create to see if my style resonates with you.`,
      },
    ],
  },
}

export const STRAIGHT_TO_OFFER = {
  TESTIMONIAL_SEGMENT: [
    {
      author: {
        name: 'MELISSA',
        state: 'Colorado',
        country: 'USA',
        picture: 'avatar_melissa.png',
      },
      score: 5,
      content:
        '“I’ve researched relationships most of my life. However, I couldn’t find anything that explained my dynamic in them. I thought about therapy until I stumbled upon Thais’s YouTube channel. She explained EXACTLY what I was experiencing. She literally read my mind! I’m usually a bit of a skeptic but I was hooked! I finally figured out my problem and how to solve it!”',
    },
    {
      author: {
        name: 'TAPPY',
        state: 'England',
        country: 'UK',
        picture: 'avatar_tappy.png',
      },
      score: 5,
      content:
        '“PDS has had such an amazing impact on my relationships, my self-esteem and has given me valuable self-awareness. The tools within PDS have provided me with the strength, and ability to heal, and I feel so much more confident within myself!”',
    },
    {
      author: {
        name: 'JAKE',
        state: 'California',
        country: 'USA',
        picture: 'avatar_jake.png',
      },
      score: 5,
      content:
        '“This has impacted my life immensely. From creating a healthy relationship to self, improving my family dynamics, impacting my awareness of my subconscious programming, to providing the skills/tools for improving my communication with others in all relationships. It has honestly changed my life for the better.”',
    },
  ],

  THAIS_SECTION: {
    heading: `My name is Thais Gibson`,

    subheading: `after completing a Master's Degree and 13 different certifications, I ran a private practice for a decade and founded the Personal Development School.`,

    copy: `Just like you, I too struggled with an insecure attachment style for most of my life. I remember how it was to lose battles to myself constantly. 

Gaining awareness of my thought processes and patterns was what changed things for me. I eventually learned how to reprogram the damaging voices in my head and began to tell myself new stories. All of these steps were truly transformational and the very foundation that helped me grow into the confident and securely attached person I am today. My whole life changed. 
    
    
Since then, I’ve gained over 13 different certifications, and had my own client-based practice focused largely on attachment dynamics in relationships. I now run The Personal Development School where I teach thousands of people daily **how to heal their attachment styles so that they can feel confident, empowered, and have high quality relationships that are deeply connected and that last.**  
    
I have become a leading authority on healing attachment trauma, and have built a thriving membership and online community of over 200,000 men and women who have improved their self awareness and seen success in their relationships. 
    
Today, The Personal Development School has surpassed nearly 20 million views on YouTube and I’m also extremely proud to share that our programs at The Personal Development School have a **97% satisfaction score (NPS Score)**
     
Why are my teachings working so well? 
    
Because every program that I offer through The Personal Development school is designed to **work directly with your subconscious mind, which is ESSENTIAL for lasting change to occur.**`,
  },

  LASTING_CHANGE_SEGMENT: {
    subheading1: `By getting an All-Access Pass to the The Personal Development School, you can change your attachment style and your relationship dynamics through:`,

    subheading2: `\nAll programs through The Personal Development Schools are online programs you can access at your convenience and complete on your own schedule.`,

    bullets: [
      `Highly-effective exercises that help you reprogram your attachment style.`,
      `Hundreds of pre-recorded webinars with my guidance on how to heal.`,
      `The option to attend weekly webinars where you can get my personal feedback on your biggest challenges.`,
      `Support groups led by our trained coaches.`,
      `Getting access to our library of 55+ other interesting courses you can join any time.`,
      `Engaging in our private members forum and Facebook group where you can ask questions and connect with other members.`,
      `Using our 24/7 live chat functionality whenever you need it.`,
    ],
  },

  SCIENTIFIC_SEGMENT: {
    heading: `Programs at The Personal Development school are designed to work with the different parts of your mind and help you apply the latest scientific research on attachment trauma to create lasting change in your relationships.`,

    copy: `You could tackle your healing on your own, reading dozens of books on attachment styles, or turn to your friends or journaling practices to work through your traumas. But many of our members come to us after attempting these healing methods and seeing limited results.

    Or maybe you prefer the idea of working with a therapist or relationship coach. We are big advocates of seeking therapy, but advise you to consider the amount of time and money it can take you to shop for the right one. Even then, not all types of therapy are designed to tackle attachment trauma and healing core wounds, so you could end up seeing limited progress for quite the monthly bill (between $150-200 per session).`,
  },

  TRANSFORM_LIFE_SEGMENT: {
    heading: `Today you have the chance to transform your ENTIRE life and emotional state because you’re no longer controlled by limiting thought patterns and painful emotions.`,

    live_webinars: {
      heading: `**ATTEND WEEKLY LIVE WEBINARS**`,

      subheading: `**Q&A Sessions And Community Events**`,

      copy: `Gain exclusive access to LIVE webinars and Q&A sessions  each week where I’ll go through the course content and break down how to apply the tools and techniques while answering your personal questions. Plus, there’s no need to worry about missing a webinar of interest. You’ll have access to all of our past webinar recordings so you can access them whenever it works for you.`,
    },

    courses: {
      heading: `**ACCESS TO 55+ ONLINE COURSES**`,

      subheading: `**To Repair Your Attachment Style, Build Lasting Relationships And Gain Emotional Mastery**`,

      copy: `You’ll get access to the Personal Development School’s ENTIRE library of online courses so you can heal other aspects of yourself that are associated with your attachment trauma, like:`,

      bullets: [
        `Codependency`,
        `People pleasing`,
        `Trust issues`,
        `Self-sabotage`,
        `Setting boundaries`,
      ],
    },

    bonuses: {
      heading: `**And To Make Sure You’re Supported Every Step Of The Way,
      You’ll Also Get Access To:**`,

      headingmobile: `**And To Make Sure You’re Supported Every Step Of The Way, You’ll Also Get Access To:**`,

      bonus1: {
        heading: `**BONUS 1: Access To The Personal Development School’s Online Forum**`,

        copy: `Connect with other like-minded people who are also working on improving their relationships and attachment styles. You can ask questions and even suggest different topics you’d like me to cover in my daily YouTube videos or in the webinars to further support your healing journey.`,
      },

      bonus2: {
        heading: `**BONUS 2: Join Study Groups, Socials And Live Chats With Other Members**`,

        copy: `Join study groups as you work through different courses so you can celebrate your progress and be supported through any challenges. This social connection element of the membership will help you feel less alone in your healing journey.`,
      },

      bonus3: {
        heading: `**BONUS 3: Exclusive Invite To The Members-Only Facebook Group**`,

        copy: `We’ll invite you to join our buzzing Facebook group for further support within a popular platform that you may already be using every day. My team and I monitor and respond to comments in this group every day. You’ll find that other members have always got your back and are happy to chime in and help you through any relationship challenges.`,
      },
    },
  },

  ap: {
    TITLE: `Anxious Preoccupied`,

    YOUTUBE_URL: 'https://storage.googleapis.com/pds_videos/RoyalRumbleAPshortvideo.mp4',

    BEST_RELATION_SEGMENT: {
      heading: `Having an Anxious Preoccupied attachment style means you most likely experience these challenges in your relationships: `,

      subheading: `No more fighting, no more doubts, no more worrying about the future of your relationship`,

      challenges: [
        {
          title: `YOU DEEPLY FEAR BEING ABANDONED`,
          content: `Relationships may make you feel unsafe and on edge because you have an underlying fear that the person you love might leave you at any moment. This fear can cause you to become insecure and fixated on how much the other person is (or isn’t) loving you; making you resentful.`,
        },
        {
          title: `YOU FEAR BEING DISCONNECTED, GROWING APART, AND ENDING UP ALONE`,
          content: `One of your biggest fears is that you’ll grow apart from your loved ones. You may worry that you’ll end up alone and never find the connection and love that you so desperately crave.`,
        },
        {
          title: `YOU ALWAYS WANT MORE CLOSENESS AND FEEL CONFUSED WHEN OTHERS PULL AWAY`,
          content: `You can experience panic, distress and even desperation when you sense your loved one is pulling away. When this happens, your natural reaction is to regain closeness, sometimes at all costs.`,
        },
        {
          title: `YOU TAKE THINGS PERSONALLY`,
          content: `Your identity and self-worth depends on those around you, which is why you may end up doing everything you can to make them happy. When people don’t reciprocate your love, you tell yourself that it MUST mean that they don’t love you.`,
        },
      ],

      experiences: {
        heading: `Do any of these experiences ring a bell for you?`,

        subheading: `Don’t worry. You are not broken and this is not your fault… But you do have the chance to change your attachment style!`,
      },

      imageURL: 'images/sto-ap-best-relation.png',
    },

    PICTURE_YOURSELF_SEGMENT: {
      bullets: [
        `Instead of being a ball of anxious energy in relationships, you feel calm, safe, seen and connected because you’re no longer being controlled by painful thoughts, fears and past trauma.`,
        `Feeling 100% deserving of love for who you are right now – without needing to overgive or become more perfect (aka, more “lovable”) in the distant future after you’ve become more confident, sexy or a completely different person, for example.`,
        `Feeling less alone – because no matter what life throws at you, you know there is one person who finally loves you unconditionally and who will never abandon you again (Hint: that’s you).`,
      ],

      subheading: `This is the you that you could be. All you have to do is take the first step.`,
    },

    HEALING_JOURNEY_SEGMENT: {
      heading: `Start your healing journey today with The Anxious Preoccupied to Securely Attached Program`,

      subheading: `By Spending as Little as 10 Minutes a Day on Yourself, You’ll Learn How to:`,

      bullets: [
        `Repair your attachment style and release your fear of abandonment to start creating healthy, lasting relationships.`,
        `Reprogram and heal the painful stories, core wounds and fears that are unique to your attachment style.`,
        `Identify and express your needs in a way your partner will understand`,
        `Regulate your emotions in real-time (no more losing days to brewing emotions or outbursts of resentment!).`,
        `Navigate love with less fear, distress and anxiety because you know what you want and deserve.`,
        `Feel seen, heard and connected in your relationships with others, and also in relationship with yourself.`,
        `Start taking your own needs into consideration.`,
        `Communicate with your partner, friends, colleagues and family so you can finally get the connection that you crave.`,
      ],
    },

    LASTING_CHANGE_SEGMENT_HEADING: `The Anxious Preoccupied to Securely Attached Program uses an approach that’s designed to help you create lasting change`,
  },

  da: {
    TITLE: 'Dismissive Avoidant',

    YOUTUBE_URL: 'https://storage.googleapis.com/pds_videos/RoyalRumbleDAshortvideo.mp4',

    BEST_RELATION_SEGMENT: {
      heading: `Having a Dismissive Avoidant attachment style means you most likely experience these challenges in your relationships:`,

      subheading: `No more fighting, no more misunderstandings, no more relationship anxiety.`,

      challenges: [
        {
          title: `FEAR OF COMMITMENT`,
          content: `On one hand, you want intimacy and connection. But on the other hand, you’re afraid that you’ll need to give up your sense of self if someone gets too close and will get swallowed by the other person’s needs.`,
        },
        {
          title: `YOU FEAR VULNERABILITY
          AND EMOTIONAL CONNECTION`,
          content: `You may tend to shut your partner out. This is not because you don’t actually need an emotional connection, but because it’s foreign to you.`,
        },
        {
          title: `YOU BELIEVE THAT EMOTIONS
          MAKE YOU WEAK`,
          content: `During childhood, it’s likely that expressing emotions was often discouraged. As an adult,  rather than seeing all emotions as valid and normal, you see certain ones as your weakness, or a flaw in character.`,
        },
        {
          title: `YOU CAN BE VERY SENSITIVE
          TO CRITICISM AND IMPERFECTIONS`,
          content: `If you were criticized for displaying certain emotions as a child, it’s likely that you’re very sensitive to criticism as an adult. You can feel a lot of shame around your own imperfections and perceived “weaknesses.”`,
        },
      ],

      experiences: {
        heading: `Do any of these experiences ring a bell for you?`,

        subheading: `Don’t worry. You are not broken and this is not your fault… But you do have the chance to change your attachment style!`,
      },

      imageURL: 'images/sto-da-best-relation.png',
    },

    PICTURE_YOURSELF_SEGMENT: {
      bullets: [
        `Instead of relationships feeling unpredictable, all-consuming and turbulent – you feel calm, safe and comfortable.`,
        `Being free of all the shame, repression and criticism you received as a child because now you fully love and accept yourself, flaws and all.`,
        `Feeling safe to be vulnerable and connect with those you care about because love no longer feels unpredictable.`,
      ],

      subheading: `This is the you that you could be. All you have to do is take the first step.`,
    },

    HEALING_JOURNEY_SEGMENT: {
      heading: `Start your healing journey today with The Dismissive Avoidant to Securely Attached Program`,

      subheading: `By Spending as Little as 10 Minutes a Day on Yourself, You’ll Learn How to:`,

      bullets: [
        `Repair your attachment style so you can create and maintain healthy, lasting relationships that make you feel safe`,
        `Reprogram the painful stories, core wounds and fears that are unique to your attachment style`,
        `Identify and express your needs and regulate your emotions in real-time`,
        `Rid yourself of your sensitivity to criticism because you no longer feel shame for your perceived flaws`,
        `Feel comfortable with your emotions – all of them – and understand the inner guidance and wisdom they’re trying to communicate to you`,
        `Better communicate with your partner, friends, colleagues and family so you can finally get the connection that you crave`,
      ],
    },

    LASTING_CHANGE_SEGMENT_HEADING: `The Dismissive Avoidant to Securely Attached Program uses an approach that’s designed to help you create lasting change`,
  },

  fa: {
    TITLE: 'Fearful Avoidant',

    YOUTUBE_URL: 'https://storage.googleapis.com/pds_videos/RoyalRumbleFAshortvideo.mp4',

    BEST_RELATION_SEGMENT: {
      heading: `Having a Fearful Avoidant attachment style means you most likely experience these challenges in your relationships:`,

      subheading: `No more fighting, no more doubts, no more misunderstandings.`,

      challenges: [
        {
          title: `YOU FLIP FLOP BACK AND FORTH
          IN RELATIONSHIPS`,
          content: `Love can sometimes feel like an exhausting dance as you step towards connection, love and intimacy – only to then take a step back because you feel smothered and need to regain your space.`,
        },
        {
          title: `YOU FEAR BEING BETRAYED IN RELATIONSHIPS`,
          content: `As a fearful avoidant, your superpower is your ability to read other people. But this also means that you might overanalyze your partner’s behavior, micro-expressions and body language – always scanning for signs of betrayal and feeling uneasy.`,
        },
        {
          title: `YOU OFTEN FEEL UNSEEN OR TAKEN ADVANTAGE OF`,
          content: `You’re often very generous with your energy, time and support because of your empathetic nature. But sometimes you can “over-give”, feeling like you’ve been taken advantage of and that your needs, wants and opinions don’t really matter.`,
        },
        {
          title: `PERFECTIONISM AND OVERWHELMING FEELINGS OF GUILT`,
          content: `You set impossibly high standards for yourself because you believe you need to be perfect before you can be fully loved. You’re also prone to feeling excessive guilt, which only reinforces your belief that something is wrong with you.`,
        },
      ],

      experiences: {
        heading: `Do any of these experiences ring a bell for you?`,

        subheading: `Don’t worry. You are not broken and this is not your fault… But you do have the chance to change your attachment style!`,
      },

      imageURL: 'images/sto-fa-best-relation.png',
    },

    PICTURE_YOURSELF_SEGMENT: {
      bullets: [
        `Instead of feeling anxious or confused in your relationships, you feel calm, safe and at ease.`,
        `Feeling less alone – because no matter what life throws at you, you know there is one person who finally loves you unconditionally and who always has your back (Hint: And that’s you).`,
        `No longer losing days, weeks or even years analyzing failed or challenging relationships because you have the tools and confidence to create meaningful and joyful connections that inspire you.`,
      ],

      subheading: `This is the you that you could be. All you have to do is take the first step.`,
    },

    HEALING_JOURNEY_SEGMENT: {
      heading: `Start your healing journey today with The Fearful avoidant to Securely Attached Program`,

      subheading: `By Spending as Little as 10 Minutes a Day on Yourself, You’ll Learn How to:`,

      bullets: [
        `Repair your attachment style so you can create and maintain healthy, lasting relationships that make you feel safe`,
        `Reprogram the painful stories, core wounds and fears that are unique to your attachment style`,
        `Identify and express your needs and regulate your emotions in real-time`,
        `Cultivate self-love and self-respect so you can have healthy boundaries with others and with yourself as you’re always taking yourself into consideration`,
        `Better communicate with your partner, friends, colleagues and family so you can finally get the connection that you crave`,
        `Create passionate and exciting relationships without feeling like you’re on high alert all the time due to suspicion`,
        `Deepen your self awareness and identify the kind of relationship that works best for you so you can navigate love with less doubt, exhaustion and anxiety`,
      ],
    },

    LASTING_CHANGE_SEGMENT_HEADING: `The Fearful Avoidant to Securely Attached Program uses an approach that’s designed to help you create lasting change`,
  },

  sa: {
    TITLE: 'Secure',

    YOUTUBE_URL: 'https://storage.googleapis.com/pds_videos/RoyalRumbleSAshortvideo.mp4',

    BEST_RELATION_SEGMENT: {
      heading: `Having an Secure attachment style means you most likely experience these challenges in your relationships:`,

      subheading: `No more fighting, no more confusion, no more miscommunication.`,

      challenges: [
        {
          title: `YOU CAN FEEL CONFUSED ABOUT CHALLENGES IN YOUR RELATIONSHIP`,
          content: `More often than not, you say what you mean, need and feel – and feel at ease in intimate relationships. So you can feel confused when challenges arise in your relationship if your partner has an insecure attachment style and struggles to communicate like you do.`,
        },
        {
          title: `FEEL FRUSTRATED WHEN OTHERS STRUGGLE TO COMMUNICATE IN A HEALTHY WAY`,
          content: `As you feel comfortable expressing your needs, boundaries and emotions, you may feel frustrated when others don’t. It can be hard for you to understand when your loved one has emotional outbursts after bottling up resentment for months on end, for example.`,
        },
        {
          title: `YOU CAN HAVE DIFFICULTY RELATING
          TO OTHERS`,
          content: `When your loved one withdraws for seemingly no reason, or appears to be upset without communicating why, it can be difficult for you to relate to them. This makes it hard to relate to what the other person is experiencing – because connection, vulnerability and intimacy don’t bring up the same fears for you. This can lead to conflict and both parties feeling misunderstood and unseen.`,
        },
      ],

      experiences: {
        heading: `Do any of these experiences ring a bell for you?`,

        subheading: `Don’t worry. With the right tools, you can make sure that you always have incredible and harmonious relationships.`,
      },

      imageURL: 'images/sto-sa-best-relation.png',
    },

    PICTURE_YOURSELF_SEGMENT: {
      bullets: [
        `Building and maintaining relationships that feel easy and harmonious because you have the tools to resolve challenges.`,
        `Being able to get your loved ones to open up to you about their fears and struggles in a healthy way because you have a much deeper understanding of what they’ve been through.`,
        `Having lifelong tools to create healthy, connected relationships so you can get the love and respect that you deserve.`,
      ],

      subheading: `This is the you that you could be. All you have to do is take the first step.`,
    },

    HEALING_JOURNEY_SEGMENT: {
      heading: `Start your healing journey today with The Emotional Healing Program`,

      subheading: `By Spending as Little as 10 Minutes a Day on Yourself, You’ll Learn How to:`,

      bullets: [
        `Create healthy, lasting relationships that feel harmonious, simple and connected`,
        `Understand where your partner’s difficulties with vulnerability, trust or fears around abandonment come from (and what to do when they arise)`,
        `Maintain a healthy and connected relationship that’s in alignment with what you need to feel safe, happy and fulfilled`,
        `Relate with your partner, friends and colleagues in a deeper way because you have a greater understanding of their emotional struggles and what they need to feel safe`,
      ],
    },

    LASTING_CHANGE_SEGMENT_HEADING: `The Emotional Healing Program uses an approach that’s designed to help you create lasting change`,
  },
}

export const TRIAL_PAGE = {
  courses: [
    {
      name: 'Emotional Healing Program',
      description:
        'Courses in this program will improve your emotional well-being and facilitate deep personal healing.',
      thumbnail: 'images/trial-page-program-emotional.jpg',
      courses: [
        'The Handbook for A Better Life',
        'Emotional Mastery & Belief Reprogramming',
        'Discover, Embrace & Fulfill Your Personal Needs',
        'Release Resentment & Learn to Truly Forgive',
        'Overcoming Unworthiness By Understanding & Accepting Your Shadow',
        'Rebuilding the Relationship to Yourself: Self-Love, Self Compassion & Self-Soothing',
        'Reparenting Your Inner Child',
      ],
    },
    {
      name: 'Fearful Avoidant Tailored Journey to Secure',
      description:
        'This program will help fearful avoidant individuals overcome destructive patterns and foster deep connections to become securely attached.',
      thumbnail: 'images/trial-page-program-fa.jpg',
      courses: [
        'The Handbook for A Better Life',
        'Discover, Embrace & Fulfill Your Personal Needs',
        'Healthy and Passionate Relationships after Emotional Pain(Re-Programming the Fearful Avoidant Attachment Style)',
        'Overcoming Unworthiness by Understanding & Accepting your Shadow',
        'Emotional Mastery & Belief Reprogramming',
      ],
    },
    {
      name: 'Anxious Preoccupied Tailored Journey to Secure',
      description:
        'This program will help Anxious Preoccupied individuals overcome destructive patterns and foster deep connections to become securely attached',
      thumbnail: 'images/trial-page-program-ap.jpg',
      courses: [
        'The Handbook for A Better Life',
        'Discover, Embrace & Fulfill Your Personal Needs',
        'Stop Abandonment & Rejection in A Relationship(Anxious Attachment Style Re-Programming)',
        'Healthy Balance in Relationships: Ending Codependency & Enmeshment',
        'Emotional Mastery and Belief Reprogramming',
      ],
    },
    {
      name: 'Dismissive Avoidant Tailored Journey to Secure',
      description:
        'This program will help dismissive avoidant individuals overcome destructive patterns and foster deep connections to become securely attached',
      thumbnail: 'images/trial-page-program-da.jpg',
      courses: [
        'Handbook for A Better Life Course',
        'Discover, Embrace & Fulfill Your Personal Needs',
        'Release Emotions with Somatic Processing',
        'Emotional Mastery & Belief Reprogramming',
        'Healthy & Secure Relationships with/for the Emotionally Unavailable Person (Dismissive Avoidant Re-programming Course)',
      ],
    },
    {
      name: 'Heal From a Breakup Bootcamp',
      description:
        'Courses in this program will heal pain associated with a breakup and help you regain your peace of mind.',
      thumbnail: 'images/trial-page-program-breakup.jpg',
      courses: [
        'How to Heal from A Breakup',
        'Strengthening Self-Identity',
        'Sky-Rocket Your Self-Esteem',
        'Emotional Mastery & Belief Reprogramming',
      ],
    },
  ],
  testimonials: [
    {
      author: 'Jake',
      testimonial: `PDS has impacted my life immensely. From creating a healthy relationship to self, improving my family dynamics, 
        impacting my awareness of my subconscious programming, to providing the skills/tools for improving my communication with others 
        in all relationships. \\
        \\
        It has honestly changed my life for the better and am on my path towards becoming a more secure person. 
        They have created such an amazing community that is extremely supportive and is full of wonderful people. PDS has grown into 
        something I am very proud to be a part of and look forward to seeing where this journey takes me.`,
      img: 'images/avatar_jake.png',
    },
    {
      author: 'Tamara',
      testimonial: `PDS is an incredible resource. The work is powerful. It’s helped me to heal some hard wounds and change my life to 
        one that is more balanced, more joyful and based in a deep sense of self-love and understanding. \\
        \\
        You choose how and how fast to 
        heal and you get to do it in an environment steeped in compassion, connection, integrity and personal accountability.  For anyone 
        who is ready to really heal themselves, PDS is filled with practical insights and powerful tools. `,
      img: 'images/avatar_tamara.jpg',
    },
    {
      author: 'Tappy',
      testimonial: `I discovered PDS shortly after my 19th birthday, even being one of the
      youngest in the school, everyone is so welcoming!!\\
      \\
      I really love how Thais Gibson covers so many topics and goes above
      and beyond to cater to the needs of the members. The post-webinar
      breakout rooms have so many brilliant, and like-minded individuals who
      I always look forward to talking to!\\
      \\
      PDS has had such an amazing impact on my relationships, my self-
      esteem and has given me valuable self-awareness. The tools within 
      PDS have provided me with the strength, and ability to heal, almost
      completely from my anxiety, and I feel so much more confident within
      myself 😊`,
      img: 'images/avatar_tappy.png',
    },
  ],
}

interface IQuesion {
  question: string
  association: 'fa' | 'da' | 'ap' | 'sec'
}
// Question count for each style = 7
export const QUESTIONS: IQuesion[] = [
  {
    question:
      'I like to get to know co-workers on a personal level but I do not like to share much about my personal life initially.',
    association: 'fa',
  },
  {
    question: 'I practice healthy boundaries in the workplace.',
    association: 'sec',
  },
  {
    question: 'I often put my co-workers on a pedestal.',
    association: 'ap',
  },
  {
    question: 'I feel irritated or impatient easily around co-workers.',
    association: 'da',
  },
  {
    question: 'I feel comfortable around my colleagues and enjoy their company.',
    association: 'sec',
  },
  {
    question:
      'I put an enormous amount of pressure and expectation on myself at work and then feel angry towards others when they aren’t holding their weight.',
    association: 'fa',
  },
  {
    question: 'I feel very upset when others infringe on my need for space or time alone.',
    association: 'da',
  },
  {
    question: 'I worry about feeling excluded by co-workers.',
    association: 'ap',
  },
  {
    question:
      'I feel comfortable communicating with co-workers even if we have a difference of opinion on a matter.',
    association: 'sec',
  },
  {
    question: 'I tend to be out of touch with my emotions quite frequently.',
    association: 'da',
  },
  {
    question:
      'I am very aware of the behaviors of my co-workers and almost immediately notice if there is a change in their patterns.',
    association: 'fa',
  },
  {
    question: 'I often accidentally over-express my emotions at work.',
    association: 'ap',
  },
  {
    question:
      'I am often distracted by relationships (friends, co-workers, romantic or family)  because I frequently prioritize them over my work.',
    association: 'ap',
  },
  {
    question: 'I know how to process my emotions effectively when I feel upset.',
    association: 'sec',
  },
  // 15
  {
    question:
      'I notice a people’s micro-expressions, body language and tone of voice changing very easily.',
    association: 'fa',
  },
  // 16
  {
    question: 'I spend a lot of time wondering what my colleagues or clients think about me.',
    association: 'ap',
  },
  // 17
  {
    question: 'I am effective at compromising and communicating.',
    association: 'sec',
  },
  // 18
  {
    question:
      'I have very strong emotions about work. I can get very excited or quite angry easily.',
    association: 'fa',
  },
  // 19
  {
    question: 'I strongly dislike feeling vulnerable to others.',
    association: 'da',
  },
  // 20
  {
    question: 'I care a lot about what my colleagues think of me at work.',
    association: 'ap',
  },
  // 21
  { question: 'I do not hold grudges easily or keep resentments long.', association: 'sec' },
  // 22
  {
    question:
      'I often express anger very strongly if I feel hurt, powerless or betrayed by anyone at work.',
    association: 'fa',
  },
  // 23
  {
    question: 'I don’t feel as if I boundaries are necessary between myself and my colleagues.',
    association: 'ap',
  },
  // 24
  {
    question:
      'I feel as though conflict is resolvable and feel equipped to work through problems effectively.',
    association: 'sec',
  },
  // 25
  {
    question: 'I can struggle to trust people in the workplace.',
    association: 'fa',
  },
  // 26
  {
    question: 'I can be cold and stand-offish at times when I don’t know others very well.',
    association: 'da',
  },
  // 27
  {
    question:
      'I can be very sensitive to criticism at work, though I will not outwardly show that criticism affects me.',
    association: 'da',
  },
  // 28
  {
    question: 'I do not like when people ask me about my emotions.',
    association: 'da',
  },
]

interface IResultCorporate {
  title: string
  strengths: string[]
  improvements: string[]
}

interface IResultsCorporate {
  AP: IResultCorporate
  DA: IResultCorporate
  FA: IResultCorporate
  SA: IResultCorporate
}

export const RESULTS: IResultsCorporate = {
  AP: {
    title: 'Anxious Preoccupied',
    strengths: [
      'Your awareness of the feelings and needs of others',
      'Your ability to be thoughtful and inclusive',
      'Your willingness to improve',
    ],
    improvements: [
      'A tendency to people-please and feel resentful',
      'Your strong fear of rejection & criticism',
      'The tendency you have to self-doubt',
    ],
  },
  DA: {
    title: 'Dismissive Avoidant',
    strengths: [
      'Your ability to be resourceful and hardworking',
      'Your attention to detail and thoroughness',
      'Your intellectual capacity',
    ],
    improvements: [
      'A tendency to be cold or blunt',
      'Occasionally being too independent',
      'Your occasional impatience with others',
    ],
  },
  FA: {
    title: 'Fearful Avoidant',
    strengths: [
      'Your resilience and determination',
      'Your awareness of the needs of others',
      'Your ability to continuously grow and improve',
    ],
    improvements: [
      'Becoming too “tunnel-visioned” at times',
      'Feeling suspicious or untrusting',
      'Occasionally being inconsistent',
    ],
  },
  SA: {
    title: 'Secure Attachment',
    strengths: [
      'Your ability to communicate with others',
      'Your ability to be balanced and trustworthy',
      'Your openness to feedback',
    ],
    improvements: [
      'Occasionally having less “grit” than other Styles',
      'Sometimes being unsuspecting',
      'The traits you express in your Secondary Style',
    ],
  },
}

interface IFAQProps {
  question: string
  answer: string
}

export const FAQs: IFAQProps[] = [
  {
    question: 'Can I cancel anytime?',
    answer: `Absolutely. If you feel that the Personal Development School is not for you, you can cancel your membership at any time – no questions asked.  

  We have a very high success rate of students becoming more securely attached and fulfilled in all areas after becoming members of the school, and I’m confident that if you become a member – you will too. But if for whatever reason it no longer feels like the right fit or time for you, you can cancel.
    `,
  },
  {
    question: 'I’m on the fence about becoming a member. Do you offer a money-back guarantee?',
    answer: `Yes, to help you make the best decision for you, we offer a no-questions asked 7-day money back guarantee after your trial – meaning there is absolutely no risk if you become a paying member after your trial, and then later decide that the school is not for you.`,
  },
  {
    question: 'I’m considering other courses or therapy – why should I trust you?',
    answer: `It’s important you feel confident in your decision to work with The Personal Development School and myself.  

  We have added some testimonials on this page, but we urge you to do your own research by checking out the Personal Development School’s YouTube Channel to see thousands of user-generated reviews on the hundreds of videos we create to see if our style resonates with you.
    `,
  },
]
