// core
import React, { ComponentType, useEffect } from 'react'
// API
// import { Login_login_user as ILoggedInUser } from 'api/Auth/types/Login'
import App from './App'
// libraries
import { isMacOs } from 'react-device-detect'
import ReactDOM from 'react-dom'
// import ReactDOM from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
// modules
import { Bugsnag, FBQ, gam as Gam, gamUserTracking, GTM, Mixpanel } from 'modules/analytics'
// utils
import { configure as configureYup } from 'utils'

// styles
import './index.css'
import './styles/animations.scss'
import './styles/background-images.scss'

// @ts-ignore
if (!isMacOs) import('styles/scrollbars.scss')

import { Toast } from 'modules/toast'

import 'react-toastify/dist/ReactToastify.css'
import 'tailwindcss/tailwind.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-pro/css/all.min.css'

// ==================== Bugsnag ====================
Bugsnag.init()
const ErrorBoundary: ComponentType<any> = Bugsnag.errorBoundary()

// ==================== Facebook Pixel ====================
FBQ.init()

// ==================== Google Analytics ====================
GTM.init()

// ==================== Gam ====================
Gam.init()

// ==================== Mixpanel ====================
Mixpanel.init()

// ==================== Apollo ====================
function AppRoot() {
  useEffect(() => {
    configureYup()

    return undefined
  }, [])

  return <App />
}

function AppSetup() {
  return (
    <ErrorBoundary>
      <HashRouter>
        <AppRoot />

        <Toast />
      </HashRouter>
    </ErrorBoundary>
  )
}

ReactDOM.render(<AppSetup />, document.getElementById('root'))
