// core
import React from 'react'
// components
import { AvatarDefault, IAvatarDefaultProps } from './AvatarDefault'
// libraries
import cx from 'classnames'

interface IAvatarDetailedProps extends IAvatarDefaultProps {
  /**
   * Defines where the additional information (like name & email) is displayed
   *
   * @default 'horizontal'
   */
  direction?: 'horizontal' | 'vertical'
  /**
   * First line of text to display with bigger font size
   */
  title?: string
  /**
   * Second line of text, below header, with smaller font size and lighter text color
   */
  subtitle?: string
}

export const AvatarDetailed = ({
  alt = 'Avatar',
  className,
  direction = 'horizontal',
  title,
  icon = 'user',
  size = 'medium',
  subtitle,
  url,
  onClick,
}: IAvatarDetailedProps) => {
  return (
    <div
      className={cx(
        direction === 'vertical' && 'flex-col space-y-2 text-center',
        'flex items-center space-x-4 min-w-0',
        className
      )}
      onClick={onClick}>
      <AvatarDefault alt={alt} className="flex-shrink-0" icon={icon} size={size} url={url} />

      <div className="space-y-1 overflow-hidden">
        {title && <p className="truncate">{title}</p>}
        {subtitle && <p className="text-sm text-gray-500 font-light truncate">{subtitle}</p>}
      </div>
    </div>
  )
}
