// core
import React from 'react'
// components
import { IInputDefaultProps, InputDefault } from './InputDefault'
// libraries
import { Field, FieldProps, useFormikContext } from 'formik'
import { get } from 'lodash'

interface IInputFieldProps<T> extends Omit<IInputDefaultProps<T>, 'value'> {
  name: string
}

/**
 * Field variant of `InputDefault` used withing `Formik`'s forms
 *
 * @warning
 * THIS INPUT HAS TO BE PLACED WITHIN `<Formik>`
 *
 * It uses custom `useFormikContext()` hook which looks for parent Formik
 * and throws an error if it doesn't find one
 */
export const InputField = <T,>({ name, ...otherProps }: IInputFieldProps<T>) => {
  const { errors, submitCount } = useFormikContext()

  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <InputDefault
          {...otherProps}
          {...fieldProps.field}
          error={submitCount > 0 && get(errors, name)}
        />
      )}
    </Field>
  )
}
