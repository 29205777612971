// core
import { ERoutes } from './index'
// libraries
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom'
import { IAttachmentStylePageParams } from 'pages/RoyalRumble'
import { ICorporateQuizResultsPageParams } from 'pages/corporate/CorporateQuizResultsPage'

/* Void type redirect */
type TVoid = () => void
/* Redirect expecting mandatory parameters */
type TMandatoryParams<P> = (params: P) => void
/* Redirect expecting optional parameters */
type TOptionalParams<P> = (params?: P) => void

export interface IRouteState {
  /* Name of the previous page before redirect executes - used for implementing custom back buttons */
  backURL: string
}

interface IUseRouterConfig {
  /**
   * Redirects user to page with provided URL
   *
   * ! NOTE ! - DO NOT USE THIS, this is only for dynamic routes when your navigation is defined in the BE admin panel
   */
  anyPage: (URL: ERoutes) => void

  /* Go back to a specific URL or the previous one the browser remembers */
  back: (URL?: string) => void

  ext: (URL: string, openInNewTab?: boolean) => void

  home: TVoid

  marketingQuiz: TVoid

  royalRumble: TMandatoryParams<IAttachmentStylePageParams>

  straightToOffer: TMandatoryParams<IAttachmentStylePageParams>

  trialPage: TVoid

  corporateLandingPage: TVoid

  corporateQuizQuestionsPage: TVoid

  corporateQuizResultsPage: TMandatoryParams<ICorporateQuizResultsPageParams>
}

export const useRouter = <T, F = any>(): [T, () => IUseRouterConfig, Location] => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams() as unknown as T

  const goTo = <T = Record<string, string | number | boolean>>(URL: ERoutes, params?: T) => {
    let parsedUrl = ''

    // Replace ":variables" from URL with provided params data, ignore the not provided params
    if (params) {
      const urlParams = URL.split('/:')

      urlParams.map((urlParam, index) => {
        // start with the 1st params which is always the core of the url - the 1st "/", eg: /home, /product etc.
        if (index === 0) {
          parsedUrl += urlParam
        } else {
          // Ignore the params that weren't provided
          if (params[urlParam as keyof T]) {
            parsedUrl += '/' + params[urlParam as keyof T]
          }
        }
      })
    } else {
      parsedUrl = String(URL)
    }

    // navigate(_URL, { state: { backURL: location.pathname } as IRouteState })
    navigate(parsedUrl, { state: { backURL: location.pathname } as IRouteState })
  }

  return [
    params,
    (): IUseRouterConfig => ({
      anyPage: (url) => goTo(url),

      back: (url) => (url ? navigate(url, { replace: true }) : navigate(-1)),

      ext: (url, openInNewTab?: boolean) =>
        openInNewTab ? window.open(url, '_blank') : window.location.assign(url),

      home: () => goTo(ERoutes.HOME),

      marketingQuiz: () => goTo(ERoutes.MARKETING_QUIZ),

      royalRumble: (params) => goTo(ERoutes.ROYAL_RUMBLE, params),

      straightToOffer: (params) => goTo(ERoutes.STRAIGHT_TO_OFFER, params),

      trialPage: () => goTo(ERoutes.TRIAL_PAGE_7_DAY),

      corporateLandingPage: () => goTo(ERoutes.CORPORATE_LANDING_PAGE),

      corporateQuizQuestionsPage: () => goTo(ERoutes.CORPORATE_QUIZ_QUESTIONS),

      corporateQuizResultsPage: (params) => goTo(ERoutes.CORPORATE_QUIZ_RESULTS, params),
    }),
    location,
  ]
}
