// core
import React from 'react'
// components
import { Icon } from './Icon'
import { Image } from './Image'
// libraries
import cx from 'classnames'
// utils
// import {} from 'utils'

interface IReviewProps {
  className?: string
  review: any
  ratingBg?: string
}

export const Testimonial = ({ className, review, ratingBg="teal" }: IReviewProps) => {
  return (
    // <div className="flex flex-col items-center">
    // mb-14
    <div className="relative 2xl:mb-0 flex flex-col items-stretch">
      {/* REVIEW AUTHOR */}
      <div className="flex items-center relative top-6 left-8">
        <Image
          className="max-w-28 max-h-28 rounded-full"
          src={`images/${review.author.picture}`}
          style={{ width: 'unset' }}
        />

        <div className="flex flex-col justify-center">
          {/* NAME */}
          <span className="font-bold text-left tracking-0.325">{review.author.name}</span>

          {/* LOCATION */}
          <span className="block">
            {review.author.state}, {review.author.country}
          </span>
        </div>
      </div>

      {/* CARD */}
      <div className="reviewCard h-full">
        <div className="flex mb-6 space-x-2">
          {Array(review.score)
            .fill(1)
            .map((_: any, ii: number) => (
              <Icon key={`star_'${ii}`} className={`text${'-'+ratingBg}`} name='star'/>
            ))}
        </div>

        <p>{review.content}</p>
      </div>
    </div>
  )
}
