// core
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { ReactComponent as PlayButton } from 'assets/images/play_icon.svg'
// components
import { IDefaultProps } from 'components'
import { Dialog } from 'components/Dialog/Dialog'
import { Image } from 'components/Image'
// libraries
import cx from 'classnames'
import { overrideTailwindClasses as two } from 'tailwind-override'
import { Regexes } from 'utils'

export interface IVideoDefaultProps extends IDefaultProps {
  /**
   * Whether to hide the video controls when it starts playing
   * @default false
   */
  hideVideoControlsOnPlay?: boolean
  /**
   * The size of the play button
   * Different video players have different play btn sizes
   * @small `w-8 h-8`
   * @mediun `w-16 h-16`
   * @large `w-28 h-28
   */
  playButtonSize?: 'none' | 'small' | 'medium' | 'large'
  /**
   * Whether the video should play withing the container or pop-out through `<Dialog />`
   * @default undefined
   */
  playInline?: boolean
  /**
   * URL of the source of the video
   * @default undefined
   */
  srcUrl?: string
  /**
   * URL of the thumbnail asset
   * @default undefined
   */
  thumbnailUrl?: string
}

export const VideoDefault = ({
  className,
  hideVideoControlsOnPlay = false,
  playButtonSize,
  playInline,
  srcUrl,
  style,
  thumbnailUrl,
}: IVideoDefaultProps) => {
  // ==================== Refs ====================
  const videoEl = useRef<HTMLVideoElement>(null)

  // ==================== State ====================
  const [isDialogShown, setIsDialogShown] = useState<boolean>(false)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  useEffect(() => {
    isPlaying ? videoEl.current?.play() : videoEl.current?.pause()
  }, [isPlaying])

  const toggleVideo = useCallback(() => {
    onToggleDialog()
    setIsPlaying(!isPlaying)
  }, [isPlaying])

  const onToggleDialog = useCallback(() => {
    if (playInline) return

    setIsDialogShown(!isDialogShown)
  }, [isDialogShown, setIsDialogShown])

  return (
    <>
      {/* POP-OUT DIALOG */}
      {!playInline && (
        <Dialog
          className="overflow-hidden lg:max-w-[75%]"
          isShown={isDialogShown}
          onToggle={onToggleDialog}>
          <VideoPlayer ref={videoEl} controls src={srcUrl} />
        </Dialog>
      )}

      <div
        className={two(cx('relative flex-center bg-white rounded-md overflow-hidden', className))}
        style={style}>
        {/* THUMBNAIL */}
        {thumbnailUrl && <Image src={thumbnailUrl} />}

        {/* VIDEO */}

        {playInline && srcUrl && (
          <VideoPlayer ref={videoEl} controls={!hideVideoControlsOnPlay} src={srcUrl} />
        )}

        {/* PLAY BUTTON */}
        {(playButtonSize !== 'none' || srcUrl) && (!isPlaying || !playInline) && (
          <PlayButton
            className={cx(
              'clickable absolute z-10 w-8 h-8',
              playButtonSize === 'small' && 'w-8 h-8',
              playButtonSize === 'medium' && 'lg:w-16 lg:h-16',
              playButtonSize === 'large' && '2xl:w-28 2xl:h-28',
              // default - size adjusts based on the screen size
              !playButtonSize && 'w-8 h-8 lg:w-16 lg:h-16 2xl:w-28 2xl:h-28'
            )}
            onClick={toggleVideo}
          />
        )}
      </div>
    </>
  )
}

interface IVideoPlayer extends Omit<React.VideoHTMLAttributes<HTMLVideoElement>, 'src'> {
  src?: string
}

export const VideoPlayer = forwardRef(
  ({ src = '', ...otherProps }: IVideoPlayer, ref: React.Ref<HTMLVideoElement>) => {
    // Checks whether the src is an actual URL or a local asset
    const isSrcLink = new RegExp(Regexes.url).test(src)

    return (
      <video
        ref={ref}
        {...otherProps}
        controlsList="nodownload"
        src={isSrcLink ? src : require(`../../../assets/${src || ''}`)}
        // type="video/mp4"
      />

      //     <video ref={ref} {...otherProps} controlsList="nodownload">
      //     {/* @ts-ignore */}
      //     <source src={isSrcLink ? src : require(`../../../assets/${src || ''}`)} type="video/mp4" />
      //   </video>
    )
  }
)
