// libraries
import moment from 'moment'

/**
 * Collection of supported currencies
 */
export enum ECurrencies {
  DOLLAR = '$',
  EURO = '€',
}

/**
 * Collection of keyboard key codes
 */
export enum EKeyCodes {
  /**
   * @usage closing `inlineAdd` and `inlineEdit` in `Table`
   */
  ESC = 'Escape',
  /**
   * @usage toggling `TranslationDialog` (this is a part of a shortcut, the whole thing is: `Control + T`)
   */
  T = 't',
  /**
   * @usage toggling `SideMenu` (left, with modules)
   */
  SQUARE_BRACKET_LEFT = '[',
  /**
   * @usage toggling `UserMenu` (right, with user profile)
   */
  SQUARE_BRACKET_RIGHT = ']',
}

/**
 * Date format
 */
export enum EDateFormats {
  MONTH_YEAR = 'MMMM YYYY',
  DEFAULT = 'DD.MM.YYYY',
  DEFAULT_DATETIME = 'DD.MM.YYYY HH:mm',
  TIME_AM_PM = 'h:mm a',
  WEBINARS = 'dddd, MMMM Do, YYYY',
}

/** README !
 *
 * Global collection of all regexes used throughout the project
 *
 * @Rules keep them in alphabetical order
 *
 */

 export const Regexes: { [key: string]: RegExp } = {
    hex: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
    //   hex: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
    name: /[^a-zA-Z\d.,:'-\s]/g,
    phone: /[^()\d-]/g,
    // url: /^mailto:([^\\?]+)|^(tel:([^\\?]+))|^((https?):\/\/)?(www.)?[a-zA-Z0-9-.]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_@#&=?]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  }
  
  
/**
 * Values identical with TailwindCSS breakpoints
 */
export enum EWindowWidth {
  sm = 640,
  md = 768,
  lg = 1024,
  xl = 1280,
  '2xl' = 1536,
}

/**
 * The oldest publish date a course can be considered "New"
 *
 * Used for fetching new courses on Dashboard and View Courses pages
 */
export const newCoursesDateThreshold = moment().subtract(6, 'months').toISOString()
