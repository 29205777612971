// core
import React, { lazy, memo } from 'react'
// routes
import { ERoutes } from './index'
// libraries
import { Route, Routes } from 'react-router-dom'

// Defaults
const ExternalQuizPage = lazy(async () => import('pages/NonMemberQuizPage'))
const RoyalRumble = lazy(async () => import('pages/RoyalRumble'))
const StraightToOffer = lazy(async () => import('pages/StraightToOffer'))
const TrialPage = lazy(async () => import('pages/TrialPage'))
const BlackFridayPage = lazy(async () => import('pages/BlackFridayPage'))
const CorporateLandingPage = lazy(async () => import('pages/corporate/CorporateQuizLandingPage'))
const CorporateQuizQuestionsPage = lazy(
  async () => import('pages/corporate/CorporateQuizQuestionsPage')
)
const CorporateQuizResultsPage = lazy(
  async () => import('pages/corporate/CorporateQuizResultsPage')
)

export const Router = memo(function Router() {
  return (
    <Routes>
      {/* DEFAULTS */}
      <Route element={<ExternalQuizPage quiz_traffic_source="organic" />} path={ERoutes.HOME} />

      <Route
        element={<ExternalQuizPage quiz_traffic_source="paid" />}
        path={ERoutes.MARKETING_QUIZ}
      />

      <Route element={<RoyalRumble />} path={ERoutes.ROYAL_RUMBLE} />

      <Route element={<StraightToOffer />} path={ERoutes.STRAIGHT_TO_OFFER} />

      <Route element={<TrialPage />} path={ERoutes.TRIAL_PAGE_7_DAY} />

      <Route element={<CorporateQuizResultsPage />} path={ERoutes.CORPORATE_QUIZ_RESULTS} />

      <Route element={<CorporateQuizQuestionsPage />} path={ERoutes.CORPORATE_QUIZ_QUESTIONS} />

      <Route element={<CorporateLandingPage />} path={ERoutes.CORPORATE_LANDING_PAGE} />

      <Route element={<BlackFridayPage />} path={ERoutes.BLACK_FRIDAY} />
    </Routes>
  )
})
